import { useEffect, useRef, useState } from "react";

const useNotifyOnCardRead = () => {
  const [notif, setNotif] = useState(false);
  const handle = useRef();
  useEffect(() => {
    const listener = () => {
      setNotif("Lecture de la carte vitale terminée");
      handle.current = setTimeout(() => setNotif(null), 5000);
    };
    window.addEventListener("cardRead", listener);
    return () => {
      window.removeEventListener("cardRead", listener);
      // we also clear the current setTimeOut if exists
      // ... because else we will try to set a state in an unmounted component
      clearTimeout(handle);
      setNotif(null);
    };
  }, [handle]);

  return notif;
};

export default useNotifyOnCardRead;
