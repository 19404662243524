import React, { useEffect } from "react";
import ButtonLink from "../../Shared/components/ButtonLink";

/* global $_ipm */

const StartManualLogin = () => {
  useEffect(() => {
    if (typeof $_ipm !== "undefined") {
      // If the next page is not contained in the list, the keyboard is hidden
      $_ipm.taboskbd.hide();
    }
  }, []);

  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="text-4xl font-bold">Avez-vous déjà un compte Medeo ?</div>
      <div className="flex flex-col items-center  justify-center">
        <ButtonLink to="./mail">oui - me connecter</ButtonLink>
        <ButtonLink className="mt-10" to="/create/0">
          non - créer un compte
        </ButtonLink>
      </div>
      <div />
    </div>
  );
};

export default StartManualLogin;
