import React, { useState } from "react";
import BasicToggleButton from "../../components/BasicToggleButton";
import BasicButton from "../../components/BasicButton";

const QuestionnaireOption = ({ display, handleChange, value, variant }) => {
  const [state, setState] = useState(null);

  const ToggleButton = variant === "outline" ? BasicToggleButton : BasicButton;

  return (
    <ToggleButton
      checked={state === "checked"}
      onClick={() => {
        setState(state === null ? "checked" : null);
        handleChange(value);
      }}
      className={`mx-1 mt-2`}
    >
      {display}
    </ToggleButton>
  );
};

export default React.memo(QuestionnaireOption);
