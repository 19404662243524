import React, { useCallback, useEffect, useReducer, useState } from "react";
import moment from "moment";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerformerInfo from "../../Booking/components/PerformerInfo";
import SlotsView from "./SlotsView";
import { useUpdateEffect } from "react-use";

const BookingViewNav = ({ disabled, onClick, icon }) => (
  <nav className="flex justify-center items-start mt-6 w-8 h-8">
    {!disabled && (
      <button
        className=" text-blue-400 bg-white rounded-full border border-transparent text-xs text-center font-medium border-blue-400 w-8 h-8"
        type="button"
        onClick={onClick}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    )}
  </nav>
);

BookingViewNav.defaultProps = {
  disabled: false,
  onClick: () => {}
};

const initialState = {
  start: moment().format("YYYY-MM-DD"),
  end: moment()
    .add(5, "days")
    .format("YYYY-MM-DD")
};

/**
 * Select the period according to the slot
 * This method makes sure the slot is inside the date range
 * It is useful when a user navigate back to the booking view.
 * It allows the component to show directly the correct period
 * @param state
 * @param {Slot} slot
 * @return {Period} period
 */
const computePeriod = (state, slot) => {
  let period = { ...state };
  while (moment(slot.start).isAfter(period.end)) {
    period = {
      start: moment(period.start)
        .add(5, "days")
        .format("YYYY-MM-DD"),
      end: moment(period.end)
        .add(5, "days")
        .format("YYYY-MM-DD")
    };
  }
  return period;
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "next":
      return {
        start: moment(state.start)
          .add(5, "days")
          .format("YYYY-MM-DD"),
        end: moment(state.end)
          .add(5, "days")
          .format("YYYY-MM-DD")
      };
    case "changed":
      // if the current slot as changed
      return { ...state, ...computePeriod(state, payload) };
    case "previous":
      return {
        start: moment(state.start)
          .subtract(5, "days")
          .format("YYYY-MM-DD"),
        end: moment(state.end)
          .subtract(5, "days")
          .format("YYYY-MM-DD")
      };
    default:
      return state;
  }
};
const BookingView = ({ practitioner, slots, slot, onChange, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // when the BookingView is mounted, we may already have a slot selected,
  // if this is the case, we want to jump straight to the correct period
  // the "change" action will update the state accordingly

  useEffect(() => {
    if (slot != null && slots.find(s => s.id === slot.id))
      dispatch({ type: "changed", payload: slot });
    // eslint-disable-next-line
  }, []);

  const handleNext = useCallback(() => {
    dispatch({ type: "next" });
  }, [dispatch]);

  const handlePrevious = useCallback(() => {
    dispatch({ type: "previous" });
  }, [dispatch]);

  useUpdateEffect(() => {
    onChange(state);
  }, [state]);

  // prevent user to go in the past
  // state represent the number of weeks from now
  const disabledPrevious = moment(state.start).isSame(moment(), "day");
  // having the practitionerRoles we can get all the practitioners ids
  // and show each practitioner by separate with their schedule

  // to fix css bug, we have to remove a css property when showing more slots
  const [hasShowMore, setHasShowMore] = useState(false);
  const handleShowMore = () => {
    setHasShowMore(true);
  };

  return (
    <div
      className="flex flex-col mb-8 bg-white shadow rounded-md justify-start items-stretch pb-4"
      style={hasShowMore ? {} : { minHeight: `320px` }}
    >
      <PerformerInfo performer={practitioner} />
      <div className="flex px-2">
        <BookingViewNav
          onClick={handlePrevious}
          icon={faArrowLeft}
          disabled={disabledPrevious}
        />
        <SlotsView
          period={state}
          slots={slots}
          slot={slot}
          onShowMore={handleShowMore}
        >
          {children}
        </SlotsView>
        <BookingViewNav onClick={handleNext} icon={faArrowRight} />
      </div>
    </div>
  );
};
BookingView.defaultProps = {
  onChange: () => {},
  slot: null
};
// React.memo will reduce the number of rerender
// it won't trigger a render until the props change
export default React.memo(BookingView);
