import React, { useState, useEffect, useMemo } from "react";
import QuestionnaireOption from "./QuestionnaireOption";
import SubmitButton from "../../components/SubmitButton";
import Form from "../../components/Form";
import Hero from "../../components/Hero";
import { useEffectOnce } from "react-use";
/* global $_ipm */

const QuestionnaireChoice = ({ item, onChange }) => {
  const [state, setState] = useState({ linkId: item.linkId });
  useEffectOnce(() => {
    if (typeof $_ipm !== "undefined") {
      // Hide keyboard by default on a screen of this type
      $_ipm.taboskbd.hide();
    }
  }, []);

  const isMultipleChoice = useMemo(
    () =>
      item?.extension
        ?.find(
          e =>
            e.url ===
            "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl"
        )
        ?.valueCodeableConcept?.coding?.find(
          c => (c.system = "http://hl7.org/fhir/questionnaire-item-control")
        )?.code === "check-box",
    [item]
  );

  const handleChange = value => {
    // We add support for both choices of type valueCoding and valueString
    const index = state.answer?.findIndex(
      a => a.valueCoding.code === value || a.valueString === value
    );
    const newValue = item.option.find(
      o => o.valueCoding.code === value || o.valueString === value
    );

    const newAnswer =
      state.answer == null
        ? // if answer doesn't exist, create it
          [newValue]
        : index === -1
        ? // if the value is not there, add the value to the array
          state.answer.concat([newValue])
        : // else if the value is already there, remove it
          [...state.answer.slice(0, index), ...state.answer.slice(index + 1)];
    setState({ ...state, answer: newAnswer });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onChange(state);
  };

  useEffect(() => {
    if (!isMultipleChoice && state.answer != null) {
      onChange(state);
    }
  }, [onChange, state, isMultipleChoice]);

  return (
    <Form onSubmit={handleSubmit}>
      <Hero className="mt-8">{item.text}</Hero>
      <div className="flex flex-row justify-center flex-wrap mt-8">
        {item.option.map((o, index) => (
          <QuestionnaireOption
            handleChange={handleChange}
            display={o.valueCoding.display}
            value={o.valueCoding.code}
            key={index}
            variant={isMultipleChoice ? "outline" : ""}
          />
        ))}
      </div>
      {isMultipleChoice && (
        <div className="flex flex-row justify-center mt-12">
          <SubmitButton onClick={handleSubmit}>Suivant</SubmitButton>
        </div>
      )}
      <div className="h-16" />
    </Form>
  );
};

export default QuestionnaireChoice;
