import React, { useEffect, useReducer } from "react";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColumnHeader from "./ColumnHeader";

/**
 * @typedef {object} ColumnState
 * @property {number} length - the count of slots
 * @property {number} index - the page index
 * @property {number} pageSize - the page size
 */

/**
 * initializes the state for the column component
 * @param slots - the array of all the slot available for the day
 * @return {ColumnState} state
 */
const init = slots => ({
  index: 0,
  pageSize: 4,
  length: slots.length
});

/**
 *
 * @param {ColumnState} state
 * @param action
 * @return {ColumnState} nextState
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "limit":
      return { ...state, limit: action.payload };
    case "setLength":
      return { ...state, length: action.slots.length };
    default:
      return state;
  }
};

/**
 * This component displays slots from a given day
 * @param {Array<Slot>} slots
 * @param {children}
 * @param index - the column index, it is used to compute the corresponding day
 * @return {*}
 * @constructor
 */
const Column = ({ day, slots, limit, children }) => {
  const [, dispatch] = useReducer(reducer, slots, init);
  useEffect(() => {
    dispatch({ type: "limit", payload: limit });
  }, [limit]);

  // We update the length of the column whenever the slots are changing
  useEffect(() => {
    dispatch({ type: "setLength", slots });
  }, [slots]);
  return (
    <div className="flex flex-col items-center justify-start self-start w-20 flex-1">
      <ColumnHeader date={day} />
      {slots.length > 0 ? (
        <div className="flex flex-col flex-grow">
          {/* If the pharmacist is on the Availability page we show a different
      component where the slots have a different style and are not clickable */}
          {slots.filter((s, i) => limit == null || i < limit).map(children)}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center text-gray-500">
          <FontAwesomeIcon icon={faCalendarTimes} />
          <span>----</span>
        </div>
      )}
    </div>
  );
};
Column.defaultProps = {
  slots: []
};

export default Column;
