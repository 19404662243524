import moment from "moment";
import { getIdByReference } from "../Shared/utils";

export const getArryOfOrganizationIdFromSearchBundle = (
  searchBundle,
  organizationId
) => {
  if (searchBundle?.Organization != null && searchBundle?.CareTeam != null) {
    const careTeams = searchBundle.CareTeam.flatMap(team =>
      team.participant.map(p => p.member.reference)
    )
      .filter(ref => ref?.split("/")[1] !== organizationId)
      .filter(ref => ref?.split("/")[0] === "Organization")
      .map(ref => ref?.split("/")[1]);
    return [...new Set(careTeams)];
  } else {
    return [];
  }
};

export const sortSlotsByPerformerId = searchBundle => {
  if (searchBundle == null) return [];
  const slotArray = searchBundle.Slot?.filter(slot =>
    isBookable(slot)
  ).sort((a, b) => moment(a.start).diff(moment(b.start)));
  // here we want to be sure that we never have a schedule for a Location
  const scheduleArray = searchBundle.Schedule?.filter(
    s => !s.actor.some(a => a.reference.startsWith("Location"))
  );
  const sortedSlotArray = [];
  const sortedScheduleArray = [];
  slotArray?.forEach(slot => {
    const scheduleId = getIdByReference(slot.schedule.reference);
    if (sortedSlotArray.findIndex(s => s.scheduleId === scheduleId) === -1) {
      sortedSlotArray.push({
        scheduleId: scheduleId,
        slots: [slot]
      });
    } else {
      const existingScheduleIndex = sortedSlotArray.findIndex(
        s => s.scheduleId === scheduleId
      );
      const existingSchedule = sortedSlotArray[existingScheduleIndex];
      sortedSlotArray[existingScheduleIndex] = {
        scheduleId: existingSchedule.scheduleId,
        slots: [...existingSchedule.slots, slot]
      };
    }
  });

  scheduleArray?.forEach(schedule => {
    const practitionerId = getIdByReference(schedule.actor[0].reference);
    const existingPerformerIndex = sortedScheduleArray.findIndex(
      s => s.performerId === practitionerId
    );
    const existingPerformer = sortedScheduleArray[existingPerformerIndex];
    if (
      existingPerformer == null &&
      sortedSlotArray.some(s => s.scheduleId === schedule.id)
    ) {
      sortedScheduleArray.push({
        performerId: practitionerId,
        slots: sortedSlotArray.find(s => s.scheduleId === schedule.id)?.slots //
      });
    } else if (existingPerformer != null && existingPerformer.slots != null) {
      const otherSlots = sortedSlotArray.find(s => s.scheduleId === schedule.id)
        ?.slots;
      if (otherSlots != null)
        existingPerformer.slots = existingPerformer.slots.concat(otherSlots);
    }
  });
  return sortedScheduleArray;
};

/**
 * Inform on wether a slot is still bookable,
 * meaning it is not in the past and still has a 'free' status
 *
 * @param {*} slot
 * @returns {Boolean}
 */
export const isBookable = slot => {
  return slot.status === "free" && moment(slot.start) > moment();
};
