import moment from "moment";

/**
 * curried filter function that check whether the slot is on the same date
 * @param day
 * @return {function(*): boolean}
 */
const bySameDay = day => slot => moment(slot?.start).isSame(moment(day), "day");

/**
 * takes a period and return the list of days within the period
 * @param {Period} period
 * @return {[]}
 */
const range = ({ start, end }) => {
  let intervals = [];
  while (moment(end).diff(start, "day") >= 1) {
    intervals.push(moment(start).format("YYYY-MM-DD"));
    start = moment(moment(start).add(1, "day")).format("YYYY-MM-DD");
  }
  return intervals;
};

/**
 * curried function to transform slots in a entry keyed with the day
 * @param slots
 * @return {function(*=): (*|*[])[]}
 */
const toEntry = slots => day => [day, slots?.filter?.(bySameDay(day)) ?? []];

/**
 * Creates an object with days as keys and the corresponding slots as values
 * @param {Period} period
 * @param {[Slot]} slots
 * @return {{[p: string]: any} | any}
 */
export const reduceSlotsOverPeriod = (period, slots) => {
  const reduced = Object.fromEntries(range(period).map(toEntry(slots)));
  return reduced;
};
