import React from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import Help from "../../Shared/components/Help";
import Hero from "../../Shared/components/Hero";

const CGU = ({ onSubmit }) => {
  return (
      <form id="cgu" className="flex flex-col justify-between w-full" onSubmit={onSubmit}>
          <div>

          <Hero>
            Veuillez accepter les conditions générales d'utilisation.
          </Hero>
          <Help>
            Les conditions resteront accessibles depuis votre dossier patient.
          </Help>
          </div>
            <div className="overflow-auto bg-white h-64 shadow rounded-lg">
              <div className="p-4 ">

                <div>
                  <p>
                    MEDEO est une société spécialisée dans la programmation
                    informatique qui développe des programmes destinés aux
                    professionnels de la santé et à leurs patients.
                  </p>
                  <p>
                    A ce titre, MEDEO a développé une solution matérielle et
                    logicielle permettant aux professionnels de la santé
                    d’améliorer la coordination du parcours de soin des patients
                    et de permettre notamment la réalisation d’actes de
                    télémédecine.
                  </p>
                  <p>
                    Le Solution est accessible à ses utilisateurs en mode «
                    Software as a Service » (SaaS) sur le site internet
                    http://medeo.care (ci-après : le « Site ») ou sur le site
                    dédié à l’organisation de l’Utilisateur Professionnel de
                    santé.
                  </p>
                  <p>
                    Le Client est un professionnel de la santé agissant dans le
                    cadre et pour les besoins de son activité professionnelle.
                  </p>
                  <p>
                    Le Client s’est montré intéressé par la solution de MEDEO et
                    souhaite utiliser et acquérir la Solution pour pouvoir
                    bénéficier des services proposés.
                  </p>
                  <p>
                    A cette fin, MEDEO a soumis au Client un devis, précisant les
                    modalités d’accès à la Solution et de son utilisation
                    (ci-après : le « Devis »). Le Client a accepté le Devis en le
                    retournant à MEDEO dûment signé. Le Client reconnaît et
                    accepte que l’utilisation de la solution fournie par MEDEO
                    suppose l’acquisition de matériels ainsi que la souscription à
                    une licence payante.
                  </p>
                  <p>
                    Les Parties reconnaissent ainsi, et préalablement à la
                    signature des présentes, avoir échangé à propos des services
                    proposés par MEDEO, par l’intermédiaire de la Solution, ainsi
                    que sur leur adéquation aux attentes et besoins du Client.
                  </p>
                  <p>
                    Avertissement : MEDEO n’est pas un service d’urgence. En cas
                    d’urgence, il est recommandé de suivre les indications d’un
                    médecin ou à défaut de consulter le service des urgences le
                    plus proche.
                  </p>
                  <p>CECI EXPOSE, IL A ETE CONVENU CE QUI SUIT :</p>
                </div>
                <h4>Article 1. Définitions</h4>
                <div>
                  <p>
                    Dans le cadre des présentes conditions générales
                    d’utilisation, les termes utilisés ci-après ont les
                    significations suivantes, qu’ils soient utilisés au singulier
                    ou au pluriel :
                  </p>
                  <p>
                    « Abonnement » désigne un forfait payant donnant accès à
                    différents Services suivants l’offre commerciale choisie par
                    le Client et moyennant le versement d’un prix forfaitaire et
                    global fixé au Devis.
                  </p>
                  <p>
                    « Accessoires » désigne les différents dispositifs de mesure
                    connectés pouvant communiquer avec l’Equipement fabriqués et
                    mis sur le marché par des tiers.
                  </p>
                  <p>
                    « CGU » : désigne les présentes conditions générales
                    d’utilisation du matériel composant la Solution et de la
                    solution MEDEO.
                  </p>
                  <p>
                    « Utilisateur » : Désigne tout individu, indifféremment
                    désigné, utilisant ou bénéficiant des Services et disposant de
                    la pleine capacité juridique.
                  </p>
                  <p>
                    « Utilisateur Patient » : Désigne tout Utilisateur personne
                    physique capable au profit duquel sont utilisés les Services.
                  </p>
                  <p>
                    « Utilisateur Professionnel de santé » : Désigne tout
                    Utilisateur professionnel de santé (en ce inclus, sans que
                    cela soit limitatif, les professions médicales à l’instar de
                    tout Docteur en Médecine inscrit auprès du Conseil
                    Départemental de l’Ordre des Médecins dont il dépend, habilité
                    à délivrer les actes de téléconsultation dans le cadre des
                    Services via la solution MEDEO, les professions de la
                    pharmacie et de la physique médicale ou encore les professions
                    d’auxiliaires médicaux), dans le respect des dispositions du
                    code de la santé publique et du code de déontologie médicale.
                  </p>
                  <p>
                    « Login » : Désigne un identifiant unique permettant à
                    l'Utilisateur de se connecter aux Services. L’identifiant est
                    constitué par l’adresse email personnelle de l’Utilisateur. Le
                    Login doit être impérativement associé à un Mot de passe
                    personnel et secret pour permettre l’accès à l’application
                    MEDEO.
                  </p>
                  <p>
                    « Mot de passe » : Désigne un Mot de passe constitué d’un
                    minimum de 8 caractères.
                  </p>
                  <p>
                    « Documentation » désigne tout mode d’emploi et guide
                    d’utilisation se rapportant à la Solution.
                  </p>
                  <p>
                    « Données » désigne les données générées par les Accessoires
                    et destinées à être traitées par la Solution.
                  </p>
                  <p>
                    « Equipement » désigne le dispositif MEDEO constituant la
                    partie matérielle de la Solution.
                  </p>
                  <p>
                    « Licence » désigne la licence d’utilisation du Logiciel telle
                    que définie à l’Article 10.
                  </p>
                  <p>
                    « Logiciel » désigne la brique logicielle MEDEO constituant la
                    partie logicielle de la Solution.
                  </p>
                  <p>
                    « Matériel » désigne le matériel composant la Solution à
                    savoir ensemble l’Equipement et les Accessoires.
                  </p>
                  <p>
                    « Progiciel Tiers » désigne le logiciel métier édité par une
                    société tierce que le Client utilise et avec lequel le
                    Logiciel est amenée à communiquer des Données.
                  </p>
                  <p>
                    «Services» désigne l’ensemble des services fournis par MEDEO
                    en contrepartie du paiement par le Client de son Abonnement. A
                    savoir, l’ensemble des services mis à disposition par MEDEO
                    sur la solution MEDEO et l’application mobile MEDEO, de
                    pré-consultation permettant le calcul de scores et la
                    délivrance d’informations concernant les facteurs de risque,
                    et de télédiagnostic, télé expertise et téléconsultation
                    médicale, tels que définis à l’article 3 des présentes
                    Conditions Générales d’Utilisation.
                  </p>
                  <p>
                    «Solution» désigne le Matériel et le Logiciel dont les
                    spécifications techniques ou fonctionnelles sont décrites sur
                    le catalogue Medeo.
                  </p>
                </div>
                <h4>Article 2. Objet</h4>
                <div>
                  <p>
                    Les présentes CGU ont pour objet de définir les termes et
                    conditions dans lesquels, d’une part, MEDEO vend au Client le
                    Matériel composant la Solution et, d’autre part, MEDEO fournit
                    des Services au Client en contrepartie de la souscription d’un
                    Abonnement.
                  </p>
                  <p>
                    Tout Utilisateur qui accède aux Services, s’engage à
                    respecter, sans réserve, les présentes CGU.
                  </p>
                  <p>
                    MEDEO se réserve le droit de refuser ou de supprimer l’accès
                    aux Services, dans les conditions prévues aux présentes, à
                    tout Utilisateur ne respectant pas les présentes CGU.
                  </p>
                  <p>
                    Les présentes CGU entrent en vigueur sans réserve à l’égard de
                    l’Utilisateur Professionnel de santé, au jour de l’acceptation
                    des présentes CGU, à partir de la page d’accès au compte
                    personnel. Elles restent en vigueur à son égard pour toute la
                    durée de sa relation contractuelle avec MEDEO.
                  </p>
                  <p>
                    Les présentes CGU entrent en vigueur sans réserve à l’égard de
                    l’Utilisateur Patient, au jour de l’acceptation, à partir de
                    la signature des contrats commerciaux, des présentes CGU et de
                    la notice d'information et de consentement au titre de la
                    collecte et du traitement de ses données à caractère personnel
                    et notamment de ses données de santé. Elles restent en vigueur
                    à son égard pendant la durée de l’utilisation des Services.
                  </p>
                  <p>
                    L’Utilisateur est invité à lire avec attention les CGU et
                    dispose de la possibilité de les consulter à tout moment sur
                    le site internet www.medeo-health.com.
                  </p>
                </div>
                <h4>Article 3. Documents contractuels</h4>
                <div>
                  <p>
                    Les CGU ainsi que les documents intitulés « Annexes » le cas
                    échéant constituent l’intégralité des engagements existant
                    entre les Parties. Elles remplacent et annulent tout
                    engagement oral ou écrit antérieur relatif à l'objet des CGU.
                    Les CGU sont formées des documents contractuels suivants
                    présentés par ordre hiérarchique de valeur juridique
                    décroissante :
                  </p>
                  <ul>
                    <li>le présent document ;</li>
                    <li>le Devis ;</li>
                    <li>les Annexes au présent document.</li>
                  </ul>

                  <p>
                    En cas de contradiction entre une et/ou plusieurs dispositions
                    figurant dans l'un quelconque de ces documents, le document de
                    rang supérieur prévaudra.
                  </p>
                </div>
                <h4>Article 4. Définition et description du service</h4>
                <div>
                  <p>
                    L’Application MEDEO est une application informatique et une
                    plateforme numérique sécurisée de mise en relation entre
                    professionnels de santé.
                  </p>
                  <p>
                    L’Application MEDEO comprend notamment les Services suivants :
                  </p>
                  <ul>
                    <li>
                      Service de pré-consultation, permettant à un professionnel
                      de santé de renseigner des informations nécessaires à
                      l’évaluation du risque santé chez un patient et permettant
                      également la délivrance d’informations concernant les
                      facteurs de risque, ainsi que des recommandations en dehors
                      de la pose de tout diagnostic ou encore de la prescription
                      de traitement. Ce service ne constitue pas un acte de
                      télémédecine au sens des dispositions des articles L.6316-1
                      et R. 6316-1 1° du Code de la santé publique France.
                    </li>
                    <li>
                      Service de téléconsultation dispensé par l'Utilisateur
                      Professionnel de santé. Ce service constitue un acte de
                      télémédecine au sens des dispositions des articles L.6316-1
                      et R. 6316- 1 1° du Code de la santé publique France.
                    </li>
                  </ul>
                  <p>
                    L’Utilisateur Professionnel de santé pourra analyser,
                    compléter, diagnostiquer et prescrire le cas échéant des
                    bilans et des traitements. Il pourra à tout moment compléter
                    le parcours de soins par une consultation médicale
                    présentielle conventionnelle.
                  </p>
                  <p>
                    Ces Services permettent un échange de données médicales entre
                    l’Utilisateur Patient et l’Utilisateur Professionnel de santé.
                    Cet échange peut se faire par le biais de formulaires en ligne
                    et/ou de téléconsultation à distance (par visioconférence).
                  </p>
                  <p>
                    La plateforme et les outils numériques MEDEO n’ont toutefois
                    pas vocation à se substituer à la consultation médicale
                    présentielle conventionnelle, si celle-ci est jugée utile par
                    l’Utilisateur Professionnel de santé.
                  </p>
                </div>
                <h4>
                  Article 5. Information préalable à toute commande 4.1.
                  Conditions d’accès et d’utilisation du service
                </h4>
                <div>
                  <h5>a. Équipement nécessaire</h5>
                  <p>
                    L’application MEDEO est disponible sur les ordinateurs PC ou
                    Mac équipés des navigateurs Firefox, Chrome ou Safari dans
                    leur dernière version majeure, ainsi que sur les tablettes
                    utilisant les systèmes d’exploitation iOS ou Androïd.
                  </p>
                  <p>
                    La qualité du Service de téléconsultation par visioconférence
                    dépend de la bande passante, idéalement sur un réseau Wifi ou
                    4G. Les échanges de données numériques peuvent être réalisés
                    via un réseau Wifi ou 3G/4G.
                  </p>
                  <p>
                    ATTENTION : l’application MEDEO n’est pas compatible avec le
                    navigateur Internet Explorer.
                  </p>
                  <h5>
                    b. Création du compte personnel de l’Utilisateur Professionnel
                    de santé
                  </h5>
                  <p>
                    Pour pouvoir accéder à l’application MEDEO et fournir les
                    Services, l’Utilisateur Professionnel de santé devra demander
                    la création d’un compte professionnel par mail à
                    contact@medeo-health.com.
                  </p>
                  <p>
                    Les informations suivantes sont nécessaires pour l’inscription
                    : nom, prénoms, titre, date de naissance, sexe, adresse email,
                    numéro de téléphone mobile, adresse professionnelle, numéro
                    d’inscription au répertoire national des professionnels de
                    santé. Il devra également définir un mot de passe.
                  </p>
                  <p>
                    Pour valider son inscription, l’Utilisateur Professionnel de
                    santé devra fournir à MEDEO une photo au format numérique et
                    un justificatif d’inscription à l’Ordre des Médecins – si
                    applicable à son activité - où figure son numéro d’inscription
                    et sa qualification. Ce document peut être fourni dans un
                    format numérique.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé atteste par la présente
                    de l’exactitude des informations renseignées dans le
                    formulaire d’inscription et s’engage à ne pas usurper
                    l’identité d’un tiers, ni à masquer ou modifier des
                    informations le concernant.
                  </p>
                  <p>
                    MEDEO se réserve le droit de demander à l’Utilisateur
                    Professionnel de santé de lui communiquer, sans que ce soit
                    limitatif, tous les justificatifs, diplômes, certificats,
                    homologations, autorisations professionnelles, permettant de
                    certifier ces informations. MEDEO se réserve le droit de
                    désactiver le compte en cas de manquement à cette demande.
                  </p>
                  <p>
                    Pour finaliser son inscription, l’Utilisateur Professionnel de
                    santé sera invité à accepter les présentes CGU et le Devis.
                  </p>

                  <h5>c. Gestion du compte personnel de l’Utilisateur</h5>
                  <p>
                    L’identifiant et le Mot de passe de connexion sont strictement
                    personnels à chaque Utilisateur. Leur protection est placée
                    sous la seule responsabilité de l’Utilisateur. Il appartient à
                    celui-ci de mettre en œuvre toutes les mesures de sécurité
                    nécessaires à la protection de son Mot de passe et de son
                    identifiant de connexion et à ne pas les divulguer sous aucun
                    prétexte et à quelque titre que ce soit.
                  </p>
                  <p>
                    En cas de perte ou de vol de son Mot de passe, ou si
                    l’Utilisateur a connaissance de l’utilisation de son Mot de
                    passe par un tiers non autorisé, la responsabilité de MEDEO ne
                    pourra en aucun cas être engagée et l’Utilisateur devra
                    immédiatement le changer en cliquant sur le lien « Mot de
                    passe oublié» sur la page de connexion, ou envoyer un mail à
                    l’adresse suivante: contact@medeo-health.com pour une
                    réinitialisation de son Mot de passe ou si besoin une
                    désactivation temporaire de son compte.
                  </p>

                  <h5>d. Résiliation du compte personnel de l’Utilisateur</h5>
                  <p>
                    Chaque Utilisateur accepte que MEDEO résilie immédiatement son
                    accès à l’application MEDEO sans préavis ni mise en demeure ni
                    indemnité de quelque nature que ce soit, à l’échéance du
                    contrat souscrit par l’Utilisateur Professionnel de Santé, ou
                    en cas de manquement à l’une quelconque des obligations
                    décrites aux présentes CGU, ou à la Réglementation. Sans
                    limitation de ce qui précède, sont également susceptibles de
                    fonder une résiliation de l’inscription de l’Utilisateur par
                    MEDEO :
                  </p>
                  <ul>
                    <li>
                      Le non-respect d’une disposition légale ou réglementaire en
                      vigueur ;
                    </li>
                    <li>
                      Une tentative de connexion non autorisée, par une
                      utilisation frauduleuse du système ou par une usurpation des
                      éléments d’authentification.
                    </li>
                  </ul>
                  <p>
                    L’Utilisateur Professionnel de santé peut se désinscrire et
                    clôturer son compte personnel à tout moment en adressant une
                    lettre recommandée avec accusé de réception à MEDEO.
                  </p>
                  <p>
                    En aucun cas MEDEO ne pourra être tenue responsable des
                    conséquences directes et indirectes liées à une désinscription
                    de la part d’un Utilisateur Professionnel de santé.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé peut être représenté par
                    sa société civile ou sa société d’exercice libéral. Dans ce
                    cas, les devoirs et responsabilités mentionnés dans les
                    présentes CGU s’appliquent à l’ensemble des membres
                    constitutifs de la société civile ou de la société d’exercice
                    libéral.
                  </p>
                  <p>
                    L’Utilisateur est informé que la fermeture de son compte
                    personnel n’entraînera pas la suppression automatique des
                    données à caractère personnel le concernant. Elles seront
                    conservées, dans le respect des exigences posées par la
                    Réglementation, ce qu’accepte expressément l’Utilisateur. Son
                    attention est également attirée sur le fait qu’à l’issue de
                    cette action il ne sera plus en mesure d’accéder à
                    l’application MEDEO et aux Services.
                  </p>

                  <h5>e. Souscription à un Abonnement</h5>

                  <p>
                    Le Client reconnaît avoir été parfaitement informé que
                    l’utilisation de la Solution MEDEO suppose en outre de
                    souscrire à un Abonnement.
                  </p>
                  <p>
                    A défaut de souscrire à un Abonnement, le Client reconnaît et
                    accepte qu’il ne pourra pas utiliser la Solution et que
                    l’Equipement comme le Logiciel cesseront de fonctionner.
                  </p>
                  <p>
                    Sauf stipulation contraire, les Accessoires inclus dans
                    l’offre commerciale souscrite par le Client pourront
                    fonctionner indépendamment de la souscription d’un Abonnement.
                  </p>
                </div>
                <h4>Article 6. DEVOIRS ET RESPONSABILITES</h4>
                <div>
                  <h5>a. MEDEO</h5>
                  <p>
                    MEDEO fera ses meilleurs efforts pour assurer à l’Utilisateur
                    une continuité de service des applications et d’accès aux
                    données 24h/24 et 7j/7.
                  </p>
                  <p>
                    MEDEO fera ses meilleurs efforts pour assurer une sécurité
                    optimale pour protéger les données personnelles des
                    Utilisateurs, ainsi que les données de santé des Utilisateurs
                    Patients. L’hébergement des données est assuré chez un
                    Hébergeur Agréé santé selon les normes en vigueur.
                  </p>
                  <p>L’accès aux Services est sécurisé par un Mot de passe.</p>
                  <p>
                    En cas de faille de sécurité détectée ou signalée, MEDEO peut
                    suspendre l’accès aux Services et dossiers concernés et
                    s’engage à informer les Utilisateurs dans les plus brefs
                    délais.
                  </p>
                  <p>
                    MEDEO s’engage à ne pas utiliser les données nominatives de
                    l’Utilisateur Patient sans son autorisation. Les données
                    utilisées à titre statistique ou dans un but de recherche sont
                    systématiquement anonymisées.
                  </p>
                  <p>
                    MEDEO s’engage à assurer un service d’assistance en ligne
                    accessible 24h/24 et 7j/7.
                  </p>

                  <p>
                    La responsabilité de MEDEO ne pourra pas être recherchée si
                    l'exécution de l'une de ses obligations est empêchée ou
                    retardée en raison d'un cas de force majeure tel que définie
                    par l’article 1218 du Code Civil France.
                  </p>

                  <h5>b. Utilisateur Professionnel de santé</h5>
                  <p>
                    L’Utilisateur Professionnel de santé reconnaît et accepte
                    qu’il lui appartient de respecter les règles déontologiques
                    propres à sa profession (médecin, pharmacien, infirmier etc.).
                  </p>
                  <p>
                    Lorsque l’Utilisateur Professionnel de santé est un médecin,
                    celui-ci est inscrit auprès du Conseil National de l’Ordre des
                    Médecins. Il s'engage dans le cadre de son utilisation de
                    l’application MEDEO, à respecter l'intégralité des règles
                    déontologiques gouvernant l'exercice de sa profession, telles
                    qu'elles sont définies dans le Code de déontologie et
                    interprétées par le Conseil National de l’ordre des Médecins.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé reconnaît que son
                    utilisation de l’application MEDEO, et la fourniture des
                    Services dans ce cadre ne portent pas atteinte à ses
                    obligations déontologiques applicables à sa profession et ce,
                    plus particulièrement au regard du principe d'indépendance ou
                    encore d’interdiction de tout procédé direct ou indirect de
                    publicité.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé s'engage également à
                    respecter le secret le plus absolu sur les informations de
                    santé et relatives à la vie privée des Utilisateurs Patients,
                    dont il a eu connaissance par quelque moyen que ce soit, et ce
                    conformément à ses obligations légales, réglementaires et
                    déontologiques découlant des articles L.1110-4, L.1110-12,
                    R.1110-1 et suivants du Code de la santé publique, 226-13 du
                    Code pénal et du Code de déontologie régissant l’exercice de
                    sa profession.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé s'interdit en conséquence
                    de communiquer, directement ou indirectement, l'une quelconque
                    des informations concernant les Utilisateurs Patients et
                    soumise à la protection du secret médical, à tout tiers non
                    autorisé. A ce titre, l’Utilisateur Professionnel de santé
                    fait son affaire personnelle de toute action disciplinaire qui
                    pourrait être introduite à son encontre ayant comme fondement
                    un acte, un comportement ou une attitude contraire à l'une
                    quelconque des obligations déontologiques qui préside à
                    l'exercice de sa profession et ce y compris dans le cadre de
                    son utilisation de l’application MEDEO.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé s’oblige à apporter, dans
                    le domaine de ses qualifications ordinales, des informations
                    appropriées et intelligibles par l’Utilisateur Patient, en se
                    fondant sur les données acquises de la science et la
                    connaissance du dossier médical du patient concerné. Il assume
                    la responsabilité des informations, diagnostics et
                    prescriptions éventuelles qu’il fournit. Il est exclusivement
                    responsable des actes médicaux qu’il peut être amené à
                    délivrer à l’Utilisateur Patient, le cas échéant en dehors de
                    l’application MEDEO.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé garantit MEDEO contre
                    toutes réclamations, revendications de propriété ou toutes
                    actions judiciaires ou extrajudiciaires relatives aux
                    informations qu’il émet ou aux actes médicaux qu’il effectue,
                    et s’engage à dédommager intégralement MEDEO de ses frais et
                    pertes qui pourraient lui être causés de ce fait.
                  </p>
                  <p>
                    En aucun cas, la responsabilité de l’Utilisateur Professionnel
                    de santé ni celle de MEDEO ne pourra être recherchée en cas
                    d’absence de réponse à une sollicitation de la part d’un
                    Utilisateur Patient.
                  </p>
                </div>
                <h5>
                  Article 7. ACTE DE TELEMEDECINE - SERVICE DE TELECONSULTATION
                </h5>
                <div>
                  <p>
                    Afin de fournir le service de téléconsultation, l’Utilisateur
                    Professionnel de santé va sélectionner sur l’application MEDEO
                    des créneaux de disponibilité. L’Utilisateur Patient pourra
                    alors prendre rendez-vous avec l’Utilisateur Professionnel de
                    santé sur ces créneaux.
                  </p>
                  <p>
                    Le Service est délivré par l’Utilisateur Professionnel de
                    santé avec le consentement libre et éclairé de l’Utilisateur
                    Patient, après information :
                  </p>
                  <ul>
                    <li>de la collecte et du traitement de ses données,</li>
                    <li>
                      du partage de ses données entre les Utilisateurs
                      Professionnels de santé participants à la fourniture des
                      Services,
                    </li>
                    <li>
                      du possible partage de son compte-rendu avec son médecin
                      traitant avec son consentement et sur transmission de sa
                      part selon son choix à son médecin traitant,
                    </li>
                    <li>
                      des modalités de réalisation de l’acte de téléconsultation.
                    </li>
                  </ul>
                  <p>
                    Ce consentement est recueilli, avant tout acte de
                    téléconsultation, par voie électronique par le biais d’une
                    case à cocher associée à une convention de preuve contenue
                    dans les présentes CGU.
                  </p>
                  <p>
                    L’Utilisateur Professionnel de santé exerce son activité dans
                    le respect des dispositions applicables à son activité,
                    notamment du Code de la santé publique et du Code de
                    déontologie réglementant sa profession d’une part, et dans le
                    respect des conditions de mise en œuvre de l’activité de
                    télémédecine telles que prévues par le décret n°2010- 1229 du
                    19 octobre 2010 relatif à la télémédecine, d’autre part.
                  </p>
                  <p>
                    Aussi conformément au décret précité, le Service de
                    téléconsultation est délivré par un Utilisateur Professionnel
                    de santé choisi par un Utilisateur Patient dans des conditions
                    garantissant :
                  </p>
                  <ul>
                    <li>
                      l’authentification des Utilisateurs Professionnels de santé
                      intervenant dans l'acte de téléconsultation ;
                    </li>
                    <li>l’identification de l’Utilisateur Patient ;</li>
                    <li>
                      l'accès de l’Utilisateur Professionnel de santé aux données
                      médicales de l’Utilisateur Patient nécessaires à la
                      réalisation de l'acte.
                    </li>
                  </ul>
                  <p>
                    Si applicable, sont inscrits dans le dossier de l’Utilisateur
                    Patient tenu par chaque Utilisateur Professionnel de santé
                    intervenant dans l'acte de téléconsultation, et dans la fiche
                    d'observation mentionnée à l'article R.4127-45 du Code de la
                    santé publique :
                  </p>
                  <ul>
                    <li>le compte rendu de la réalisation de l'acte ;</li>
                    <li>
                      les actes et les prescriptions effectués dans le cadre de
                      l'acte de téléconsultation ; · l'identité des Utilisateurs
                      Professionnels de santé participant à l'acte ;
                    </li>
                    <li>la date et l'heure de l'acte ;</li>
                    <li>
                      Le cas échéant, les incidents techniques survenus au cours
                      de l'acte.
                    </li>
                  </ul>
                  <p>
                    Le paiement des honoraires de l’Utilisateur Professionnel de
                    santé par l’Utilisateur Patient s’effectuera par le biais du
                    compte Stripe ouvert par l’Utilisateur Professionnel de santé.
                    A ce titre, l’Utilisateur Professionnel de santé reconnait et
                    accepte qu’il est seul responsable de l’ouverture d’un compte
                    Stripe en son nom, étant entendu que MEDEO pourra fournir aide
                    et assistance à l’Utilisateur Professionnel de santé dans
                    cette démarche.
                  </p>
                </div>
                <h4>Article 8. Vente ou location du Matériel</h4>
                <div>
                  <h5>a. Prix et Spécifications techniques du Matériel</h5>
                  <p>
                    Le Matériel est disponible soit à la vente soit à la location
                    financière. Le Devis précisera l’option sélectionnée par le
                    Client.
                  </p>
                  <p>
                    Le prix et les spécifications techniques du Matériel vendus
                    figurent sur le Site et sont rappelés lors de la confirmation
                    de la commande.
                  </p>
                  <p>La durée de la location est indiquée sur le Devis.</p>
                  <p>
                    Lors de la commande, le prix indiqué au Client s’entend de
                    toutes charges comprises.
                  </p>
                  <p>
                    L’Equipement et les Accessoires vendus au Client dans le cadre
                    de la commande peuvent dépendre de l’offre commerciale choisie
                    par le Client.
                  </p>
                  <p>
                    Le contenu des offres commerciales de MEDEO ainsi que le prix
                    et les spécifications du Matériel vendu sont détaillées sur le
                    Site ainsi que dans le catalogue MEDEO.
                  </p>

                  <h5>b. Paiement</h5>
                  <p>
                    Le Client effectue son paiement avant l‘exécution de la
                    commande de matériel.
                  </p>
                  <p>
                    Le paiement s’effectue par virement ou par le biais d’une
                    interface sécurisée fournie par un prestataire technique agréé
                    sur le Site.
                  </p>

                  <h5>c. Livraison</h5>
                  <p>
                    Lors de sa commande, le Client choisit un des modes de
                    livraison.
                  </p>
                  <p>
                    Les frais de livraison peuvent varier suivant le mode de
                    livraison choisi par le Client.
                  </p>
                  <p>
                    Les délais de livraison peuvent varier suivant la
                    disponibilité du Matériel choisi par le Client et sont donc
                    donnés à titre indicatif. Les délais de livraison sont
                    calculés à compter de la confirmation de la commande par
                    MEDEO.
                  </p>
                  <p>
                    En cas de retard dans la livraison, la commande ne sera pas
                    annulée. MEDEO s’engage à en informer le Client par courrier
                    électronique.
                  </p>
                  <p>
                    Lors de la livraison, le Client s’engage à vérifier l’état du
                    Matériel livré et à émettre directement auprès du transporteur
                    toutes les réserves qu’il estimerait nécessaire ou de refuser
                    un colis qui aurait subi une avarie. Le Client effectuera une
                    réclamation écrite au transporteur, par lettre recommandée
                    avec demande d’avis de réception, dans les trois jours
                    ouvrables qui suivent la date de la livraison, conformément à
                    l’article L. 133-3 du code de commerce France.
                  </p>

                  <h5>d. Matériovigilance</h5>
                  <p>
                    Conformément aux dispositions légales du code de la santé
                    publique (Article L. 5212-2), l’Utilisateur est tenu de
                    signaler sans délai à l’Agence nationale de sécurité du
                    médicament et des produits de santé, lorsqu’il en a
                    connaissance, tout incident ou risque d’incident mettant en
                    cause un dispositif médical ayant entraîné la mort ou la
                    dégradation de l’état de santé d’un patient, d’un utilisateur
                    ou d’un tiers.
                  </p>

                  <p>
                    L’Utilisateur en informera également MEDEO, qui signalera
                    toute notification au fabricant et à l’Agence nationale de
                    sécurité du médicament et des produits de santé afin que MEDEO
                    puisse satisfaire pleinement aux obligations qui lui incombe.
                  </p>
                </div>
                <h4>Article 9. Abonnements</h4>
                <div>
                  <h5>a. Effet, durée et reconduction de l’Abonnement</h5>
                  <p>
                    L’Abonnement prend effet à compter de la mise en place
                    effective de la solution.
                  </p>
                  <p>
                    La licence d’utilisation de la Solution et l’ensemble des
                    Services prévus aux présentes sont souscrits par l’Utilisateur
                    sous la forme d’un Abonnement, dont la durée initiale et à la
                    date de début sont indiquées dans le Devis.
                  </p>
                  <p>
                    Cet abonnement se renouvellera ensuite tacitement pour des
                    périodes successives de même durée, aux mêmes termes et
                    conditions, sauf dénonciation par l’une ou l’autre des Parties
                    adressée à l’autre Partie, par lettre recommandée avec avis de
                    réception, au minimum 3 (trois) mois avant l’expiration de la
                    période concernée.
                  </p>
                  <p>
                    La durée initiale et les périodes de renouvellement de
                    l’abonnement seront dénommées ci- après conjointement les «
                    Périodes d’Abonnement ».
                  </p>

                  <h5>b. Description de l’Abonnement</h5>
                  <p>Choix de l’Abonnement</p>
                  <p>
                    Lors de sa commande, le Client choisit la formule d’Abonnement
                    qui correspond le mieux à ses besoins professionnels.
                  </p>
                  <p>
                    Chaque formule d’Abonnement inclut des Services différents.
                  </p>
                  <p>
                    Les différents Abonnements commercialisés par MEDEO et les
                    Services qui y sont liés sont détaillés sur le Site et
                    figurent également sur le catalogue de MEDEO.
                  </p>
                  <p>Services compris dans tout Abonnement</p>
                  <p>
                    Sous réserve du paiement effectif par le Client des redevances
                    dues et pour toute la durée de l’Abonnement, toute formule
                    d’Abonnement comprend les Services suivants :
                  </p>
                  <ul>
                    <li>
                      Licence d’Utilisation du Logiciel de transfert sécurisé des
                      données
                    </li>
                    <li>
                      Mises à jour du Logiciel et de la compatibilité
                      logiciel/matériel
                    </li>
                    <li>Support technique et assistance téléphonique</li>
                    <li>
                      Formation et installation à distance en cas de demande
                      spécifique de la part de l’utilisateur
                    </li>
                  </ul>
                  <p>Services supplémentaires</p>
                  <p>
                    Outre les Services compris dans tout Abonnement, le Client
                    pourra bénéficier de Services supplémentaires compris dans sa
                    formule d’Abonnement ou sur demande spécifique du Client à
                    MEDEO, sous réserve de son acceptation.
                  </p>

                  <h5>c. Conditions financières de l’Abonnement</h5>
                  <p>Redevances de l’Abonnement</p>
                  <p>
                    L’accès aux Services suppose que le Client dispose d’un
                    Abonnement.
                  </p>
                  <p>
                    Le montant des redevances figure sur le Devis et dépend de
                    l’offre commerciale, des options et des modalités de paiement
                    choisis par le Client.
                  </p>
                  <p>
                    Le Client ne peut accéder aux Services qu’une fois le paiement
                    reçu par MEDEO.
                  </p>
                  <p>Modalités de paiement</p>
                  <p>
                    Les Services sont facturés par prélèvement bancaire, chaque
                    mois à la date d’anniversaire de l’Abonnement.
                  </p>
                  <p>
                    A l’expiration de chaque Abonnement, et en l’absence de
                    résiliation notifiée par le Client dans les conditions
                    prévues, l’Abonnement est reconduit automatiquement d’un
                    montant et d’une durée identiques à ceux initialement
                    souscrit.
                  </p>
                  <p>Défaut de paiement</p>
                  <p>
                    De convention expresse entre les Parties, tout retard de
                    paiement de tout ou partie d’une somme due à MEDEO à son
                    échéance au titre des présentes CGU entraînera
                    automatiquement, sans préjudice des dispositions de l’article
                    « Résiliation » et dès le jour suivant la date de règlement
                    figurant sur la facture :
                  </p>
                  <ol>
                    <li>
                      La déchéance du terme de l’ensemble des sommes dues par le
                      Client et leur exigibilité immédiate, quelles que soient les
                      modalités de règlement qui avaient été prévues ;
                    </li>
                    <li>
                      La suspension immédiate des présentes CGU jusqu’au complet
                      paiement de l’intégralité des sommes dues ;
                    </li>
                    <li>
                      La facturation au profit de MEDEO d’un intérêt de retard, dû
                      par le seul fait de l’échéance du terme contractuel, au taux
                      de trois fois le taux d’intérêt légal, assis sur le montant
                      de la créance non réglée à l’échéance et d’une indemnité
                      forfaitaire de 40 (quarante) € au titre des frais de
                      recouvrement, sans préjudice d’une indemnisation
                      complémentaire si les frais de recouvrement effectivement
                      exposés sont supérieurs à ce montant.
                    </li>
                  </ol>
                  <p>Révision des prix</p>
                  <p>
                    A chaque renouvellement de l’Abonnement, MEDEO pourra en
                    réviser le prix.
                  </p>
                  <p>
                    Le Client sera alors libre de résilier l’Abonnement s’il n’a
                    pas été informé du nouveau tarif applicable avant la date
                    limite de résiliation de l’Abonnement.
                  </p>

                  <h5>
                    d. Conditions financières applicables aux Utilisateurs
                    Patients
                  </h5>
                  <p>
                    Selon les Services souscrits par le Client, l’Utilisateur
                    Patient aura la possibilité d’effectuer des paiements sur le
                    Site.
                  </p>
                  <p>
                    Les Utilisateurs Patients sont expressément informés et
                    acceptent que tous les paiements effectués à travers le Site,
                    sont gérés par la société Stripe, société agréée en tant
                    qu’établissement de monnaie électronique (ci-après «
                    Établissement de monnaie électronique »).
                  </p>
                  <p>
                    Les Utilisateurs Patients contractent directement avec
                    l’Établissement de monnaie électronique s’agissant de la mise
                    en œuvre de ces paiements, en acceptant les conditions
                    générales de l’Établissement de monnaie électronique par le
                    biais d’une case à cocher lors de leur inscription sur le
                    Site.
                  </p>
                </div>
                <h4>
                  Article 10. Licence d’utilisation du Logiciel Propriété
                  Intellectuelle de MEDEO
                </h4>
                <div>
                  <p>
                    Le Logiciel est protégé par le droit d’auteur français et par
                    les traités internationaux relatifs aux droits d’auteur, ainsi
                    que par toute loi ou traité relatifs à la propriété
                    intellectuelle.
                  </p>
                  <p>
                    Le Logiciel est et demeure la propriété exclusive de MEDEO.
                  </p>
                  <p>
                    MEDEO consent au Client, dans le cadre de l’Abonnement que
                    celui-ci souscrit, une licence d’utilisation non-exclusive ,
                    personnelle et non transmissible d’utilisation de la Solution,
                    dans sa version existante à la date des présentes et dans
                    toutes éventuelles versions à venir, ainsi que de sa
                    documentation technique, en mode SaaS, aux seules fins de la
                    fourniture des Services et pour les seules personnes que le
                    Client aura préalablement autorisées à utiliser la Solution
                    (ci-après: les “Utilisateurs”).
                  </p>
                  <p>
                    Toute demande de modification du paramétrage de la Solution
                    et/ou d’ajout de nouveaux Services et/ou de nouvelles
                    fonctionnalités par le Client fera l’objet d’une commande
                    spécifique. En cas d’accord des Parties sur les conditions,
                    notamment financières, d’adaptation de la présente licence,
                    celles-ci feront l’objet d’un devis complémentaire.
                  </p>
                  <p>Acceptation du Client</p>
                  <p>
                    Toute validation d’un Devis, expresse ou implicite, emporte
                    acceptation pleine et entière des présentes conditions
                    générales, dans leur version en vigueur à la date du Devis
                    concerné. Toute acceptation sous réserve est considérée comme
                    nulle et non avenue. Le Client qui n’accepte pas d’être lié
                    par les présentes CGU ne doit pas commander de Services auprès
                    de MEDEO.
                  </p>
                  <p>Droits concédés</p>
                  <p>
                    MEDEO concède au Client les droits spécifiés ci-après, étant
                    précisé que MEDEO conservera les droits qui ne sont pas
                    expressément concédés.
                  </p>
                  <p>
                    Sous réserve du paiement effectif des redevances dues au titre
                    de l’Abonnement, MEDEO concède au Client uniquement pour le
                    Territoire, les droits non-exclusifs suivants :
                  </p>
                  <ul>
                    <li>
                      Utilisation, chargement, affichage et exécution du Logiciel
                      ;
                    </li>
                    <li>
                      Installation du Logiciel dans sa forme exécutable
                      uniquement, sur un nombre raisonnable d’ordinateurs, en
                      garantissant que le Logiciel est utilisé ou exécuté
                      uniquement par le Client, à l’exclusion de toute exécution
                      ou utilisation simultanée par plusieurs utilisateurs ;
                    </li>
                    <li>
                      La possibilité d’effectuer, en nombre raisonnable, des
                      copies du Logiciel vers des dispositifs de stockage tels que
                      disques durs, pourvu que le Client respecte les termes de la
                      présente Licence. Cette copie ne peut être utilisée qu’à des
                      fins de sauvegarde dans le but de réinstaller le Logiciel ;
                    </li>
                  </ul>
                  <p>
                    Ces droits ne peuvent être vendus ou transférés à un tiers
                    sans l’accord préalable de MEDEO.
                  </p>
                  <p>Mise en œuvre de la Licence</p>
                  <p>
                    Dès qu’il aura validé l’accès à la Solution dans les
                    conditions visées ci-dessus, MEDEO ouvre un compte au nom du
                    Client, qui lui permet de gérer son utilisation des Services
                    via la Solution (ci-après : le « Compte Client »).
                  </p>
                  <p>
                    Il appartient au Client de sélectionner les Utilisateurs ayant
                    accès à la Solution, dans la limite du nombre maximum prévu
                    dans le Devis, de déterminer la nature des accès qui leur sont
                    donnés, ainsi que les données et informations auxquelles ils
                    ont accès. Les différentes conditions d’accès des Utilisateurs
                    sont décrites dans le Devis.
                  </p>
                  <p>
                    Il est également de la responsabilité du Client de désigner,
                    parmi les Utilisateurs, celui ou ceux qui seront en charge de
                    l’administration de la Solution (ci-après : les «
                    Administrateurs »), ainsi que de communiquer à MEDEO, par tous
                    moyens écrits utiles, les noms et adresses email des
                    Administrateurs et des Utilisateurs, afin que MEDEO ouvre un
                    compte au nom de chacun d’eux (ci-après : les « Comptes
                    Utilisateurs »).
                  </p>
                  <p>Maintenance corrective</p>
                  <p>
                    La maintenance corrective a pour objet de corriger, pendant la
                    durée des CGU, les anomalies relevées sur la Solution, ainsi
                    que sur ses nouvelles versions, entendues comme un bogue ou
                    dysfonctionnement de celles-ci (ci-après : les « Anomalies »).
                  </p>
                  <p>Les Anomalies se répartissent en trois catégories :</p>
                  <p>
                    « Anomalie Aléatoire » : une erreur ou une Anomalie est dite
                    aléatoire lorsque les causes du défaut sont non
                    reproductibles.;
                  </p>
                  <p>
                    « Anomalie Majeure » : Anomalie diminuant l’utilisation de la
                    Solution en empêchant l’utilisation de certaines
                    fonctionnalités essentielles ;
                  </p>
                  <p>
                    « Anomalie Mineure » : toute Anomalie mettant le Client dans
                    l’impossibilité d’utiliser une ou plusieurs fonctionnalités
                    non essentielles de la Solution.
                  </p>
                  <p>
                    Le Client pourra signaler à MEDEO toutes Anomalies par email à
                    l’adresse de contact suivante assistance@medeo-health.com ou
                    via le numéro vert 0 805 69 22 33.
                  </p>
                  <p>
                    Le Client s’efforcera de donner le maximum d’informations,
                    notamment les difficultés rencontrées et les circonstances
                    dans lesquelles elles sont intervenues, de nature à permettre
                    à MEDEO de caractériser l’incident. La réception de cette
                    notification vaut signalement d’une Anomalie.
                  </p>
                  <p>
                    MEDEO procèdera alors au diagnostic de l’Anomalie, en
                    vérifiant notamment si elle a pour origine la Solution et, le
                    cas échéant, procédera à la qualification de l’Anomalie en
                    Anomalie Aléatoire, Majeure ou Mineure. MEDEO informera le
                    Client, par email des résultats de ce diagnostic dans les
                    meilleurs délais.
                  </p>
                  <p>
                    S’il s’avère que l’Anomalie a pour origine la Solution, MEDEO
                    s’engage à mobiliser les moyens nécessaires pour la corriger
                    dans les délais suivants :
                  </p>
                  <ul>
                    <li>
                      Anomalie Mineure : Garantie du temps d’intervention (GTI) en
                      heures ouvrées : 16H Garantie du Temps de rétablissement
                      (GTR) en heures ouvrées : 24H
                    </li>
                    <li>
                      Anomalie Majeure : Garantie du temps d’intervention (GTI) en
                      heures ouvrées : 8H Garantie du Temps de rétablissement
                      (GTR) en heures ouvrées : 16H
                    </li>
                    <li>
                      Anomalie Aléatoire : Garantie du temps d’intervention (GTI)
                      en heures ouvrées : 8H
                    </li>
                  </ul>
                  <p>
                    Ces délais s’effectuent dans le cadre des plages
                    d’intervention de MEDEO, à savoir entre 9 heures 30 et 12H
                    puis entre 13 heures et 18 heures pendant les jours ouvrés. Un
                    jour ouvré s’entend d’une période ininterrompue de dix (10)
                    heures, du lundi au vendredi, entre 8 heures et 18 heures.
                    Tout délai ayant débuté au cours de cette tranche horaire
                    expirera à la même heure le dernier jour ouvré du délai.
                  </p>
                  <p>Maintenance évolutive</p>
                  <p>
                    MEDEO s’engage à faire bénéficier le Client, pendant la durée
                    des CGU :
                  </p>
                  <ul>
                    <li>
                      de toutes améliorations des fonctionnalités de la Solution,
                      en rapport avec son ergonomie, sa rapidité d’exécution ou
                      son efficacité, et/ou de toutes révisions visant à
                      introduire des extensions mineures de la Solution (ci-après
                      : les « Mises à Jour »),
                    </li>
                    <li>
                      de toutes modifications de la Solution et de la
                      documentation y afférente par l’ajout de nouvelles
                      fonctionnalités (ci-après : les « Nouvelles Versions »).
                    </li>
                  </ul>
                  <p>
                    La nature et la régularité de ces Mises à Jour et Nouvelles
                    Versions seront laissées à la libre appréciation de MEDEO.
                  </p>
                  <p>
                    Les Mises à Jour pourront être effectuées automatiquement et
                    sans information préalable, ce que le Client accepte
                    expressément.
                  </p>
                  <p>
                    MEDEO informera préalablement le Client, par tout moyen écrit
                    utile et notamment par email, de toute Nouvelle Version de la
                    Solution au minimum quinze (15) jours ouvrés avant son
                    installation.
                  </p>
                  <p>Durée et Frais</p>
                  <p>
                    La Licence est consentie pour la durée de l’Abonnement
                    souscrit par le Client, sous réserve du paiement effectif des
                    redevances dues.
                  </p>
                  <p>
                    En conséquence, le non-paiement des redevances de l’Abonnement
                    par le Client ou la résiliation de l’Abonnement du Client,
                    quelle qu’en soit la cause et la Partie à l’initiative de
                    cette résiliation, entrainera le retrait de la présente
                    Licence.
                  </p>
                  <p>
                    Le Client est informé que la survenance d’un de ces évènements
                    entrainera automatiquement la désactivation immédiate et à
                    distance des fonctionnalités du Logiciel par MEDEO.
                  </p>
                  <p>Interdictions et restrictions</p>
                  <p>La Licence n’autorise pas le Client à :</p>
                  <ul>
                    <li>
                      Modifier le Logiciel dans le but de corriger des erreurs ou
                      défaillances sans l’accord préalable de MEDEO ;
                    </li>
                    <li>
                      Louer, partager, sous-licencier le Logiciel ou accorder
                      quelque sorte de droit que ce soit en lien avec le Logiciel,
                      ou une partie de celui-ci à une tierce partie sans l’accord
                      préalable de MEDEO ;
                    </li>
                    <li>
                      Traduire, réaliser de l’ingénierie inversée, décompiler,
                      désassembler totalement ou partiellement le Logiciel, sauf
                      si une telle activité est conforme aux conditions de
                      l’article L. 112-6-1 du code de la propriété intellectuelle.
                      Si des problèmes d’interopérabilité entre le Logiciel et
                      d’autres programmes apparaissent, le Client en informera
                      MEDEO qui étudiera les solutions possibles ;
                    </li>
                    <li>
                      Retirer ou retoucher toute identification relative au
                      Logiciel, la notice de propriété, les labels ou marques qui
                      apparaissent sur le Logiciel ;
                    </li>
                    <li>
                      Révéler, distribuer, commercialiser, exposer publiquement,
                      promouvoir tout ou partie de la documentation afférente au
                      Logiciel, modifiée ou non, à toute tierce personne ;
                    </li>
                  </ul>
                  <p>
                    La Licence non exclusive est concédée personnellement au
                    Client et ne peut être vendue ni transférée à une tierce
                    personne sans l’autorisation préalable de MEDEO. En
                    conséquence, tout transfert à titre gratuit ou onéreux est
                    interdit au Client, excepté si cela a été expressément
                    autorisé par MEDEO par contrat séparé. Le Client s’engage à ne
                    jamais permettre à tout tiers à cette licence d’utiliser le
                    Logiciel sous quelque forme que ce soit et de mettre en œuvre
                    toute disposition raisonnable en vue d’assurer qu’aucune
                    utilisation non autorisée du Logiciel n’est réalisée. De même,
                    le Client ne pourra en aucun cas permettre à tout tiers à la
                    Licence de bénéficier de l’usage ou des fonctionnalités du
                    Logiciel sous la forme d’accord de « jouissance en temps
                    partagé » ou d’autres arrangements.
                  </p>
                  <p>Garanties et responsabilité</p>
                  <p>
                    MEDEO garantit le Client contre toute revendication en
                    contrefaçon qui pourrait être intentée contre le Client
                    relativement à la possession et/ou l’usage du Logiciel, sous
                    réserve que le Client informe MEDEO dans les plus brefs délais
                    à compter de la mise en demeure ou de l’assignation et que le
                    Client ait mis MEDEO en capacité de se défendre elle-même.
                  </p>
                  <p>
                    MEDEO garantit également la conformité du Logiciel aux
                    caractéristiques techniques décrites par MEDEO durant
                    quatre-vingt-dix jours à compter de la commande. En cas de
                    mise en jeu de cette garantie, MEDEO sera tenue exclusivement,
                    à ses propres frais, de réaliser toute mesure commercialement
                    raisonnable pour corriger toute erreur reproductible que le
                    Client lui rapporterait pendant la période de garantie, et
                    notamment en fournissant au Client une version modifiée du
                    Logiciel qui élimine l’erreur ou en fournissant des
                    instructions permettant d’éliminer les effets pratiques de
                    l’erreur. Toute correction d’erreur n’entrainera aucune
                    extension de la durée de garantie initiale. Cette garantie
                    sera nulle s’il s’avère que le défaut résulte d’un accident,
                    d’un usage abusif ou anormale, d’une modification non
                    autorisée du Logiciel ou d’un usage du Logiciel en relation
                    avec des produits, équipements matériels ou logiciels non
                    validés ou non fournis par MEDEO.
                  </p>
                  <p>
                    Le Client a et conserve le contrôle de l’usage du Logiciel
                    installé sur son équipement informatique. En conséquence, le
                    Client engage sa propre responsabilité concernant les
                    exploitations des résultats issus de l’usage du Logiciel. Le
                    Client reconnait que MEDEO n’endossera aucune responsabilité
                    envers lui ou une tierce partie, pour tout dommage qui
                    pourrait résulter de l’usage direct ou indirect du Logiciel,
                    excepté concernant les actions en contrefaçon.
                  </p>
                  <p>
                    De plus, MEDEO ne garantit pas que les opérations du Logiciel
                    soient ininterrompues ou dépourvues d’erreurs.
                  </p>
                  <p>
                    A ce titre, MEDEO fera ses meilleurs efforts pour maintenir un
                    accès à ses outils 24h/24h et 7j/7j et garantit la
                    disponibilité des Services à 99,5%, sauf cas de force majeure.
                  </p>
                  <p>
                    Eu égard à la complexité d’Internet, l’inégalité des capacités
                    des différents sous-réseaux, l’afflux à certaines heures des
                    Utilisateurs de la Solution, aux différents goulots
                    d’étranglement sur lesquels MEDEO n’a aucune maîtrise, la
                    responsabilité de MEDEO sera limitée au fonctionnement de ses
                    serveurs, dont les limites extérieures sont constituées par
                    les points de raccordement.
                  </p>
                  <p>
                    MEDEO ne saurait être tenu pour responsable (i) des vitesses
                    d’accès à ses serveurs, (ii) des ralentissements externes à
                    ses serveurs, et (iii) des mauvaises transmissions dues à une
                    défaillance ou à un dysfonctionnement de ces réseaux.
                  </p>
                  <p>
                    En cas de nécessité, MEDEO se réserve la possibilité de
                    limiter ou de suspendre l’accès à la Solution et aux Services
                    pour procéder à toute opération de maintenance de la Solution.
                    Dans cette hypothèse, MEDEO s’engage à informer le Client au
                    minimum 24 (vingt-quatre) heures à l’avance, par tous moyens
                    utiles et notamment par message informatif général sur la page
                    d’accueil du Site, de ces opérations de maintenance.
                  </p>
                </div>
                <h4>Article 11. OBLIGATIONS ET RESPONSABILITE</h4>
                <div>
                  <p>Obligations du Client</p>
                  <p>
                    Sans préjudice des autres obligations prévues aux présentes,
                    le Client s’engage à respecter les obligations qui suivent :
                  </p>
                  <p>
                    Le Client s’engage à fournir à MEDEO tous les documents,
                    éléments, données et informations nécessaires à la réalisation
                    des obligations de cette dernière au titre des présentes CGU.
                  </p>
                  <p>
                    Plus généralement le Client s’engage à coopérer activement
                    avec MEDEO en vue de la bonne exécution des présentes et à
                    l’informer de toutes difficultés liées à cette exécution.
                  </p>
                  <p>
                    Le Client déclare avoir reçu de MEDEO, préalablement à la
                    signature des présentes, tous conseils, instructions et
                    précisions qui lui sont nécessaires pour souscrire aux
                    présentes en toute connaissance de cause, qu’il dispose ainsi
                    d’une connaissance suffisante des caractéristiques et des
                    fonctionnalités de la Solution, telles que communiquées par
                    MEDEO, et qu’il a, préalablement aux présentes, suffisamment
                    échangé avec MEDEO pour s’assurer que le service de mise à
                    disposition de la Solution en mode SaaS correspond à ses
                    attentes, besoins et contraintes.
                  </p>
                  <p>
                    Le Client s’engage à informer les Utilisateurs de l’existence
                    de la Solution, ainsi qu’à leur fournir toutes informations
                    utiles pour leur permettre une bonne utilisation de celle-ci
                    et faciliter leur accès.
                  </p>
                  <p>
                    Le Client s’engage, dans son usage de la Solution et des
                    Services, à respecter les lois et règlements en vigueur et à
                    ne pas porter atteinte aux droits de tiers ou à l’ordre
                    public.
                  </p>
                  <p>
                    Il se porte garant contre toute mauvaise utilisation,
                    utilisation non conforme ou illicite, que les Utilisateurs
                    pourront faire de la Solution, notamment contre les atteintes
                    aux lois et règlements en vigueur. Le Client est ainsi
                    exclusivement responsable de la mise en place de toutes
                    procédures destinées à prévenir ou à réparer la commission de
                    tels actes.
                  </p>
                  <p>
                    Le Client est seul responsable du bon accomplissement de
                    toutes les formalités notamment administratives, fiscales et/
                    ou sociales et de tous les paiements de cotisations, taxes ou
                    impôts de toutes natures qui lui incombent le cas échéant en
                    relation avec son utilisation de la Solution et des Services.
                    La responsabilité de MEDEO ne pourra en aucun cas être engagée
                    à ce titre.
                  </p>
                  <p>
                    Le Client est seul responsable de son utilisation de la
                    Solution et des Services, et plus particulièrement : (i) de
                    l’utilisation de la Solution et des Services par les
                    Utilisateurs eux- mêmes dont il se porte garant, ainsi que
                    (ii) des relations pouvant naître entre lui, les Utilisateurs
                    et les tiers, notamment tous différends ou litiges ayant pour
                    cause ou origine l’utilisation de la Solution et des Services.
                    La responsabilité de MEDEO ne pourra en aucun cas être engagée
                    à ce titre.
                  </p>
                  <p>
                    Il appartient par ailleurs au Client d’alerter MEDEO de toute
                    attaque, menace potentielle ou tentative d’intrusion de tiers
                    dans les Comptes des Utilisateurs.
                  </p>
                  <p>
                    Le Client est seul responsable des informations renseignées
                    sur la Solution et de son éventuel diagnostic. Il garantit à
                    ce titre MEDEO contre toutes plaintes, réclamations, actions
                    et/ou revendications quelconques qu’elle pourrait subir du
                    fait de la violation, par le Client de l’une quelconque de ses
                    obligations aux termes des présentes. Le Client s’engage à
                    payer à MEDEO tous les frais, charges et/ou condamnations
                    qu’il pourrait avoir à supporter de ce fait.
                  </p>
                  <p>
                    Le Client est informé et accepte que l’utilisation de la
                    Solution et la mise en œuvre des Services nécessitent qu’il
                    possède les prérequis techniques communiqués par MEDEO,
                    notamment qu’il soit connecté à internet et que la qualité des
                    Services dépend directement de cette connexion, dont il est
                    seul responsable.
                  </p>
                  <p>
                    Le Client s’interdit, en son nom et au nom des Utilisateurs de
                    monnayer, céder, concéder ou transférer tout ou partie de ses
                    droits ou obligations au titre des présentes à un quelconque
                    tiers, y compris si ce tiers a un lien direct ou indirect avec
                    le Client ou l’un des Utilisateurs, de quelque manière que ce
                    soit.
                  </p>
                  <p>Obligations de MEDEO</p>
                  <p>
                    Sans préjudice des autres obligations prévues aux CGU, MEDEO
                    s’engage à respecter les obligations qui suivent :
                  </p>
                  <p>
                    MEDEO s’engage à exécuter ses obligations au titre des
                    présentes avec diligence et selon les règles de l’art, étant
                    précisé qu’il pèse sur elle une obligation de moyens, à
                    l’exclusion de toute obligation de résultat.
                  </p>
                  <p>
                    MEDEO s’oblige à mettre à disposition du Client un personnel
                    compétent et à fournir au Client les conseils, mises en garde
                    et informations nécessaires en vue de la bonne exécution des
                    présentes et de la fourniture des Services. Il s’oblige
                    également à informer le Client de toute difficulté prévisible
                    notamment quant à une difficulté de mise en œuvre de la
                    Solution et des Services ou à leur bon fonctionnement.
                  </p>
                  <p>
                    MEDEO s’engage à faire ses meilleurs efforts pour assurer la
                    sécurité de la Solution, du Compte Client et des Comptes
                    Utilisateurs.
                  </p>
                  <p>
                    La responsabilité de MEDEO ne saurait toutefois être engagée
                    en cas d’introduction malveillante sur l’espace de stockage
                    réservé au Client, dès lors qu’il n’est pas démontré que les
                    mesures de sécurité qu’elle a mises en place étaient gravement
                    défaillantes.
                  </p>
                  <p>
                    En outre, MEDEO ne saurait être tenue responsable de tout
                    manque de vigilance de la part des Utilisateurs dans le
                    maintien de la confidentialité de leur identifiant et de leur
                    mot de passe
                  </p>

                  <p>
                    MEDEO s’engage à n’utiliser les informations, documents,
                    données et plus généralement tous les éléments qui pourront
                    lui être transmis dans le cadre des présentes qu’aux fins
                    d’exécution des présentes et à ne pas les diffuser ou partager
                    avec quelque tiers que ce soit, sauf demande ou accord exprès
                    du Client.
                  </p>
                  <p>
                    Les Services définis aux présentes sont fournies par MEDEO
                    telles quelles et sans garantie d’aucune sorte, expresse ou
                    implicite. MEDEO ne garantit notamment pas au Client (i) que
                    la Solution et les Services, soumis à une recherche constante
                    pour en améliorer notamment la performance et le progrès,
                    seront totalement exempts d’erreurs, de vices ou défauts, (ii)
                    que la Solution et les Services, étant standard et nullement
                    proposés à la seule intention du Client en fonction de ses
                    propres contraintes personnelles, répondront spécifiquement à
                    ses besoins et attentes.
                  </p>
                  <p>
                    En tout état de cause, la responsabilité susceptible d’être
                    encourue par MEDEO au titre des présentes ne pourra porter que
                    sur les dommages directs subis par le Client et sera
                    expressément limitée au montant total du prix perçu par MEDEO
                    pendant les 12 (douze) mois précédant le fait générateur de
                    responsabilité.
                  </p>
                </div>
                <h4>Article 12. RESILIATION</h4>
                <div>
                  <p>
                    En cas de manquement par l’une des Parties à l’une quelconque
                    de ses obligations au titre des présentes CGU, celles-ci
                    seront résolues de plein droit 15 (quinze) jours après
                    réception par la Partie défaillante d’une mise en demeure,
                    restée sans effet, par lettre recommandée avec accusé de
                    réception, mentionnant l’intention de faire application de la
                    présente clause, sans préjudice de tous dommages et intérêts
                    qui pourront être réclamés à la Partie défaillante.
                  </p>
                </div>
                <h4>Article 13. CONFIDENTIALITE ET RESPECT DU SECRET MEDICAL</h4>
                <div>
                  <p>
                    Les informations personnelles concernant les Utilisateurs
                    Patients sont confidentielles.
                  </p>
                  <p>
                    De même, les échanges entre les Utilisateurs Patients et les
                    Utilisateurs Professionnels de santé dans le cadre des
                    Services peuvent donner lieu à un diagnostic ou à une
                    prescription de la part de l’Utilisateur Professionnel de
                    santé et sont, ainsi que les comptes rendus de ces échanges,
                    confidentiels et protégés par le secret médical.
                  </p>
                  <p>
                    Les échanges entre les Utilisateurs Patients et les
                    Utilisateurs Professionnels de santé se font par messagerie
                    sécurisée directement accessible par le biais de l’interface
                    de la solution MEDEO.
                  </p>
                  <p>
                    Pour autant, les questions posées par les Utilisateurs
                    Patients sont susceptibles d'être lues par du personnel non
                    médical mais habilité, affecté à la gestion de la solution
                    MEDEO. Ce personnel est contractuellement tenu au respect du
                    secret médical sous la responsabilité du personnel médical
                    habilité à en connaître.
                  </p>
                  <p>
                    La préservation de la sécurité des données à caractère
                    personnel des utilisateurs, engage les utilisateurs à :
                  </p>
                  <ul>
                    <li>
                      ne jamais communiquer des données de santé le concernant par
                      email non sécurisé ;
                    </li>
                    <li>
                      respecter les consignes de sécurité données par MEDEO ;
                    </li>
                    <li>respecter la gestion des accès au compte personnel ;</li>
                    <li>
                      garder strictement confidentiels ses éléments
                      d’authentification et ne pas les dévoiler à un tiers ;
                    </li>
                    <li>
                      informer MEDEO de tout dysfonctionnement technique ou
                      intrusions constatées.
                    </li>
                  </ul>
                  <p>
                    L’Utilisateur s’engage à prendre toutes les mesures
                    appropriées de façon à protéger ses données et matériels de la
                    contamination par des virus ou autres formes d’attaques
                    circulant éventuellement sur internet.
                  </p>
                  <p>
                    L’Utilisateur Patient est informé que des interventions
                    techniques sur la solution MEDEO s’effectuent dans le respect
                    des dispositions légales et notamment des dispositions du code
                    de la santé publique.
                  </p>
                  <p>
                    Les utilisateurs sont également alertés sur l’existence de
                    risques inhérents à l'utilisation d’internet et ce, y compris
                    en présence d'un accès sécurisé notamment en termes de :
                  </p>
                  <ul>
                    <li>continuité non garantie dans l'accès aux Services ;</li>
                    <li>défaut de fiabilité du réseau Internet ;</li>
                    <li>
                      performances non garanties, compte tenu notamment de la
                      propagation de virus ;
                    </li>
                    <li>
                      toute autre contrainte technique qui n’est pas sous le
                      contrôle et la responsabilité de MEDEO.
                    </li>
                  </ul>
                  <p>
                    MEDEO ne saurait ainsi être tenue responsable à l’égard des
                    utilisateurs de ces risques et de leurs conséquences
                    préjudiciables, quelle qu’en soit l’étendue.
                  </p>
                </div>
                <h4>Article 14. PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h4>
                <div>
                  <p>
                    MEDEO collecte et traite des données à caractère personnel au
                    titre de l’accès à la solution MEDEO, et au titre de la
                    fourniture des Services, sur la base du consentement explicite
                    de l’Utilisateur, conformément aux dispositions de la Loi
                    n°78-17 du 6 janvier 1978 relative à l’informatique, aux
                    fichiers et aux libertés modifiée (ci-après la « Loi
                    informatique et libertés ») et du Règlement (UE) 2016/679 du
                    Parlement européen et du Conseil du 27 avril 2016 relatif à la
                    protection des personnes physiques à l'égard du traitement des
                    données à caractère personnel et à la libre circulation de ces
                    données, et abrogeant la directive 95/46/CE (Règlement Général
                    sur la Protection des Données ci-après le « RGPD ») – ci-après
                    désignés ensemble « la Réglementation ».
                  </p>

                  <h5>
                    a. Données à caractère personnel fournies par les Utilisateurs
                  </h5>
                  <p>
                    L’accès aux Services repose sur la création préalable d’un
                    compte personnel sécurisé sur la solution MEDEO ainsi que sur
                    la création d’un profil médical.
                  </p>
                  <p>
                    Lors de la création du compte personnel, les Utilisateurs
                    transmettent certaines données à caractère personnel.
                  </p>
                  <p>
                    Lors de la création du profil médical, l’Utilisateur transmet
                    notamment des données de santé et des données médicales.
                  </p>
                  <p>
                    MEDEO collecte et traite sur la base du consentement explicite
                    de l’Utilisateur, dont le recueil est dématérialisé et tracé
                    dans les conditions décrites au sein des présentes CGU et
                    suivant les termes de la notice d’information et de
                    consentement :
                  </p>
                  <ul>
                    <li>
                      Des données à caractère personnel telles que ses nom,
                      prénom, date de naissance, sexe et adresse mail ;
                    </li>
                    <li>
                      Des données de santé et des données médicales, découlant des
                      échanges entre l’Utilisateur Professionnel de santé et
                      l’Utilisateur Patient (comptes rendus des entretiens
                      téléphoniques ou vidéos, diagnostics et prescriptions)
                      couvertes au surplus par le secret professionnel médical.
                    </li>
                  </ul>

                  <h5>
                    b. Responsable des traitements de données à caractère
                    personnel fournies par les Utilisateurs
                  </h5>
                  <p>
                    MEDEO est responsable des traitements de données à caractère
                    personnel mis en œuvre au titre de l’accès à la solution
                    MEDEO, et au titre de la fourniture des Services au sens de la
                    Réglementation.
                  </p>
                  <p>
                    MEDEO et les éventuels sous-traitants auxquels elle fait appel
                    appliquent des garanties suffisantes au titre de la mise en
                    œuvre de mesures techniques et organisationnelles appropriées,
                    notamment en termes de confidentialité et de sécurité.
                  </p>
                  <p>
                    Compte tenu de la sensibilité des données de santé à caractère
                    personnel concernant l’Utilisateur Patient, susceptibles
                    d’être collectées et traitées dans le cadre des Services
                    proposés par MEDEO, l’Utilisateur est informé qu’elles sont
                    hébergées chez AWS, qui fait partie des hébergeurs agréés par
                    le ministère de la santé, en vue de garantir la conservation,
                    l’archivage et la sécurité des données et assurer le respect
                    des exigences de confidentialité, de sécurité et de pérennité
                    de ces données.
                  </p>

                  <h5>
                    c. Finalité du traitement des données à caractère personnel
                    fournies par les Utilisateurs
                  </h5>
                  <p>
                    MEDEO collecte et traite les données à caractère personnel des
                    Utilisateurs et les données de santé de l’Utilisateur Patient,
                    pour les objectifs principaux suivants :
                  </p>
                  <p>
                    MEDEO collecte et traite sur la base du consentement explicite
                    de l’Utilisateur, dont le recueil est dématérialisé et tracé
                    dans les conditions décrites au sein des présentes CGU et
                    suivant les termes de la notice d’information et de
                    consentement :
                  </p>
                  <ul>
                    <li>
                      Pour la fourniture du service de pré-consultation : le
                      service de pré-consultation permet d’évaluer l’état de santé
                      des Patients et d’obtenir la délivrance d’informations
                      concernant les facteurs de risque ainsi que des
                      recommandations en dehors de la pose de tout diagnostic ou
                      encore de la prescription de traitement. Le service de pré-
                      consultation ne constitue pas un acte de télémédecine au
                      sens des dispositions des articles L.6316-1 et R.6316-1 1°
                      du Code de la santé publique.
                    </li>
                    <li>
                      Pour la fourniture du service de téléconsultation : le
                      service de téléconsultation est un acte de télémédecine au
                      sens des dispositions des articles L.6316-1 et R.6316-1 1°
                      du Code de la santé publique. Il permet aux patients de
                      bénéficier d’une consultation à distance avec un
                      Professionnel de santé.
                    </li>
                  </ul>
                  <p>
                    Les données à caractère personnel ainsi collectées sont
                    uniquement destinées aux Professionnels de santé participant
                    aux Services.
                  </p>
                  <p>
                    Les données à caractère personnel de l’Utilisateur peuvent
                    être utilisées par MEDEO pour lui envoyer des SMS, des
                    notifications et des emails d’information et de sollicitation
                    pour le seul compte de MEDEO. L’utilisateur pourra à tout
                    moment demander l’arrêt de ces envois par email à
                    contact@medeo-health.com. MEDEO décline toute responsabilité
                    en cas de non réception d’un SMS ou d’un email pour des
                    raisons indépendantes de sa volonté.
                  </p>
                  <p>
                    Les données de santé de l’Utilisateur pourront être exploitées
                    dans le cadre d’études ou d’évaluations dans le domaine de la
                    santé auxquelles participerait MEDEO, sauf opposition de sa
                    part au préalable, et après mise en œuvre de l’ensemble des
                    formalités requises auprès de la CNIL lorsqu’elles sont
                    applicables. Avant chaque étude ou évaluation mise en œuvre,
                    l’Utilisateur recevra une information préalable dans le
                    respect de la Réglementation.
                  </p>
                  <p>
                    Enfin, sous réserve de ne pas permettre l’identification
                    directe de l’Utilisateur, les données collectées dans le cadre
                    de l’utilisation de ces Services pourront être exploitées par
                    MEDEO dans l’objectif d’améliorer les performances et les
                    fonctionnalités de la solution MEDEO, et seront susceptibles
                    de faire l’objet d’analyses et d’études statistiques dans le
                    respect de votre anonymat.
                  </p>

                  <h5>d. Information de l’Utilisateur sur ses droits</h5>

                  <p>L’Utilisateur est préalablement informé :</p>
                  <ul>
                    <li>
                      Du caractère facultatif de la communication de ses données à
                      caractère personnel ;
                    </li>
                    <li>
                      De ce que le défaut de communication de ses données à
                      caractère personnel aura pour seule conséquence de ne pas
                      lui permettre l’inscription sur MEDEO ou l’utilisation de
                      l’intégralité des fonctionnalités des Services MEDEO ;
                    </li>
                    <li>
                      De ce que les données communiquées ont pour unique objet de
                      permettre le fonctionnement des Services MEDEO dans des
                      conditions de sécurité optimales.
                    </li>
                    <li>
                      Conformément à la Réglementation, l’Utilisateur dispose du
                      droit de demander l’accès, la rectification, ou l’effacement
                      des données à caractère personnel le concernant. Il dispose
                      également du droit de communiquer des directives concernant
                      le sort de ses données à caractère personnel après son
                      décès.
                    </li>
                  </ul>

                  <p>L’Utilisateur dispose en outre :</p>
                  <ul>
                    <li>
                      Du droit de s’opposer au traitement ou de retirer son
                      consentement à tout moment ;
                    </li>
                    <li>Du droit de solliciter une limitation du traitement ;</li>
                    <li>Du droit à la portabilité de ses données ;</li>
                    <li>Du droit à l’oubli et à l’effacement numérique ;</li>
                    <li>
                      Du droit d’introduire une réclamation auprès de la CNIL.
                    </li>
                  </ul>
                  <p>
                    Pour exercer ces droits, l’Utilisateur peut adresser sa
                    demande par mail à contact@medeo- health.com ou par courrier à
                    l’adresse MEDEO SAS, 16. Rue du Boulevard, 69100, VILLEURBANNE
                    en joignant une copie d’une pièce d’identité personnelle en
                    cours de validité. L’Utilisateur peut également exercer ses
                    droits auprès du l’hébergeur agréé santé (AWS)
                  </p>

                  <h5>e. Destinataires des données à caractère personnel</h5>
                  <p>
                    Les données à caractère personnel collectées auprès des
                    Utilisateurs sont uniquement destinées, avec leur consentement
                    préalable explicite, aux personnes suivantes et ne feront
                    l’objet d’aucune exploitation commerciale :
                  </p>
                  <ul>
                    <li>
                      Aux membres du personnel de MEDEO, spécifiquement habilités,
                      dans la limite de leurs attributions et notamment afin de
                      vérifier l’éligibilité aux Services des Utilisateurs ;
                    </li>
                    <li>
                      Aux Utilisateurs Professionnels de santé et paramédicaux
                      intervenant dans le respect des règles relatives au secret
                      médical et au partage des données de santé couvertes par le
                      secret médical ;
                    </li>
                    <li>
                      Au médecin traitant de l’Utilisateur Patient avec son accord
                      préalable et sur transmission du compte-rendu par ses soins
                      ;
                    </li>
                    <li>
                      Au pharmacien, dans le cas de la transmission d’une
                      prescription électronique le cas échéant ;
                    </li>
                    <li>
                      Aux personnels de l’hébergeur de données de santé
                      strictement habilités dans la limite de leurs attributions
                      respectives ;
                    </li>
                    <li>
                      Aux personnels strictement habilités des sous-traitants
                      techniques de MEDEO dans la limite de leurs attributions
                      respectives.
                    </li>
                  </ul>
                  <p>
                    Aucune de ces données n’est cédée ou communiquée à des tiers
                    non autorisés, sans l’accord préalable de l’Utilisateur.
                  </p>
                  <p>
                    La cession ou la mise à disposition de données personnelles ne
                    pourra s’opérer sans que l’Utilisateur n’ait été mis en mesure
                    de s’y opposer.
                  </p>

                  <h5>
                    f. Durée de conservation des données à caractère personnel
                  </h5>
                  <p>
                    Les données à caractère personnel des Utilisateurs sont
                    conservées pendant la durée de l’utilisation des Services.
                  </p>
                  <p>
                    Les données de santé et informations médicales figurant au
                    sein du dossier médical de l’Utilisateur Patient et incluant
                    notamment les données collectées lors de la complétude du
                    questionnaire santé et les données issues du compte-rendu de
                    téléconsultation et du dossier médical, font l’objet d’un
                    archivage intermédiaire à des fins probatoires pendant 20 ans,
                    à l’issue duquel elles seront supprimées, conformément aux
                    prescriptions du Conseil National de l’Ordre des Médecins.
                  </p>
                  <p>
                    Par ailleurs, si le compte personnel MEDEO que l’Utilisateur a
                    créé demeure inactif pendant 3 ans, il recevra un e-mail
                    l’informant de sa clôture et de la suppression de ses données
                    sous 30 jours, sauf s’il manifeste son souhait de conserver
                    son compte personnel.
                  </p>
                </div>
                <h4>Article 15. DISPONIBILITÉ DU SERVICE</h4>
                <div>
                  <p>
                    MEDEO s’engage à mettre en œuvre tous les moyens raisonnables
                    à sa disposition pour assurer un accès de qualité aux
                    Services, mais n’est tenue à aucune obligation de résultat d’y
                    parvenir, en particulier en cas de dysfonctionnement du
                    réseau, ou de tout autre évènement échappant à son contrôle.
                    En cas de force majeure, les obligations de MEDEO sont
                    suspendues dans les conditions de l’article « Force Majeure ».
                  </p>
                  <p>
                    MEDEO se réserve la possibilité d’interrompre, de suspendre,
                    ou de modifier sans préavis, l’accès à tout ou partie du
                    Service MEDEO, en vue d’en assurer la maintenance. Ces
                    interruptions auront lieu autant que possible pendant les
                    heures les moins préjudiciables à l’utilisation des Services
                    par l’Utilisateur.
                  </p>
                </div>
                <h4>
                  Article 16. MODIFICATIONS DES CONDITIONS GÉNÉRALES D’UTILISATION
                </h4>
                <div>
                  <p>
                    MEDEO se réserve la possibilité de modifier les CGU et
                    s’engage à en informer l’Utilisateur et à les lui faire
                    accepter avant son accès aux Services. En continuant à
                    utiliser la solution MEDEO, l’Utilisateur accepte les
                    modifications des présentes CGU.
                  </p>
                  <p>
                    Le refus des conditions d’utilisation ou de leurs
                    modifications ultérieures exclut l’utilisation de la solution
                    MEDEO.
                  </p>
                </div>
                <h4>Article 17. DIVERS</h4>
                <div>
                  <h5>a. Intégralité</h5>
                  <p>
                    Les CGU et le Devis conclu entre MEDEO et l’Utilisateur
                    Professionnel de santé constituent l’intégralité de l’accord
                    intervenu entre MEDEO et l’Utilisateur Professionnel de santé.
                  </p>
                  <p>
                    Les CGU ainsi que la notice d’information et consentement au
                    titre de la collecte et du traitement de données à caractère
                    personnel, constituent l’intégralité de l’accord intervenu
                    entre MEDEO et l’Utilisateur Patient.
                  </p>
                  <p>
                    Aucune condition générale ou spécifique figurant dans d’autres
                    documents ne pourra être considérée comme étant intégrée au
                    périmètre des droits et obligations existant entre MEDEO et
                    l’Utilisateur.
                  </p>

                  <h5>b. Force majeure</h5>
                  <p>
                    Aucune des Parties ne saurait être tenue responsable des
                    difficultés ou impossibilités momentanées d’exécution des
                    présentes qui auraient pour origine la force majeure.
                  </p>
                  <p>
                    Dans l’hypothèse d’un cas de force majeure faisant obstacle à
                    l’exécution de ses obligations par une Partie et se
                    poursuivant au-delà d’une durée d’un mois, les présentes CGU
                    pourront être résiliées par l’une ou l’autre des Parties, par
                    lettre recommandée avec accusé de réception, sans qu’aucune
                    des Parties n’ait à verser à l’autre une quelconque indemnité.
                  </p>

                  <h5>c. Nullité partielle</h5>
                  <p>
                    Si une ou plusieurs stipulations des présentes CGU venaient à
                    être nulle aux termes d’une disposition légale règlementaire
                    ou d’une décision judiciaire devenue définitive, elle serait
                    réputée non écrite et n’entrainerait pas la nullité des autres
                    stipulations.
                  </p>

                  <h5>d. Loi applicable et juridiction compétente.</h5>
                  <p>
                    Tout litige auquel l’utilisation de la solution MEDEO pourrait
                    donner lieu relèvera de la loi française et de la compétence
                    exclusive des tribunaux français.
                  </p>
                </div>
                Version du 20/12/2020.
              </div>

            </div>

            <div className="mt-8 flex justify-center">
              <SubmitButton>
                J'accepte les conditions générales d'utilisation
              </SubmitButton>
            </div>
          </form>

  );
};

export default CGU;
