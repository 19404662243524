import React from "react";
import OutlineToggleButton from "../../Shared/components/OutlineToggleButton";
import FormWrapper from "../../Shared/components/FormWrapper";
import SubmitButton from "../../Shared/components/SubmitButton";
import { MEDEO_MOTIVE_ITEM_CODE } from "../../Shared/codes";
import { getItemFromCode } from "../../Shared/Questionnaire/utils";

/**
 * Displays the motives extracted from the motive questionnaire
 *
 */
const Motive = ({ onClick, onSubmit, questionnaire, response }) => {
  if (questionnaire == null) return null;

  const motiveItem = getItemFromCode({
    questionnaire,
    code: MEDEO_MOTIVE_ITEM_CODE
  });

  const value = response?.answer?.valueCoding;

  const listofMotives = motiveItem.option.map(option => option.valueCoding);

  return (
    <FormWrapper>
      <div className="mt-8 text-4xl font-bold">{motiveItem.text}</div>
      <form id="motivesForm" onSubmit={onSubmit}>
        {
          <div className="flex flex-wrap mt-10 justify-center">
            {listofMotives.map(motive => (
              <OutlineToggleButton
                key={motive.code}
                onClick={() =>
                  onClick({
                    text: motiveItem.text,
                    linkId: motiveItem.linkId,
                    answer: { valueCoding: motive }
                  })
                }
                className="mb-2"
                checked={value?.code === motive.code}
              >
                {motive.display}
              </OutlineToggleButton>
            ))}
          </div>
        }
        {value != null && (
          <div className="m-12 flex justify-center">
            <SubmitButton>Suivant</SubmitButton>
          </div>
        )}
      </form>
    </FormWrapper>
  );
};

export default Motive;
