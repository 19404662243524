import React from "react";

function OutlineToggleButton({ className, children, onClick, checked }) {
  const toggledClassName = checked
    ? "text-white bg-blue-400"
    : "text-blue-400 bg-gray-200";
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${toggledClassName} ${className} mx-2 p-2 border-2 text-sm uppercase text-center font-bold rounded-md border-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
    >
      {children}
    </button>
  );
}

export default OutlineToggleButton;
