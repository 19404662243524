import React from "react";
import BasicButton from "./BasicButton";

function SubmitButton({ children, onClick, disabled, className }) {
  return (
    <BasicButton
      className={className + " bg-gray-200"}
      onClick={onClick}
      type="submit"
      disabled={disabled}
    >
      {children}
    </BasicButton>
  );
}

export default SubmitButton;
