import moment from "moment";

export const extractDataFromCardReader = data => {
  // The structure can be seen in cardpeek. Once converted the structure is :
  // beneficiary1 => VITALE2 (node?.[0]) > Application1 (node?.[1]) > HEALTH (node?.[2]) > content (node?.[1]) > Bénéficiaire (node?.[0])
  const beneficiary1 =
    data.cardpeek?.node?.[0]?.node?.[1]?.node?.[2]?.node?.[1]?.node?.[0]?.node;
  // beneficiary2 => VITALE2 (node?.[0]) > Application2 (node?.[2]) > PORTEUR (node?.[1]) > content (node?.[1]) > Bénéficiaire (node?.[0])
  const beneficiary2 =
    data.cardpeek?.node?.[0]?.node?.[2]?.node?.[1]?.node?.[1]?.node?.[0]?.node;

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  const nir = beneficiary1?.[1]?.["attr"]?.[5]?.["_"].slice(0, -2);
  const lastName = beneficiary2?.[0]?.["attr"]?.[5]?.["_"].toUpperCase();
  const firstName = capitalize(
    beneficiary2?.[1]?.["attr"]?.[5]?.["_"].toLowerCase()
  );
  const birthDate = moment(
    beneficiary2?.[2]?.["attr"]?.[5]?.["_"],
    "DD/MM/YYYY"
  ).format("YYYY-MM-DD");

  const gender = nir?.[0] === "1" ? "male" : "female";

  return { lastName, firstName, birthDate, gender, nir };
};

export const extractDataFromIpmCardReader = data => {
  // The data received from the IPM reader is of structure:
  // [{firstName: '', lastName: ''}, ...]

  // We only provide support for the first beneficiary for now
  const beneficiary = data[0];

  const { firstName, lastName } = beneficiary;

  const birthDate = beneficiary.birthdate;

  // The reader gets the nir as '{number} {key}', so we remove both the key and the space
  const nir = beneficiary.nir.slice(0, -3);

  const gender = nir[0] === "1" ? "male" : "female";

  return { birthDate, firstName, gender, lastName, nir };
};
