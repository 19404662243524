import { useCallback, useEffect, useReducer, useRef } from "react";
import { groupResources, createFhirClient } from "./utils";
import { isEqual } from "lodash";

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return initialState;
    case "fetching":
      return {
        ...state,
        error: undefined,
        loading: true
      };
    case "fetched":
      return {
        ...state,
        data: {
          ...groupResources(action.payload.data.entry),
          total: action.payload.data.total
        },
        error: undefined,
        loading: false
      };
    case "failed":
      return {
        ...state,
        data: undefined,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
const initialState = {
  error: undefined,
  loading: true,
  data: undefined
};
/**
 * this hook is a replacement for the search action and saga worker
 * the search action was the primary way to fetch data from the server
 * the search action trigger the searchFhirResourceWorker
 * - this worker fetch the data
 * - populate the redux store using an action called receiveFhirResource
 * In it's current state search action lacks some info and is cumbersome to use
 * This useSearch provide the same interface as the useQuery from @apollo/client
 * it provides error, data and loading the same way useQuery would
 *
 * @see https://www.apollographql.com/docs/react/api/react/hooks/#usequery
 *
 * @param type
 * @param query
 * @return {React.ReducerStateWithoutAction<function(*, *): ({data: any, error: any, loading: boolean})>}
 */
const useSearch = (type, query) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const run = useCallback(async () => {
    try {
      dispatch({ type: "fetching" });
      const fhirClient = await createFhirClient();
      const response = await fhirClient.search({ type, query });
      dispatch({ type: "fetched", payload: response });
    } catch (e) {
      dispatch({ type: "failed", payload: e });
    }
  }, [type, query, dispatch]);

  // starts with initialValue to null to run on first render
  const ref = useRef(null);
  useEffect(() => {
    // ref.current holds the last params used
    // params is an object and therefore change on every render
    // isEqual does a deep comparison
    // whereas === checks if they refer to the same object
    // it makes sure to call dispatch only when there is a  params change
    if (!isEqual(ref.current, query)) {
      run().then();

      ref.current = query;
    }
  }, [type, query, run]);

  return state;
};

export default useSearch;
