import React from "react";
import {
  displayPractitionerImg,
  displayPractitionerActiveRole
} from "../../Practitioner/utils";
import Spinner from "../../Shared/components/Spinner";
export const THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM =
  "http://medeo.io/fhir/Identifier/third-party-payer";
export const THIRD_PARTY_PAYER_NONE = "none";
export const THIRD_PARTY_PAYER_AM_PART = "am-part";
export const THIRD_PARTY_PAYER_FULL = "full";

const ThirdPartyInfo = ({ performer }) => {
  let value = performer.identifier?.find(
    identifier => identifier.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
  )?.value;
  switch (value) {
    case THIRD_PARTY_PAYER_FULL:
      value = "intégral";
      break;
    case THIRD_PARTY_PAYER_AM_PART:
      value = "part assurance maladie";
      break;
    case THIRD_PARTY_PAYER_NONE:
    default:
      value = "Aucun";
      break;
  }
  return <div>Tiers-payant: {value}</div>;
};
const PerformerInfo = ({ performer, practitionerRole }) => {
  if (performer == null) return <Spinner />;
  const [given] = performer?.name?.[0]?.given;
  const family = performer?.name?.[0]?.family;

  return (
    <div className="text-black w-full flex flex-row items-center p-4  border-b-2 mb-4">
      <aside className="mr-4">
        <div className="w-20 h-20 flex justify-center rounded-full border-2 border-solid border-white overflow-hidden">
          <img src={displayPractitionerImg(performer)} alt="" />
        </div>
      </aside>
      <main className="flex flex-col items-streth flex-grow">
        <div className="flex justify-between items-baseline mb-1 text-lg font-bold">
          <div>
            <span>{given}</span>
            &nbsp;
            <span className="uppercase">{family}</span>
          </div>
          <div className="text-xs text-gray-500">
            {performer.address?.[0]?.postalCode}
          </div>
        </div>
        <div className="flex justify-between items-baseline text-xs text-gray-500">
          <div>
            <div className="text-blue-600 text-sm">
              {displayPractitionerActiveRole([practitionerRole])}
            </div>
            <ThirdPartyInfo performer={performer} />
          </div>

          <div className="text-xs text-gray-50">
            {performer.address?.[0]?.city}
          </div>
        </div>
      </main>
    </div>
  );
};

export default PerformerInfo;
