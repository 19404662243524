import { Redirect, Router, useNavigate } from "@reach/router";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Header from "../../Shared/components/Header";
import BookingSelectSot from "../components/BookingSelectSlot";
import Consent from "../components/Consent";
import Motive from "../components/Motive";
import {
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
  MEDEO_APPOINTMENT_TYPE_TELECONSULTATION,
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
  MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_DU_TERRITOIRE
} from "../../Shared/codes";
import { SignInContext } from "../../App";
import { getOrganizationId } from "../../Auth/utils";
import { displayFullName, FHIR_DATE_FORMAT } from "../../Shared/utils";
import useSave from "../../Shared/hooks/useSave";
import useSearch from "../../Shared/hooks/useSearch";
import useLazySearch from "../../Shared/hooks/useLazySearch";

const BookingRouter = ({ patient, encounter, onFinish }) => {
  const [indexOfPage, setIndexOfPage] = useState(0);
  const navigate = useNavigate();
  const [authContext] = useContext(SignInContext);
  const organizationId = getOrganizationId(authContext);
  const [state, setState] = useState({
    motiveResponse: null,
    slot: null,
    consent: null,
    performer: null,
    performerOrganizationId: null,
    error: ""
  });
  const [save] = useSave();
  const [search] = useLazySearch();
  const { data } = useSearch("Location", {
    organization: organizationId,
    _include: "Location:organization"
  });
  const { data: motiveQuestionnaireData } = useSearch("Questionnaire", {
    status: "active",
    identifier: "motive-kiosk"
  });

  const onSave = async () => {
    const appointmentFullUrl = `urn:uuid:${uuidv4()}`;
    const orgData = await search("Organization", {
      _id: state.performerOrganizationId
    });
    const performerOrganization = orgData?.Organization?.[0];

    const procedureRequestEntry = {
      resource: {
        resourceType: "ProcedureRequest",
        status: "active",
        intent: "proposal",
        code: {
          coding: [
            {
              system: MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
              code: MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE
            }
          ]
        },
        context: { reference: `Encounter/${encounter.id}` },
        requester: {
          agent: {
            reference: `Organization/${organizationId}`,
            display: `${data?.Organization?.[0]?.name}`
          },
          onBehalfOf: { reference: `Organization/${organizationId}` }
        },
        performer: {
          reference: `Organization/${state.performerOrganizationId}`,
          display: performerOrganization?.name
        },
        subject: {
          reference: `Patient/${patient.id}`,
          display: displayFullName(patient)
        },
        authoredOn: new Date().toISOString()
      },
      request: {
        method: "POST",
        url: "ProcedureRequest"
      }
    };
    const updatedEncounterEntry = {
      resource: {
        ...encounter,
        status: "finished",
        period: {
          ...encounter?.period,
          end: new Date().toISOString()
        },
        appointment: { reference: appointmentFullUrl }
      },
      request: {
        method: "PUT",
        url: `Encounter/${encounter.id}`
      }
    };
    const appointmentEntry = {
      resource: {
        resourceType: "Appointment",
        identifier: [
          {
            system: MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM,
            value: MEDEO_APPOINTMENT_TYPE_TELECONSULTATION
          }
        ],
        status: "booked",
        slot: [
          {
            reference: `Slot/${state.slot.id}`
          }
        ],
        created: new Date().toISOString(),
        description: `${state.motiveResponse.answer.valueCoding.display} - sans examen clinique`,
        start: state.slot.start,
        end: state.slot.end,
        participant: [
          {
            actor: {
              reference: `Patient/${patient.id}`,
              display: displayFullName(patient)
            },
            type: [
              {
                coding: [
                  {
                    system:
                      "http://medeo.io/fhir/Identifier/appointment-actor-code",
                    code: "patient"
                  }
                ]
              }
            ],
            required: "required",
            status: "needs-action"
          },
          {
            actor: {
              reference: `Practitioner/${state.performer.id}`,
              display: displayFullName(state.performer)
            },
            type: [
              {
                coding: [
                  {
                    system:
                      "http://medeo.io/fhir/Identifier/appointment-actor-code",
                    code: "practitioner"
                  }
                ]
              }
            ],
            required: "required",
            status: "accepted"
          },
          {
            actor: {
              reference: `Location/${data.Location?.[0]?.id}`,
              display: data.Location?.[0]?.name
            },
            type: [
              {
                coding: [
                  {
                    system:
                      "http://medeo.io/fhir/Identifier/appointment-actor-code",
                    code: "location"
                  }
                ]
              }
            ],
            required: "required",
            status: "accepted"
          }
        ],
        requestedPeriod: [
          {
            start: state.slot.start,
            end: state.slot.end
          }
        ]
      },
      fullUrl: appointmentFullUrl,
      request: {
        method: "POST",
        url: "Appointment"
      }
    };
    const slotEntry = {
      resource: {
        ...state.slot,
        status: "busy"
      },
      request: {
        method: "PUT",
        url: `Slot/${state.slot.id}`
      }
    };
    const questionnaireResponseEntry = {
      resource: {
        resourceType: "QuestionnaireResponse",
        status: "completed",
        authored: moment().format(FHIR_DATE_FORMAT),
        context: { reference: `Encounter/${encounter.id}` },
        subject: {
          reference: `Patient/${patient.id}`,
          display: displayFullName(patient)
        },
        questionnaire: {
          reference: `Questionnaire/${motiveQuestionnaireData?.Questionnaire?.[0]?.id}`
        },
        item: [state.motiveResponse]
      },
      request: {
        method: "POST",
        url: "QuestionnaireResponse"
      }
    };

    const entries = [
      procedureRequestEntry,
      updatedEncounterEntry,
      appointmentEntry,
      slotEntry,
      questionnaireResponseEntry
    ];

    const organizationHasAuthorization = patient.generalPractitioner?.some(
      gp => gp.reference === `Organization/${state.performerOrganizationId}`
    );

    if (!organizationHasAuthorization) {
      const newGP = {
        reference: `Organization/${state.performerOrganizationId}`,
        identifier: {
          system: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM,
          value: MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_DU_TERRITOIRE
        }
      };
      entries.push({
        resource: {
          ...patient,
          generalPractitioner: patient?.generalPractitioner
            ? patient.generalPractitioner.concat(newGP)
            : [newGP]
        },
        request: {
          method: "PUT",
          url: `Patient/${patient.id}`
        }
      });
    }

    await save({
      resourceType: "Bundle",
      type: "transaction",
      entry: [...entries]
    });

    await onFinish();
    navigate("../end");
  };

  // next code is for navigation
  const steps = useMemo(
    () => [{ url: "motive" }, { url: "slot" }, { url: "consent" }],
    []
  );
  useEffect(() => {
    if (indexOfPage < steps.length && indexOfPage >= 0) {
      navigate(`./${steps[indexOfPage].url}`);
    }
  }, [indexOfPage, steps, navigate]);

  const onSubmit = useCallback(
    async e => {
      const formId = e.target.id;
      e.preventDefault();
      setIndexOfPage(indexOfPage + 1);
      switch (formId) {
        case "motivesForm":
          break;
        case "bookingSlot":
          const selectedSlot = state.slot;
          const slotData = await search("Slot", {
            _id: selectedSlot.id
          });
          const currentSlotStatus = slotData?.Slot?.[0]?.status;
          if (currentSlotStatus !== "free") {
            setState(s => ({
              ...s,
              error:
                "Malheureusement le créneau choisi vient d'être réservé, merci d'en choisir un autre"
            }));
          } else {
            await save({ ...state.slot, status: "busy-tentative" });
          }
          break;
        default:
          break;
      }
    },
    [save, search, indexOfPage, state, setState]
  );

  const onBackHeader = () => {
    if (indexOfPage > 0) {
      setIndexOfPage(indexOfPage - 1);
    } else {
      navigate("../");
    }
  };
  const handleChange = useCallback(
    ({ slot, practitioner, performerOrganizationId }) => {
      setState(s => ({
        ...s,
        slot,
        performer: practitioner,
        performerOrganizationId,
        error: ""
      }));
    },
    [setState]
  );

  return (
    <div>
      <Header
        cancelText="Vous êtes sur le point d'abondonner votre prise de rendez-vous. Les informations fournies ne seront pas enregistrées et votre rendez-vous ne sera pas réservé."
        headerText="Prise de rendez-vous"
        onCancel={() => navigate("../")}
        onBack={onBackHeader}
        widthOfProgressBar={indexOfPage * 33}
      />
      <Router>
        <Motive
          path="motive"
          onClick={value =>
            setState({
              ...state,
              motiveResponse: value
            })
          }
          onSubmit={onSubmit}
          questionnaire={motiveQuestionnaireData?.Questionnaire?.[0]}
          response={state.motiveResponse}
        />
        <BookingSelectSot
          path="slot"
          error={state.error}
          practitioner={state.performer}
          slot={state.slot}
          onChange={handleChange}
          onSubmit={onSubmit}
        />
        <Consent path="consent" onClick={onSave} />
        <Redirect from="/" to="./motive" noThrow />
      </Router>
    </div>
  );
};

export default BookingRouter;
