import React from "react";

const Spinner = () => {
  return (
      <div className="absolute w-full inset-0 flex justify-center items-center" style={{background: "rgba(0,0,0,0.75)"}}>
          <div className="bg-gray-200 p-8 rounded-lg">

    <div className="flex flex-col items-center justify-center">
      <svg
        className="animate-spin h-20 w-20 text-blue-400"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>

          </div>
      </div>
  );
};

export default Spinner;
