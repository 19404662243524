import React from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

// eslint-disable-next-line
/*global $_ipm*/

const Start = ({ onSubmit }) => {
  // const dir = "C:\\Program Files\\SKV2-Borne\\SKChrome-win";

  return (
    <Form
      id="start"
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div>
        <Hero>
          Avant de démarrer la téléconsultation, nous avons quelques questions
          médicales à vous poser.
        </Hero>
        <Help>
          Ces informations aideront les professionels de santé à mieux vous
          soigner.
        </Help>
      </div>
      <div className="flex justify-center">
        <SubmitButton formName="start">Suivant</SubmitButton>
      </div>
      <div />
    </Form>
  );
};

export default Start;
