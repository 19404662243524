/*global $_ipm*/
import React, { useContext } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeContext } from "../containers/StripeProvider";
import StripeInput from "./StripeInput";
import { useEffectOnce } from "react-use";

const NewDetailsForm = () => {
  const [stripeData, dispatch] = useContext(StripeContext);
  const handleStripeElementChange = e => {
    if (e.error) {
      dispatch({
        type: "error",
        payload: e.error.message
      });
    } else if (!e.error && e.complete) {
      dispatch({
        type: "card-complete"
      });
    } else {
      dispatch({
        type: "entering-card"
      });
    }
  };

  useEffectOnce(() => {
    if (typeof $_ipm !== "undefined") {
      $_ipm.taboskbd.showLayout("number");
    }
  });

  return (
    <div className="pt-10">
      <div>
        <span>Titulaire de la carte</span>
        <StripeInput
          id="name"
          name="name"
          type="text"
          required
          defaultValue={stripeData.customerName}
          onChange={e =>
            dispatch({
              type: "customer-name",
              payload: e.target.value
            })
          }
        />
      </div>
      <div>
        <span>Mail du propriétaire de la carte</span>
        <StripeInput
          id="email"
          name="email"
          type="text"
          defaultValue={stripeData.customerEmail}
          required
          onChange={e =>
            dispatch({
              type: "customer-email",
              payload: e.target.value
            })
          }
        />
      </div>
      <div>Détails de la carte bancaire</div>
      <CardElement
        options={{ hidePostalCode: true }}
        onChange={handleStripeElementChange}
        onClick={() => {
          if (typeof $_ipm !== "undefined") $_ipm.taboskbd.showLayout("number");
        }}
        onFocus={() => {
          if (typeof $_ipm !== "undefined") $_ipm.taboskbd.showLayout("number");
        }}
        className="bg-white mt-1 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
      />
    </div>
  );
};

export default NewDetailsForm;
