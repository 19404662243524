import React from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import Input from "../../Shared/components/Input";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

const Height = ({ value, onChange, onSubmit }) => {
  return (
    <Form id="height" onSubmit={onSubmit}>
      <div>
        <Hero>Quelle est votre taille ?</Hero>
        <Help>
          Cette information permettra au médecin d'adapter le dosage du
          traitement si nécessaire.
        </Help>
      </div>
      <div className="flex flex-row items-center justify-center">
        <Input
          id="height"
          name="height"
          type="number"
          className="w-1/4"
          defaultValue={value}
          onChange={onChange}
        />
        <div className="ml-2 mt-10">cm</div>
        <div className="ml-6 flex justify-center mt-10">
          <SubmitButton>Suivant</SubmitButton>
        </div>
      </div>
      <div className="h-16" />
    </Form>
  );
};

export default Height;
