import { API, Auth } from "aws-amplify";

/**
 * Creates a Stripe setupIntent object
 * returns a client_secret used to confirm the payment method
 * @returns {Promise<string>}
 */
export const setupIntent = async () => {
  // when we register a card payment we still don't have paymentMethod or customerId
  return await API.get("stripe", "/stripe/setup-intent", {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    }
  });
};

/**
 * Confirm card of user with the client secret, return a
 * confirmation object to get a paymentMethod
 * @param stripe
 * @param clientSecret
 * @param cardElement
 * @param cardHolderName
 * @param cardHolderEmail
 * @returns {Promise<{setupIntent?: SetupIntent, error?: StripeError}>}
 */
export const confirmCardSetup = async (
  stripe,
  clientSecret,
  cardElement,
  cardHolderName,
  cardHolderEmail
) => {
  return await stripe.confirmCardSetup(clientSecret, {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: cardHolderName,
        email: cardHolderEmail
      }
    }
  });
};

/**
 * This function is used to create a charge during the preconsultation
 * We create a customer in stripe platform and once we check the user card
 * we create a charge of 30 euros and to finish we return a chargeId
 * This charge Id will be save in a paymentNotice at the end of preconsultation
 * @param patientId
 * @param cardHolderName
 * @param cardHolderEmail
 * @param paymentMethodId
 * @returns {Promise<any>}
 */
export const handleCardSetup = async (
  patientId,
  cardHolderName,
  cardHolderEmail,
  paymentMethodId
) => {
  return await API.post("stripe", "/stripe/card-setup", {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: {
      patientId,
      cardHolderName,
      cardHolderEmail,
      paymentMethodId
    }
  });
};

/**
 * We create source with stripe JS like it's recommended by Stripe
 * Once the source is created we return it and we will attach it to
 * the customer with the createCharge lambda
 * @param stripe
 * @param stripeElement
 * @param cardHolderName
 * @param cardHolderEmail
 * @returns {Promise<*>}
 */
export const createSource = async (
  stripe,
  stripeElement,
  cardHolderName,
  cardHolderEmail
) => {
  const response = await stripe.createSource(stripeElement, {
    type: "card",
    currency: "eur",
    owner: {
      name: cardHolderName,
      email: cardHolderEmail
    }
  });
  return response.source;
};

/**
 * This lambda is use to create a charge for a customer
 * first we attach the source to the customer and once we
 * attached the source we create the charge. We return the
 * chargeId and we will save it in a PaymentNotice at the
 * end of the preconsultation form.
 * @param customerId
 * @param sourceId
 * @returns {Promise<any>}
 */
export const createCharge = async (
  customerId,
  sourceId,
  metadata,
  performerId
) => {
  try {
    return await API.post("stripe", "/stripe/create-charge", {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      },
      body: {
        customerId,
        sourceId,
        metadata,
        performerId
      }
    });
  } catch {
    return {
      error:
        "Votre carte bancaire ne dispose pas des fonds nécessaires, veuillez choisir une autre carte bancaire."
    };
  }
};

/**
 * This lambda is use to detach payment method of customer.
 * and delete it in stripe platform
 * @param customerId
 * @returns {Promise<any>}
 */
export const detachPaymentMethod = async customerId => {
  return await API.post("stripe", "/stripe/detach-payment-method", {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    },
    body: {
      customerId
    }
  });
};
