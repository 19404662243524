import { Auth } from "aws-amplify";
import { Authenticator } from "aws-amplify-react";
import React, { useContext } from "react";
import { SignInContext } from "../../App";
import CustomSignIn from "./CustomSignIn";

const RequireSignIn = ({ children }) => {
  const [signedInContext, setSignedInContext] = useContext(SignInContext);
  const isUserSignedIn = signedInContext != null;
  return isUserSignedIn ? (
    children
  ) : (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 ">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg max-w-md w-full space-y-8 p-4">
        <Authenticator
          hideDefault={true}
          onStateChange={authState => {
            if (authState === "signedIn") {
              Promise.all([
                Auth.currentSession(),
                Auth.currentAuthenticatedUser()
              ])
                .then(([session, user]) => {
                  let cognitoGroups = [];
                  if (
                    session &&
                    session.accessToken &&
                    session.accessToken.payload &&
                    session.accessToken.payload["cognito:groups"]
                  ) {
                    cognitoGroups =
                      session.accessToken.payload["cognito:groups"];
                  }
                  setSignedInContext({
                    token: session.accessToken.getJwtToken(),
                    attributes: user.attributes,
                    cognitoGroups
                  });
                })
                .catch(err => console.warn(err));
            }
          }}
        >
          <CustomSignIn />
        </Authenticator>
        <div className="flex items-center justify-center mt-4">
          <div className="text-sm">
            <a
              href="/"
              className="font-medium text-blue-400 hover:text-blue-500"
            >
              Mot de passe oublié ?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequireSignIn;
