import React from "react";
import QuestionnaireChoice from "./QuestionnaireChoice";
import QuestionnaireGroup from "./QuestionnaireGroup";
import QuestionnaireString from "./QuestionnaireString";
import QuestionnaireBoolean from "./QuestionnaireBoolean";

const QuestionnaireItem = ({ item, onChange }) => {
  switch (item.type) {
    case "group":
      return <QuestionnaireGroup item={item} onChange={onChange} />;
    case "string":
      return <QuestionnaireString item={item} onChange={onChange} />;
    case "choice":
      return <QuestionnaireChoice item={item} onChange={onChange} />;
    case "boolean":
      return <QuestionnaireBoolean item={item} onChange={onChange} />;
    default:
      break;
  }
};

export default QuestionnaireItem;
