import React from "react";
import Hero from "../../Shared/components/Hero";
import image from "../../Images/Card@1x.png";
import useCardReader from "../../CreatePatient/hooks/useCardReader";
import OutlineToggleButton from "../../Shared/components/OutlineToggleButton";

/**
 * Use a hook which will handle properly both the situation if we are on IPM or not.
 * It will return a cardStatus to know whether the card is valid or not (and
 * processing for cardpeek as it takes longer), and a retry callback to call
 * when the reading was unsuccessfull.
 *
 * @param {Function} onCardInsertion: callback for when a card was detected
 */
const VitaleCardReader = ({ onCardInsertion = () => {} }) => {
  const { cardStatus, retry } = useCardReader(onCardInsertion);

  return (
    <div>
      <Hero className="mb-4">
        Merci d'insérer votre carte Vitale dans le lecteur
      </Hero>
      {cardStatus == null && (
        <div>
          <div className="text-xl mt-6 text-center font-bold">
            Si vous avez une carte vitale, insérez-la dans le lecteur. Sinon,
            cliquez sur le bouton "Pas de carte vitale" ci-dessous.
          </div>
          <div className="flex justify-center m-4">
            <img style={{ height: "150px" }} src={image} alt="" />
          </div>
        </div>
      )}
      {cardStatus === "processing" && (
        <div>
          <div className="flex justify-center m-4">
            <img style={{ height: "150px" }} src={image} alt="" />
          </div>
          <div className="text-xl mt-6 text-center font-bold">
            Lecture de votre carte vitale en cours...
          </div>
        </div>
      )}
      {cardStatus === "notValid" && (
        <div>
          <div className="flex justify-center m-4">
            <img style={{ height: "150px" }} src={image} alt="" />
          </div>
          <div className="text-xl mt-6 text-center font-bold">
            Carte invalide.
          </div>
          <div className="flex justify-center mt-10">
            <OutlineToggleButton onClick={retry}>Réessayer</OutlineToggleButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default VitaleCardReader;
