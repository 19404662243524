import React from "react";
import BasicToggleButton from "../../Shared/components/BasicToggleButton";
import SubmitButton from "../../Shared/components/SubmitButton";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";

/**
 * List of the allergies which can be selected.
 * {
 *  code,
 *  text: text as going to be displayed in Medeo and used for the "text" attribute in
 *  the Fhir resource
 *  display: text to be displayed on the button.
 * }
 */
const allergyList = [
  {
    code: "pollen",
    text: "Pollen",
    display: "Allergie au pollen"
  },
  {
    code: "medecine",
    text: "Médicamenteuse",
    display: "Allergie médicamenteuse"
  },
  {
    code: "other",
    text: "Autre",
    display: "Autre"
  }
];

const Allergies = ({ onClick, onSubmit, value }) => {
  return (
    <Form id="allergies" onSubmit={onSubmit}>
      <Hero>Lesquelles ?</Hero>
      <div className="flex flex-row justify-center">
        {allergyList.map((allergy, i) => (
          <BasicToggleButton
            key={i}
            onClick={() => onClick(allergy)}
            checked={value.findIndex(v => v.code === allergy.code) !== -1}
          >
            {allergy.display}
          </BasicToggleButton>
        ))}
      </div>
      <div className="m-20 flex justify-center">
        <SubmitButton disable={value?.length > 0}>Suivant</SubmitButton>
      </div>
      <div />
    </Form>
  );
};

export default Allergies;
