import React, { useContext } from "react";
import { StripeContext } from "../containers/StripeProvider";

const ReusableDetails = () => {
  const [stripeData] = useContext(StripeContext);
  const ownerName = stripeData.customerName;

  const expirationDate =
    stripeData.source?.card?.exp_month +
    "/" +
    stripeData.source?.card?.exp_year;
  const paymentString =
    " \u2022 \u2022 \u2022 \u2022 -".repeat(3) +
    ` ${stripeData.source?.card?.last4}`;

  return (
    <div className="pt-16">
      <div>
        <span>Titulaire de la carte</span>
        <input
          id="name"
          name="name"
          type="text"
          value={ownerName}
          readOnly
          disabled
          className="mt-1 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        />
      </div>
      <div className="flex flex-row justify-between w-full mt-3">
        <div className="w-full mr-3">
          <span>Numéro de carte</span>
          <input
            id="card-number"
            name="card-number"
            type="text"
            readOnly
            disabled
            value={paymentString}
            className="mt-1 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          />
        </div>
        <div>
          <span>Date d'expiration</span>
          <input
            id="expiration-date"
            name="expiration-date"
            type="text"
            readOnly
            disabled
            value={expirationDate}
            className="mt-1 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default ReusableDetails;
