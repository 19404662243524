export const getIdByReference = reference => {
  return reference?.split("/")[1];
};

export const usualName = entity => {
  if (entity == null) return null;
  if (entity && entity.name && entity.name.length === 1) return entity.name[0];
  return (entity.name || []).find(name => name.use === "usual") || null;
};

export const displayGivenName = entity => {
  const usual = usualName(entity);

  if (usual == null || usual.given == null) {
    return null;
  }

  return usual.given[0];
};

export const displayFamilyName = entity => {
  const usual = usualName(entity);

  if (
    usual == null ||
    usual.family == null ||
    typeof usual.family != "string"
  ) {
    return null;
  }

  return usual.family.toUpperCase();
};

export const displayFullName = entity =>
  displayGivenName(entity) + " " + displayFamilyName(entity);

export const FHIR_DATE_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";
