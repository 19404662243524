import React, { useState } from "react";

const Consent = ({ onClick }) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = () => {
    setClicked(true);
    onClick();
  };
  return (
    <div>
      <div className="flex flex-col items-center justify-center mt-10">
        <div className="max-w-4xl space-y-2">
          <div className="text-4xl font-bold">
            J’accepte que mes informations soient diffusées auprès des médecins
            téléconsultants
          </div>
          <div className="text-xs">
            Ces informations seront utilisées par le praticien afin de mieux
            préparer la téléconsultation.
          </div>
          <div className="text-xs">Cela ne prendra que quelques minutes.</div>
          <div className="m-20 flex justify-center">
            {/* we have to write two buttons here, as disabled attribute doesn't work with tailwind */}
            {!clicked && (
              <button
                onClick={handleClick}
                className="uppercase mt-20 group relative w-1/4 py-2 px-4 border border-transparent text-sm text-center font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                je donne mon consentement
              </button>
            )}
            {clicked && (
              <button className="uppercase mt-20 group relative w-1/4 py-4 px-4 border border-transparent text-sm text-center font-medium rounded-md cursor-not-allowed text-white bg-gray-400">
                Veuillez patientez...
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consent;
