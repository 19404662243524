import React, { useCallback, useReducer } from "react";
import { useUpdateEffect } from "react-use";
import Column from "../components/Column";

import { reduceSlotsOverPeriod } from "../utils";

/**
 * @typedef {object} SlotsViewState
 * @property {number} daysFromNow - elapsed days from now for the first displayed day in the view
 */
const init = ({ slots, period, limit = 4, slot = null }) => {
  const byDay = reduceSlotsOverPeriod(period, slots);
  const shouldDisplayMore =
    limit != null && Object.values(byDay).some(s => s.length > limit);

  return {
    limit: Object.values(byDay).some(slots =>
      slots.find((s, i) => s?.id === slot?.id && i >= limit)
    )
      ? null
      : limit,
    shouldDisplayMore,
    slots,
    period,
    // byDay acts as a cursor over the slots
    // it contains only the slots from the given period
    byDay
  };
};

/**
 *
 * @param {SlotsViewState} state
 * @param action
 * @return {SlotsViewState} nextState
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "show":
      return { ...state, shouldDisplayMore: false, limit: null };
    case "change":
      // when changing the view, we don't update the slots
      // we compute the byDay state to show the correct cursor
      return init({ slots: state.slots, period: action.payload });
    case "reset":
      // when resetting we keep the period the same
      // user should stay on the same view when slots are updated
      return init({
        slots: action.payload.slots,
        slot: action.payload.slot,
        period: state.period,
        limit: state.limit
      });
    default:
      return state;
  }
};

/**
 * This component always shows three days, with for each its corresponding
 * available schedule.
 * It is possible to navigate from today to the three days tuple containing the last
 * set schedule of the perfomer
 * @param {string} performerId
 */
const SlotsView = ({ slots, period, children, slot, onShowMore }) => {
  const [state, dispatch] = useReducer(reducer, { slots, period, slot }, init);
  // current selected/created slot from FHIR is now in the store
  useUpdateEffect(() => {
    dispatch({ type: "reset", payload: { slots, slot } });
  }, [slots]);

  useUpdateEffect(() => {
    dispatch({
      type: "change",
      payload: period
    });
  }, [period]);

  const handleShowMore = useCallback(() => {
    onShowMore();
    dispatch({ type: "show" });
  }, [dispatch, onShowMore]);

  return (
    <div className="w-full block">
      <div className="flex w-full">
        {Object.entries(state.byDay).map(([day, slots], i) => (
          <Column day={day} limit={state.limit} key={i} slots={slots}>
            {children}
          </Column>
        ))}
      </div>
      {state.shouldDisplayMore && (
        <button
          className="uppercase text text-blue-400 text-sm block"
          type="button"
          onClick={handleShowMore}
        >
          Plus de créneaux
        </button>
      )}
    </div>
  );
};

export default React.memo(SlotsView);
