import { MEDEO_ITEM_VALUE_SET_SYSTEM } from "../codes";

/**
 * this method transforms the nested structure of the questionnaireResponse
 * item and returns the items in a list, that could be used with standard
 * array method or evaluate
 * @param questionnaireResponse
 * @returns {any[]}
 */
export const flattenItems = questionnaireResponse => {
  if (questionnaireResponse == null || questionnaireResponse.item == null)
    return [];
  const flattenRecursively = item => {
    if (item.item == null) return item;
    return [item, item.item.map(flattenRecursively).flat()].flat();
  };

  return questionnaireResponse.item.map(flattenRecursively).flat();
};

/**
 * Get item in a questionnaire depending on its code
 *
 * @param {Questionnaire} questionnaire - questionnaire where to look for the linkId
 * @param {String} code - code of the item we are looking for
 */
export const getItemFromCode = ({
  questionnaire,
  code,
  system = MEDEO_ITEM_VALUE_SET_SYSTEM
}) => {

  const item = flattenItems(questionnaire).find(i => {
    return i.code?.find(c => c.system === system && c.code === code);
  });

  return item;
};

/**

 * Get item in a QuestionnaireResponse depending on its linkId
 *
 * @param {QuestionnaireResponse} response - response where to look for the linkId
 * @param {String} linkId - linkId of the item we are looking for
 */
export const getItemFromLinkId = ({ response, linkId }) => {
  const item = flattenItems(response).find(i => {
    return i.linkId === linkId;
  });

  return item;
};

/**
 * Get the answer given for an item of a specific code
 *
 * @param {String} code - the code on the Questionnaire item
 * @param {Object} questionnaire - FHIR Questionnaire resource the response replies to
 * @param {Object} questionnaireResponse - FHIR QuestionnaireResponse resource
 */
export const getAnswerForCode = ({
  code,
  questionnaire,
  questionnaireResponse
}) => {
  const linkId = getItemFromCode({ questionnaire, code })?.linkId;


  const answer = getItemFromLinkId({ response: questionnaireResponse, linkId })
    ?.answer;

  return answer;
};
