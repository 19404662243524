import React, { useState, useEffect } from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import { displayPractitionerImg } from "../../Practitioner/utils";
import BasicToggleButton from "../../Shared/components/BasicToggleButton";
import { MEDEO_RPPS_IDENTIFIER_SYSTEM } from "../../Shared/codes";
import { queryPractitionerFromES } from "../utils";
import Input from "../../Shared/components/Input";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* global $_ipm */

const Field = props => {
  return (
    <div className="w-1/2 pb-2 pt-2 pl-2 pr-2">
      <div className="text-gray-500">{props.label}</div>
      <div>
        {props.defaultValue != null &&
        props.defaultValue !== "" &&
        props.defaultValue !== " " &&
        props.defaultValue !== "  "
          ? props.defaultValue
          : "-"}
      </div>
    </div>
  );
};

const getPostalCodeAndCity = string => {
  const groups = string.match(/(\d{5}) (\w*)/);
  return { postalCode: groups?.[1], city: groups?.[2] };
};

const GeneralPractitioner = ({ value, onSelect, onSubmit }) => {
  const [generalPractitioner, setGeneralPractitioner] = useState(value);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (search !== "")
      queryPractitionerFromES(search).then(datalist => {
        setResults(datalist);
      });
  }, [search]);

  useEffect(() => {
    onSelect(generalPractitioner);
  }, [generalPractitioner, onSelect]);

  return (
    <Form id="general-practitioner" onSubmit={onSubmit}>
      <Hero>Quel est le nom de votre médecin traitant ?</Hero>

      {generalPractitioner == null ? (
        <div className="ml-32 mr-32">
          <div className="relative">
            <Input
              type="text"
              onChange={e => setSearch(e.target.value)}
              className="pl-10"
              placeholder="Rechercher un médecin..."
              defaultValue={search}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="text-blue-400 absolute z-10 ml-3 left-0 bottom-0 mb-3"
            />
          </div>
          <div
            className="pb-2 mt-2"
            style={
              search !== "" ? { maxHeight: "14rem", overflowY: "scroll" } : null
            }
          >
            {search !== "" &&
              results.map((result, index) => (
                <div
                  className="bg-white shadow-md p-2 mt-3 mb-3 flex flex-row justify-between cursor-pointer"
                  key={index}
                  onClick={() => {
                    if (typeof $_ipm !== "undefined") {
                      $_ipm.taboskbd.hide();
                    }
                    if (result != null) {
                      const postalCode = getPostalCodeAndCity(result.postalCode)
                        .postalCode;
                      const city = getPostalCodeAndCity(result.postalCode).city;

                      setGeneralPractitioner({
                        resourceType: "Practitioner",
                        identifier: [
                          result.rpps
                            ? {
                                type: {
                                  coding: [
                                    {
                                      code: "RPPS",
                                      display: "N° RPPS"
                                    }
                                  ]
                                },
                                system:
                                  "http://medeo.io/fhir/Identifier/numero-rpps",
                                value: result.rpps
                              }
                            : null,
                          result.finess_code
                            ? {
                                type: {
                                  coding: [
                                    {
                                      system:
                                        "http://finess.sante.gouv.fr/fhir/Identifier/finessEt",
                                      code: "FINESS",
                                      display: "FINESS"
                                    }
                                  ]
                                },
                                system:
                                  "http://medeo.io/fhir/Identifier/practitioner-finess",
                                value: result.finess_code
                              }
                            : null
                        ],
                        name: [
                          {
                            use: "usual",
                            family: result.lastName ? result.lastName : null,
                            given: [result.firstName ? result.firstName : null]
                          }
                        ],
                        telecom: result.email
                          ? [
                              {
                                system: "email",
                                value: result.email,
                                use: "work"
                              }
                            ]
                          : null,
                        address: [
                          {
                            line: result.address ? [result.address] : null,
                            city: city,
                            postalCode: postalCode,
                            country: "France"
                          }
                        ]
                      });
                    }
                  }}
                >
                  <div className="flex flex-row">
                    <div className="mr-4 m-auto">
                      <img
                        className="w-10 rounded-full"
                        alt={`${result.firstName} ${result.lastName}`.toLowerCase()}
                        src={displayPractitionerImg()}
                      />
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="text-lg capitalize">
                        <span>{result.firstName.toLowerCase()} </span>
                        <span>{result.lastName.toUpperCase()} </span>
                      </div>
                      <div>
                        <span className="text-blue-400">
                          {result.name_trade}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: "3.5rem" }}
                    className="flex flex-col justify-center items-end text-gray-500"
                  >
                    <div>
                      {getPostalCodeAndCity(result.postalCode).postalCode}
                    </div>
                    <div>{getPostalCodeAndCity(result.postalCode).city}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="bg-white p-2 w-full mt-6">
          <div>
            <Field
              label="Identifiant Rpps"
              defaultValue={
                generalPractitioner.identifier?.find(
                  s => s.system === MEDEO_RPPS_IDENTIFIER_SYSTEM
                )?.value
              }
            />
          </div>
          <div className="flex">
            <Field
              label="Nom"
              defaultValue={generalPractitioner.name?.[0]?.family}
            />
            <Field
              label="Prénom"
              defaultValue={generalPractitioner.name?.[0]?.given?.[0]}
            />
          </div>
          <div className="flex">
            <Field
              label="Mail MSanté"
              defaultValue={
                generalPractitioner.telecom?.find(s => s.system === "email")
                  ?.value
              }
            />
            <Field
              label="N° téléphone"
              defaultValue={
                generalPractitioner.telecom?.find(s => s.system === "phone")
                  ?.value
              }
            />
          </div>
          <div>
            <Field
              label="Adresse"
              defaultValue={generalPractitioner.address?.[0]?.line?.[0]}
            />
          </div>
          <div className="flex">
            <Field
              label="Code postal"
              defaultValue={generalPractitioner.address?.[0]?.postalCode}
            />
            <Field
              label="Ville"
              defaultValue={generalPractitioner.address?.[0]?.city}
            />
          </div>
          <div>
            <Field
              label="Pays"
              defaultValue={generalPractitioner.address?.[0]?.country}
            />
          </div>
        </div>
      )}
      <div className="m-6 flex justify-center">
        {generalPractitioner != null ? (
          <BasicToggleButton
            className="mr-6"
            color="aqua"
            variant="outline"
            onClick={() => setGeneralPractitioner(null)}
          >
            Changer de médecin traitant
          </BasicToggleButton>
        ) : (
          <SubmitButton className="mr-6">Pas de médecin traitant</SubmitButton>
        )}
        <SubmitButton disabled={generalPractitioner === null}>
          Suivant
        </SubmitButton>
      </div>
      {/* This component is here to prevent no display if the user has uncheck the checkbox
      for no general practitioner and doesn't indicate a generalPractitioner. This div will be
      in recap at the end of add patient form */}
    </Form>
  );
};

export default GeneralPractitioner;
