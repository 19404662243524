import "./App.css";
import React, { createContext, useState, useContext, useEffect } from "react";
import { Router } from "@reach/router";
import moment from "moment";
import Footer from "./Shared/components/Footer";
import Lobby from "./Shared/containers/Lobby";
import RequireSignIn from "./Auth/containers/RequireSignIn";
import CreatePatient from "./CreatePatient/containers/CreatePatient";
import Auth from "./Auth/containers/Auth";
import Dashboard from "./Dashboard/components/Dashboard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffectOnce } from "react-use";
import { installMomentLocales } from "./locale";
import useSearch from "./Shared/hooks/useSearch";
import useNotifyOnCardRead from "./CreatePatient/hooks/useNotifyOnCardRead";

export const SignInContext = createContext([]);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const SignedRoutes = () => {
  const [signIn, setSignIn] = useContext(SignInContext);
  const { data } = useSearch("Organization", {
    _id: signIn.attributes["custom:orgId"]
  });

  useEffect(() => {
    if (
      !signIn.organization &&
      data &&
      data.Organization &&
      data.Organization[0]
    ) {
      setSignIn({ ...signIn, organization: data.Organization[0] });
    }
  });

  return (
    <div className="h-full flex flex-col">
      <Router className="flex flex-1 flex-col router">
        <Lobby path="/" />
        <Auth path="auth/*" />
        <CreatePatient path="create/*" />
        <Dashboard path="dashboard/:patientId/*" />
      </Router>
      <Footer />
    </div>
  );
};

function App() {
  const [signIn, setSignIn] = useState(null);
  const notif = useNotifyOnCardRead();

  const language = "fr";
  useEffectOnce(() => {
    installMomentLocales();
    moment.locale(language);
  });

  return (
    <Elements stripe={stripePromise}>
      <div className="bg-gray-200 h-screen flex flex-col">
        <SignInContext.Provider value={[signIn, setSignIn]}>
          <Router className="flex-1 flex flex-col router" primary={false}>
            <RequireSignIn path="/">
              <SignedRoutes path="/*" />
            </RequireSignIn>
          </Router>
        </SignInContext.Provider>
        {notif && (
          <div className="text-white px-6 py-4 border-0 rounded absolute right-0 bottom-0 mb-4 bg-green-500">
            <span className="inline-block align-middle mr-8">{notif}</span>
          </div>
        )}
      </div>
    </Elements>
  );
}

export default App;
