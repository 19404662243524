import React, { useCallback } from "react";
import { useNavigate } from "@reach/router";
import VitaleCardReader from "../components/VitaleCardReader";
import useLazySearch from "../../Shared/hooks/useLazySearch";
import { MEDEO_NIR_IDENTIFIER_SYSTEM } from "../../Shared/codes";
import Header from "../../Shared/components/Header";
import BasicButton from "../../Shared/components/BasicButton";

const CardAuthentication = () => {
  const navigate = useNavigate();
  const [search] = useLazySearch();

  const onNoCard = () => {
    navigate("auth/manual");
  };

  const onCardInsertion = useCallback(
    async cardData => {
      // Check if a patient with the read nir already exists
      // we use start with in the filter so that if the patient nir was
      // saved with the key, it would still be found
      const data = await search("Patient", {
        _filter: `(identifier sw ${MEDEO_NIR_IDENTIFIER_SYSTEM}|${cardData.nir})`,
        _sort: "-_id"
      });
      // We also need this patient to have been created on the borne
      // so that they have a code pin. If they don't, then we create a new
      // one again.
      const patient = data?.Patient?.[0];

      if (patient != null) navigate(`/dashboard/${patient.id}`);
      else navigate("create/0", { state: { cardData } });

      // dispatch notification
      var event;
      if (typeof Event === "function") {
        // for chrome and other browsers
        event = new Event("cardRead");
      } else {
        // for IE
        event = document.createEvent("Event");
        event.initEvent("cardRead", true, true);
      }
      window.dispatchEvent(event);
    },
    [navigate, search]
  );

  const handleCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div>
      <Header onCancel={handleCancel} onBack={handleCancel} />
      {/* <div className="m-4 flex-1 flex h-full"> */}
      <div className="m-4 flex flex-1 flex-col items-stretch justify-between">
        <VitaleCardReader onCardInsertion={onCardInsertion} />
        <div className="flex justify-center">
          <BasicButton onClick={onNoCard}>Pas de carte vitale</BasicButton>
        </div>
      </div>
    </div>
  );
};

export default CardAuthentication;
