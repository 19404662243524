import React from "react";
import BasicButton from "../../Shared/components/BasicButton";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

const Informations = ({ cardData, onCancel, onNext }) => {
  const firstName = cardData?.firstName ?? "";
  const lastName = cardData?.lastName ?? "";

  return (
    <div className="flex flex-col items-stretch justify-between w-full">
      {cardData != null ? (
        <div>
          <Hero className="mb-10">
            Nous n'avons pas trouvé de compte relié à votre carte vitale.
          </Hero>
          <Help className="mb-4">
            Nous avons besoin de quelques informations supplémentaires afin de
            créer un compte au nom de :
          </Help>
          <Help className="flex justify-center">
            {lastName} {firstName}
          </Help>
          <Help className="mt-4">Cela ne prendra que quelques minutes.</Help>
        </div>
      ) : (
        <div>
          <Hero className="mb-10">
            Nous avons besoin de quelques informations afin de créer votre
            compte.
          </Hero>
          <Help className="mb-4">
            Elles seront uniquement utilisées pour communiquer avec les
            professionnels de santé.
          </Help>
          <Help className="mt-4">Cela ne prendra que quelques minutes.</Help>
        </div>
      )}
      <div className="flex justify-center">
        <BasicButton onClick={onNext}>Créer mon compte</BasicButton>
      </div>
    </div>
  );
};

export default Informations;
