import moment from "moment";
import { FHIR_DATE_FORMAT, getIdByReference } from "../Shared/utils";
import {
  MEDEO_ENCOUNTER_ORIGIN_KIOSK,
  MEDEO_ENCOUNTER_ORIGIN_SYSTEM,
  MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION,
  MEDEO_ENCOUNTER_TYPE_SYSTEM
} from "../Shared/codes";

/**
 * Compute the entries which will be saved,
 * except for the ones which need to refer to data
 * not fetched yet (RequestGroup and QuestionnaireResponse)
 *
 * @param {String} encounterFullUrl
 * @param {Object} patient
 * @param {String} procedureRequestFullUrl
 * @param {Object} procedureRequest
 */
export const computeInitialState = ({
  encounterFullUrl,
  patient,
  procedureRequestFullUrl,
  procedureRequest
}) => {
  return {
    encounter: {
      fullUrl: encounterFullUrl,
      resource: {
        resourceType: "Encounter",
        status: "finished",
        type: [
          {
            coding: [
              {
                system: MEDEO_ENCOUNTER_TYPE_SYSTEM,
                code: MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION
              }
            ]
          },
          {
            coding: [
              {
                system: MEDEO_ENCOUNTER_ORIGIN_SYSTEM,
                code: MEDEO_ENCOUNTER_ORIGIN_KIOSK
              }
            ]
          }
        ],
        subject: {
          reference: `Patient/${patient.id}`
        },
        period: {
          start: moment().format(FHIR_DATE_FORMAT),
          end: moment().format(FHIR_DATE_FORMAT)
        }
      },
      request: { method: "POST", url: "Encounter" }
    },
    requestGroup: {
      resource: {},
      request: { method: "POST", url: "RequestGroup" }
    },
    teleconsultationProcedureRequest: {
      fullUrl: procedureRequestFullUrl,
      resource: {
        resourceType: "ProcedureRequest",
        status: "active",
        intent: "proposal",
        code: {
          coding: [
            {
              system: "http://medeo.io/fhir/ValueSet/procedure-code",
              code: "teleconsultation"
            }
          ]
        },
        subject: {
          reference: `Patient/${patient.id}`
        },
        performer: {
          reference: `Organization/${getIdByReference(
            procedureRequest.performer.reference
          )}`
        },
        context: {
          reference: encounterFullUrl
        }
      },
      request: { method: "POST", url: "ProcedureRequest" }
    },
    paymentNotice: {
      resource: {
        resourceType: "PaymentNotice",
        status: "active",
        request: { reference: procedureRequestFullUrl },
        created: new Date().toISOString(),
        organization: {
          reference: `Organization/${getIdByReference(
            procedureRequest.performer.reference
          )}`
        },
        paymentStatus: {
          coding: [
            {
              system: "http://medeo.io/fhir/PaymentNotice/payment-status",
              code: "authorized"
            },
            {
              system: "http://medeo.io/fhir/PaymentNotice/payment-method",
              code: "card"
            },
            {
              system: "http://medeo.io/fhir/PaymentNotice/payment-amount",
              code: 30
            },
            {
              system: "http://medeo.io/fhir/PaymentNotice/charge-id",
              code: null
            }
          ]
        }
      },
      request: { method: "POST", url: "PaymentNotice" }
    },
    preconsultationProcedureRequest: {
      resource: { ...procedureRequest, status: "completed" },
      request: { method: "PUT", url: `ProcedureRequest/${procedureRequest.id}` }
    },
    symptomsQuestionnaireResponse: {
      resource: {},
      request: { method: "POST", url: "QuestionnaireResponse" }
    },
    paymentMethodResponse: {
      resource: {},
      request: { method: "POST", url: "QuestionnaireResponse" }
    }
  };
};

/**
 * Initiate the RequestGroup resource
 *
 * @param {String} encounterRef
 * @param {Number} patientId
 * @param {String} questionnaireCode - the code of the motive questionnaire which is going to be used
 */
export const initRequestGroupResource = ({
  encounterRef,
  patientId,
  questionnaireCode
}) => ({
  resourceType: "RequestGroup",
  definition: [
    {
      reference: `PlanDefinition/${
        process.env.REACT_APP_FHIR_BASE_URL ===
        "https://develop.fhir.medeo.io/fhir"
          ? 105117
          : 29086
      }`
    }
  ],
  status: "completed",
  subject: {
    reference: `Patient/${patientId}`
  },
  context: {
    reference: encounterRef
  },
  action: [
    {
      title: "Préconsultation",
      code: [
        {
          coding: [
            {
              system: "medeo-action-definition",
              code: "questionnaire"
            }
          ]
        },
        {
          coding: [
            {
              system: "medeo-display-on-readonly",
              code: "true"
            }
          ]
        },
        {
          coding: [
            {
              system:
                "http://medeo.io/fhir/Identifier/questionnaire-preconsultation",
              code: questionnaireCode
            }
          ]
        }
      ],
      condition: [
        {
          kind: "applicability",
          description: "consultation-type",
          expression: "autonome"
        }
      ]
    },
    {
      title: "Moyen de paiement",
      code: [
        {
          coding: [
            {
              system: "medeo-action-definition",
              code: "payment-stripe"
            }
          ]
        },
        {
          coding: [
            {
              system: "medeo-display-on-readonly",
              code: "false"
            }
          ]
        }
      ],
      condition: [
        {
          kind: "applicability",
          description: "consultation-type",
          expression: "autonome"
        }
      ]
    }
  ]
});

/**
 * Initiate the QuestionnaireResponse resource
 *
 * @param {String} encounterRef
 * @param {Number} patientId
 * @param {Number} questionnaireId
 */
export const initQuestionnaireResponseResource = ({
  encounterRef,
  patientId,
  questionnaireId
}) => ({
  resourceType: "QuestionnaireResponse",
  status: "completed",
  authored: moment().format(FHIR_DATE_FORMAT),
  questionnaire: {
    reference: `Questionnaire/${questionnaireId}`
  },
  subject: {
    reference: `Patient/${patientId}`
  },
  context: {
    reference: encounterRef
  },
  item: []
});

export const initPaymentMethodResponse = ({
  encounterRef,
  patientId,
  questionnaireId
}) => ({
  resourceType: "QuestionnaireResponse",
  status: "completed",
  authored: moment().format(FHIR_DATE_FORMAT),
  questionnaire: {
    reference: `Questionnaire/${questionnaireId}`
  },
  subject: {
    reference: `Patient/${patientId}`
  },
  context: {
    reference: encounterRef
  },
  item: [
    {
      linkId: "1",
      answer: [
        {
          valueString: "card"
        }
      ]
    }
  ]
});
