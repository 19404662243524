import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState
} from "react";
import { useUpdateEffect } from "react-use";

import { SignInContext } from "../../App";
import { getOrganizationId } from "../../Auth/utils";
import useSearch from "../../Shared/hooks/useSearch";
import {
  MEDEO_CATEGORY_SYSTEM,
  MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE
} from "../../Shared/codes";
import {
  getArryOfOrganizationIdFromSearchBundle,
  sortSlotsByPerformerId
} from "../utils";
import Spinner from "../../Shared/components/Spinner";
import BookingView from "../../Slot/containers/BookingView";
import Slot from "../../Slot/components/Slot";
import useLazySearch from "../../Shared/hooks/useLazySearch";
import SubmitButton from "../../Shared/components/SubmitButton";
import { getIdByReference } from "../../Shared/utils";
import ErrorDisplay from "../../Shared/components/ErrorDisplay";

const init = props => {
  const { slot, practitioner } = props;
  return {
    slot: slot ?? null,
    // the component will look for practitioner when given an id
    practitionerId: practitioner?.id,
    practitioners: [],
    practitionerRoles: []
  };
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "click":
      return {
        ...state,
        slot: payload.slot,
        practitionerId: payload.practitionerId
      };
    case "update":
      const practitioners = payload?.Practitioner ?? [];
      const practitionerRoles = payload?.PractitionerRole ?? [];
      return {
        ...state,
        practitioners,
        practitionerRoles
      };
    default:
      return state;
  }
};

const BookingSelectSot = ({
  error,
  practitioner,
  slot,
  onChange,
  onSubmit
}) => {
  const [search] = useLazySearch();
  const [authContext] = useContext(SignInContext);
  const organizationId = getOrganizationId(authContext);
  const [state, dispatch] = useReducer(reducer, { slot, practitioner }, init);
  const { data: careTeamData } = useSearch("CareTeam", {
    participant: `Organization/${organizationId}`,
    category: `${MEDEO_CATEGORY_SYSTEM}|${MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE}`,
    _include: "CareTeam:participant"
  });
  const arrayOfOrgId = React.useMemo(
    () => getArryOfOrganizationIdFromSearchBundle(careTeamData, organizationId),
    [careTeamData, organizationId]
  );

  const [search2, { data, loading, called }] = useLazySearch();

  useEffect(() => {
    if (!called && arrayOfOrgId.length > 0) {
      search2("Schedule", {
        "actor.organization": {
          $or: arrayOfOrgId
        },
        active: "true",
        identifier: "remote-consultation",
        date: {
          $ge: moment().format("YYYY-MM-DD"),
          $le: moment()
            .add(2, "weeks")
            .format("YYYY-MM-DD")
        },
        _sort: "date",
        _count: 200,
        _include: "Schedule:actor",
        _revinclude: "Slot:schedule"
      });
    }
  }, [arrayOfOrgId, called, search2]);
  const sortedSlot = React.useMemo(() => sortSlotsByPerformerId(data), [data]);

  useUpdateEffect(() => {
    dispatch({ type: "update", payload: data });
  }, [data]);

  useEffect(() => {
    onChange({
      slot: state.slot,
      practitioner: state.practitioners.find(
        p => p.id === state.practitionerId
      ),
      performerOrganizationId: getIdByReference(
        state.practitionerRoles.find(
          pr =>
            pr.practitioner.reference === `Practitioner/${state.practitionerId}`
        )?.organization.reference
      )
    });
    // eslint-disable-next-line
  }, [state]);

  const curriedHandleChange = useCallback(
    practitionerId => async period => {
      dispatch({ type: "change" });

      // this search does not require to get any include info
      await search("Schedule", {
        actor: `Practitioner/${practitionerId}`,
        date: {
          $ge: period.start,
          $le: period.end
        },
        _revinclude: "Slot:schedule"
      });
    },
    [search, dispatch]
  );

  const [hasClicked, setHasClicked] = useState(false);
  const curriedHandleClick = practitionerId => slot => {
    setHasClicked(true);
    dispatch({ type: "click", payload: { slot, practitionerId } });
  };
  return (
    <div className="flex flex-col items-center justify-center ">
      <div className="max-w-2xl">
        <div className="mt-8 text-4xl font-bold">
          Veuillez sélectionner un horaire pour votre rendez-vous
        </div>
      </div>
      <div className="w-full overflow-auto items-center justify-center flex flex-col">
        <div className="max-w-4xl w-full">
          <form
            id="bookingSlot"
            onSubmit={onSubmit}
            className="flex flex-row justify-between w-full"
            style={{ height: "26rem", width: "100%" }}
          >
            <div
              // using this as the tailwind max-h could not work
              className="flex flex-col w-full mt-8 py-4"
            >
              {(loading || !called) && <Spinner />}
              {sortedSlot.map(({ performerId, slots }) => (
                <BookingView
                  key={performerId}
                  practitioner={data.Practitioner.find(
                    p => p.id === performerId
                  )}
                  slots={slots}
                  slot={state.slot}
                  onChange={curriedHandleChange(performerId)}
                >
                  {slot => (
                    <Slot
                      key={slot?.id}
                      checked={state.slot?.id === slot?.id}
                      slot={slot}
                      onClick={curriedHandleClick(performerId)}
                    />
                  )}
                </BookingView>
              ))}
              {called && !loading && sortedSlot.length === 0 && (
                <div className="h-full flex justify-center items-center">
                  Aucun créneau n'est disponible actuellement
                </div>
              )}
            </div>
            {hasClicked && (
              <>
                {state.slot != null ? (
                  <div className="mt-20">
                    <SubmitButton>Suivant</SubmitButton>
                  </div>
                ) : (
                  <Spinner />
                )}
              </>
            )}
          </form>
        </div>
      </div>
      {error !== "" && <ErrorDisplay value={error} />}
    </div>
  );
};

export default BookingSelectSot;
