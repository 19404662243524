import React from "react";

const ErrorDisplay = ({ value }) => {
  return (
    <div className="bg-red-200 p-4 mb-2 text-left text-xs rounded border-l-8 border-red-700 shadow">
      {value}
    </div>
  );
};

export default ErrorDisplay;
