import React from "react";
import BasicToggleButton from "../../Shared/components/BasicToggleButton";
import SubmitButton from "../../Shared/components/SubmitButton";
import Hero from "../../Shared/components/Hero";
import Form from "../../Shared/components/Form";

/**
 * List of the health problems which can be selected
 * when adding a patient.
 * Each text attribute will be the one displayed on the
 * select button, and as text attribute on the FHIR resource
 */
const healthProblemsList = [
  {
    code: "cancer",
    text: "Cancer"
  },
  {
    code: "diabetes",
    text: "Diabète"
  },
  {
    code: "hypertension",
    text: "Hypertension"
  },
  {
    code: "cardiac-disease",
    text: "Maladie cardiaque"
  },
  {
    code: "digestive-disease",
    text: "Maladie digestive"
  },
  {
    code: "infectious-disease",
    text: "Maladie infectieuse"
  },
  {
    code: "renal-disease",
    text: "Maladie rénale"
  },
  {
    code: "breathing-disease",
    text: "Maladie respiratoire"
  },
  {
    code: "other",
    text: "Autre"
  }
];

const HealthProblems = ({ onClick, onSubmit, value }) => {
  return (
    <Form id="health-problems" onSubmit={onSubmit}>
      <Hero>Lesquels ?</Hero>
      <div className="flex flex-wrap mx-20 justify-center">
        {healthProblemsList.map((healthProblem, j) => (
          <BasicToggleButton
            key={`${j}`}
            onClick={() => onClick(healthProblem)}
            checked={value.findIndex(v => v.code === healthProblem.code) !== -1}
            className="mb-2"
          >
            {healthProblem.text}
          </BasicToggleButton>
        ))}
      </div>
      <div className="flex flex-row justify-center">
        <SubmitButton disable={value?.length > 0}>Suivant</SubmitButton>
      </div>
      <div />
    </Form>
  );
};

export default HealthProblems;
