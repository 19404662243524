import React from "react";
import Hero from "../../Shared/components/Hero";
import Form from "../../Shared/components/Form";
import InputWithSubmit from "../../Shared/components/InputWithSubmit";

const Firstname = ({ value, onChange, onSubmit }) => {
  return (
    <Form id="first-name" onSubmit={onSubmit}>
      <Hero>Quel est votre prénom ?</Hero>
      <InputWithSubmit
        id="first-name"
        autoComplete="off"
        name="first-name"
        type="text"
        required
        className="w-1/2"
        value={value}
        onChange={onChange}
      />
      <div className="h-16" />
    </Form>
  );
};

export default Firstname;
