import React, { useState, useCallback, useEffect } from "react";
import QuestionnaireGroup from "./QuestionnaireGroup";
import _ from "lodash";

const Questionnaire = ({
  questionnaire,
  onSubmit,
  questionnaireResponse: questionnaireResp
}) => {
  const [state, setState] = useState(questionnaireResp);

  useEffect(() => {
    // The state here gets updated only once the questionnaire was completely filled,
    // So at this moment we can call the onSubmit callback
    if (!_.isEqual(state, questionnaireResp)) {
      onSubmit({ ...state });
    }
  }, [state, onSubmit, questionnaireResp]);

  const handleSubmit = useCallback(
    questionnaireResponse => {
      if (!_.isEqual(state.item, questionnaireResponse.item)) {
        setState({ ...state, item: questionnaireResponse.item });
      }
    },
    [setState, state]
  );

  return questionnaire != null ? (
    <QuestionnaireGroup
      item={{ item: questionnaire.item }}
      onSubmit={handleSubmit}
    />
  ) : null;
};

export default Questionnaire;
