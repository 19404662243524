import SubmitButton from "../../Shared/components/SubmitButton";
import React, { useContext } from "react";
import { StripeContext } from "../containers/StripeProvider";
import { detachPaymentMethod } from "../utils";
import BasicButton from "../../Shared/components/BasicButton";

const StripeFormFooter = () => {
  const [stripeData, dispatch] = useContext(StripeContext);

  // should be disabled if the payment choice is not NO_PAYMENT and missing name,
  // email and the stripe element is not completed
  let disabled =
    stripeData.customerName === "" ||
    stripeData.customerEmail === "" ||
    !stripeData.isComplete;

  const changeDetails = async e => {
    e.preventDefault();
    // detach payment method
    detachPaymentMethod(stripeData.customerId);
    // clear context
    dispatch({
      type: "modify-card",
      payload: {
        source: null,
        isComplete: false,
        hasSource: false,
        isCharging: false
      }
    });
  };

  return (
    <div className="m-8 flex justify-center">
      {stripeData.hasSource && !stripeData.isCharging && (
        <BasicButton className="mr-1" onClick={changeDetails}>
          Modifier
        </BasicButton>
      )}

      <SubmitButton formName="payment-form" disabled={disabled}>
        Suivant
      </SubmitButton>
    </div>
  );
};

export default StripeFormFooter;
