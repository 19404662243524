import Input from "./Input";
import SubmitButton from "./SubmitButton";

const InputWithSubmit = ({ disabled, ...rest }) => (
  <div className="flex flex-row justify-center">
    <Input className="w-1/2" {...rest} />
    <SubmitButton className="ml-6 mt-10" disabled={disabled}>
      Suivant
    </SubmitButton>
  </div>
);

export default InputWithSubmit;
