import React, { useState, useEffect, useCallback } from "react";
import QuestionnaireItem from "./QuestionnaireItem";
import FormWrapper from "../../components/FormWrapper";

const QuestionnaireGroup = ({ item, onSubmit }) => {
  const [indexOfPage, setIndexOfPage] = useState(0);
  const [state, setState] = useState(item);

  const handleChange = useCallback(
    childItem => {
      const index = item.item.findIndex(i => i.linkId === childItem.linkId);
      setState({
        ...state,
        item: [
          ...state.item.slice(0, index),
          childItem,
          ...state.item.slice(index + 1)
        ]
      });
      setIndexOfPage(indexOfPage + 1);
    },
    [indexOfPage, setState, state, item.item]
  );

  useEffect(() => {
    if (indexOfPage === item.item.length) {
      // last step
      onSubmit({
        ...state,
        item: state.item.map(i => {
          return i.answer != null ? i : { linkId: i.linkId };
        })
      });
    }
  }, [state, indexOfPage, item, onSubmit]);

  /* next useEffect : pass to the next item if the condition is not verified */
  useEffect(() => {
    const currentItem = item.item.find((i, index) => indexOfPage === index);

    // Find out whether the item should be displayed or not depending on the potential condition
    const shouldDisplayItem =
      // An item without condition should always be displayed
      currentItem?.enableWhen == null ||
      // If there is a condition
      currentItem.enableWhen.find(
        condition =>
          state.item
            // Find the answer for the concerned linkId
            .find(i => i.linkId === condition.question)
            ?.answer?.find(a => {
              // Find the type of the answer for this item (valueBoolean, valueString...)
              const answerTypes = Object.keys(a);
              return answerTypes.find(answerType => {
                // Build the condition type out of it
                // e.g valueCoding <=> answerCoding
                const conditionType = answerType.replace("value", "answer");
                return a[answerType] === condition[conditionType];
              });
              // We fall in this case when we found an answer filling the condition
            }) != null
      );

    if (!shouldDisplayItem) {
      setIndexOfPage(indexOfPage + 1);
    }
  }, [indexOfPage, setIndexOfPage, item.item, state.item]);

  return (
    <div>
      {item.item.length === indexOfPage && "Veuillez patientez..."}
      {item.item
        .filter((i, index) => indexOfPage === index)
        .map(item => (
          <FormWrapper key={item.linkId}>
            <QuestionnaireItem item={item} onChange={handleChange} />
          </FormWrapper>
        ))}
    </div>
  );
};

export default QuestionnaireGroup;
