import { useCallback, useReducer } from "react";
import { createFhirClient } from "./utils";
import { getIdByReference } from "../utils";

const saveInitialState = {
  error: undefined,
  loading: false,
  data: undefined,
  called: false
};

const saveReducer = (state, action) => {
  switch (action.type) {
    case "init":
      return saveInitialState;
    case "saving":
      return {
        ...state,
        error: undefined,
        loading: true,
        called: true
      };
    case "saved":
      return {
        ...state,
        data: action.payload.data,
        error: undefined,
        loading: false,
        called: true
      };
    case "failed":
      return {
        ...state,
        data: undefined,
        error: action.payload,
        loading: false,
        called: true
      };
    default:
      return state;
  }
};

/**
 * This hook replicate the saveFhirResourceWorker.
 */
const useSave = () => {
  const [state, dispatch] = useReducer(saveReducer, saveInitialState);

  const run = useCallback(async resource => {
    try {
      dispatch({ type: "saving" });

      const fhirClient = await createFhirClient();

      if (
        resource.resourceType === "Bundle" &&
        resource.type === "transaction"
      ) {
        let method = fhirClient.transaction;
        const { data: body } = await method({ resource });
        // With a Transaction, response body will not contained resources
        // it will simply display the location where the resource is stored
        // to be compatible with our redux store and actions
        // we merge the entries from the request and from the response
        // we add the id contained in the location of the response to the resources

        const entry = body.entry?.map((e, i) => {
          const id = getIdByReference(e.response?.location);
          return {
            ...e,
            resource: {
              ...resource.entry[i].resource,
              id
            }
          };
        });
        const mergedBody = { ...body, entry };
        dispatch({ type: "saved", payload: mergedBody });
        return mergedBody;
      } else {
        // check whether it should create or update the resource
        let method =
          resource.id == null ? fhirClient.create : fhirClient.update;

        // this is the normal case, we got a simple resource to POST/PUT,
        // The resource is posted/put then a receiveFhirResource is dispatched.
        const response = await method({ resource });

        dispatch({ type: "saved", payload: response });
        return response.data;
      }
    } catch (e) {
      console.log(e);
      console.log(resource.type, e);
      dispatch({ type: "failed", payload: e });
      return { error: e };
    }
  }, []);

  return [run, state];
};

export default useSave;
