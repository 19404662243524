import { Auth } from "aws-amplify";

export const getOrganizationId = auth => auth?.attributes["custom:orgId"];

/**
 * the accessToken should not be fetched from the redux state,
 * as it is not updated once expired.
 * this mechanism is provided by Amplify and the Auth library
 * @returns {Promise<string|*>} token
 */
export const getTokenFromAmplify = async () => {
  const session = await Auth.currentSession();
  return session.accessToken.getJwtToken();
};
