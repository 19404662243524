import React, { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import logo from "../../Images/logo.png";
/* global $_ipm */

const CustomSignIn = props => {
  const [isLoading, setLoading] = useState(false);
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    const { elements } = e.currentTarget;
    Auth.signIn(
      elements.namedItem("email").value,
      elements.namedItem("password").value
    )
      .then(() => {
        props.onStateChange("signedIn");
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        // dispatch(createNotification(err.message, "error", 5000));
      });
  };

  // We put a type text here so that the ipm keyboard will enable the
  // user to type '+' in as well for instance, which are not contained
  // by default into the email keyboard
  // on chrome we use email so the on screen keyboard while show the correct layout
  const type = typeof $_ipm !== undefined ? "text" : "email";

  // display the keyboard when arrive on the page
  const hasRun = useRef(false);
  const ref = useRef(null);
  useEffect(() => {
    let handler;
    if (hasRun.current === false) {
      if (typeof $_ipm !== "undefined") {
        // sometimes the keyboard opens too early, then it collapses
        // that's why we open the keyboard again after a few seconds
        handler = setTimeout(() => {
          $_ipm.taboskbd.showLayout(type);
        }, 3000);
      }
      ref.current.focus();
      hasRun.current = true;
      return () => {
        clearTimeout(handler);
      };
    }
  }, [hasRun, type]);

  return (
    <div>
      <div>
        <img className="mx-auto h-20 w-auto" src={logo} alt="logo" />
      </div>
      <form className="mt-8 space-y-6" onSubmit={onSubmit}>
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="email-address" className="text-gray-800">
              Adresse email
            </label>
            <input
              ref={ref}
              id="email-address"
              name="email"
              type={type}
              autoComplete="email"
              required
              onClick={() => {
                if (typeof $_ipm !== "undefined") {
                  $_ipm.taboskbd.showLayout(type);
                }
              }}
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="password" className="text-gray-800">
              Mot de passe
            </label>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              onClick={() => {
                if (typeof $_ipm !== "undefined") {
                  $_ipm.taboskbd.showLayout("text");
                }
              }}
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-500 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading === true}
            className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {isLoading ? (
              <p>Veuillez patienter s'il-vous-plaît...</p>
            ) : (
              <p>SE CONNECTER</p>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomSignIn;
