import React, { useContext, useState, useEffect } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import NewDetailsForm from "../../Stripe/components/NewDetailsForm";
import { StripeContext } from "../../Stripe/containers/StripeProvider";
import ReusableDetails from "../../Stripe/components/ReusableDetails";
import StripeFormFooter from "../../Stripe/components/StripeFormFooter";
import {
  setupIntent,
  confirmCardSetup,
  detachPaymentMethod,
  createSource,
  createCharge,
  handleCardSetup
} from "../../Stripe/utils";
import Spinner from "../../Shared/components/Spinner";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";
import Form from "../../Shared/components/Form";
import BasicButton from "../../Shared/components/BasicButton";
export const THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM =
  "http://medeo.io/fhir/Identifier/third-party-payer";
export const THIRD_PARTY_PAYER_NONE = "none";
export const THIRD_PARTY_PAYER_AM_PART = "am-part";
export const THIRD_PARTY_PAYER_FULL = "full";

const useStripeHasRun = initialValue => {
  const [, dispatch] = useContext(StripeContext);
  const [hasRun, setHasRun] = useState(false);
  useEffect(() => {
    if (!hasRun) {
      dispatch({ type: "customer-name", payload: initialValue.name });
      dispatch({ type: "customer-email", payload: initialValue.email });
      setHasRun(true);
    }
  }, [setHasRun, dispatch, initialValue, hasRun]);
};

export const getThirdPartyPayerDetails = practitioner => {
  return practitioner.identifier?.find(
    identifier => identifier.system === THIRD_PARTY_PAYER_IDENTIFIER_SYSTEM
  )?.value;
};

const PaymentForm = ({
  onSubmit,
  patientId,
  practitioner,
  initialValue,
  metadata
}) => {
  const [stripeData, dispatch] = useContext(StripeContext);
  const stripe = useStripe();
  const elements = useElements();
  useStripeHasRun(initialValue);
  const thirdPartyOption = getThirdPartyPayerDetails(practitioner);

  const handlePaymentSetup = async e => {
    e.preventDefault();
    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    dispatch({
      type: "loading",
      payload: true
    });
    // If we already have a source attached we can navigate directly
    if (stripeData.hasSource) {
      dispatch({ type: "loading", payload: false });
      // do the process to navigate
      onSubmit();
    } else {
      // If not card is already attached we do the process to check it,
      // and create a charge. Once all's good we allow the navigation.
      // In case we get an error we put an error message to inform user
      const setupIntentResponse = await setupIntent();

      if (setupIntentResponse.error) {
        dispatch({
          type: "error",
          payload: setupIntentResponse.error.message
        });
        return;
      }
      // here we confirm the card using the client secret we created with the setupIntent
      const confirmation = await confirmCardSetup(
        stripe,
        setupIntentResponse.clientSecret,
        cardElement,
        stripeData.customerName,
        stripeData.customerEmail
      );
      if (confirmation.error) {
        dispatch({
          type: "error",
          payload: confirmation.error.message
        });
        return;
      }
      const paymentMethodId = confirmation.setupIntent.payment_method;
      // call card setup lambda and pass patientId and paymentMethod along with cardHolder's name and email
      const cardSetup = await handleCardSetup(
        patientId,
        stripeData.customerName,
        stripeData.customerEmail,
        paymentMethodId
      );
      const source = await createSource(
        stripe,
        cardElement,
        stripeData.customerName,
        stripeData.customerEmail
      );
      const charge = await createCharge(
        cardSetup.customerId,
        source.id,
        metadata,
        practitioner.id
      );
      if (charge.error) {
        dispatch({ type: "loading", payload: false });
        dispatch({
          type: "error",
          payload: charge.error
        });
        // Detach all remaining payment methods
        await detachPaymentMethod(cardSetup.customerId);
        // detachPaymentMethod(stripeData.id)
      } else {
        dispatch({
          type: "setup-complete",
          payload: {
            id: cardSetup.customerId,
            source: source,
            stripeCharges: charge.chargeId
          }
        });
        onSubmit(charge.chargeId);
      }
    }
  };
  switch (thirdPartyOption) {
    case THIRD_PARTY_PAYER_FULL:
    case THIRD_PARTY_PAYER_AM_PART:
      return (
        <div className="flex flex-col">
          <div>
            <Hero>Le médecin téléconsultant accepte le tiers-payant</Hero>
            <Help>Vous ne serez pas débité pour cette téléconsultation</Help>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center">
            <BasicButton
              onClick={() => {
                onSubmit();
              }}
            >
              Suivant
            </BasicButton>
          </div>
        </div>
      );
    case THIRD_PARTY_PAYER_NONE:
    default:
      return (
        <Form id="payment" onSubmit={handlePaymentSetup}>
          <div>
            <Hero>Veuillez remplir vos informations de paiement</Hero>
            <Help>
              Le prélèvement sera effectué lorsque la consultation sera
              terminée.
            </Help>
          </div>
          <div>
            {stripeData.hasSource && stripeData.source != null && (
              <ReusableDetails />
            )}
            {/* If the user doesn't have payment card saved */}
            {!stripeData.hasSource && <NewDetailsForm />}

            {stripeData.errorMessage !== "" ? (
              <p className="text-red-500 text-center">
                {stripeData.errorMessage}
              </p>
            ) : null}
          </div>
          <StripeFormFooter />
          {stripeData.isLoading && <Spinner />}
          {/* As we use the shared Form component, we must use this trick to make the footer higher
      ... in case there is the keyboard on this screen, the button is hidden otherwise */}
          <div className="flex-1" />
        </Form>
      );
  }
};

export default PaymentForm;
