import React from "react";
import moment from "moment";

const Slot = ({ slot, onClick, checked, index }) => {
  const handleClick = () => {
    onClick(slot);
  };
  const id = `${slot?.id}-${slot?.start}`;
  return (
    <div className="relative mb-4 w-10">
      <input
        type="radio"
        data-testid="createSlot"
        id={id}
        name="slot"
        value={index}
        // when the slot is taken from someone else, we need to update the property so we keep this
        defaultChecked={checked}
        onClick={handleClick}
        required
        className="absolute opacity-0 top-1/2 left-1/2"
      />
      <label
        className={`${
          checked ? "bg-blue-400 text-white" : "text-blue-400 bg-white"
        } px-2 py-1 black text-center border cursor-pointer border-blue-400 rounded-md hover:bg-blue-200 hover:text-black`}
        htmlFor={id}
      >
        {moment(slot?.start).format("HH:mm")}
      </label>
    </div>
  );
};

export default Slot;
