import React, { useEffect, useState } from "react";
import patient_card from "../../patient_card.png";

const WaitingComponent = ({ onSubmit }) => {
  const [hasRun, setHasRun] = useState(false);

  // here we save the patient and we connect it through the dashboard
  useEffect(() => {
    if (!hasRun) {
      onSubmit();
      setHasRun(true);
    }
  }, [onSubmit, hasRun]);
  return (
    <div className="w-full flex justify-between items-center m-10 flex-col">
      <div className="text-4xl font-bold mb-10">
        Création de votre dossier patient en cours...
      </div>
      <div>
        <img className="object-fill" src={patient_card} alt="waiting" />
      </div>
    </div>
  );
};

export default WaitingComponent;
