import React from "react";

function FormWrapper({ children }) {
  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <div className="max-w-2xl space-y-8">{children}</div>
      </div>
    </div>
  );
}

export default FormWrapper;
