// MEDEO systems
export const MEDEO_NIR_IDENTIFIER_SYSTEM =
  "http://medeo.io/fhir/Identifier/patient-nir";
export const MEDEO_VALUE_SET_MEDICAL_HISTORY_SYSTEM =
  "http://medeo.io/fhir/Value-set/medical-history";
export const MEDEO_VALUE_SET_SURGICAL_HISTORY_SYSTEM =
  "http://medeo.io/fhir/Value-set/surgical-history";
export const MEDEO_ALLERGY_SYSTEM = "http://medeo.io/allergy";
export const MEDEO_MEDICAL_HISTORY_SYSTEM = "http://medeo.io/medical-history";
export const MEDEO_SURGICAL_HISTORY_SYSTEM = "http://medeo.io/surgical-history";
export const MEDEO_ENCOUNTER_TYPE_SYSTEM =
  "http://medeo.io/fhir/ValueSet/encounter-type";
export const MEDEO_ENCOUNTER_ORIGIN_SYSTEM =
  "http://medeo.io/fhir/ValueSet/encounter-origin";
export const MEDEO_CATEGORY_SYSTEM = "http://medeo.io/fhir/Category";
export const ESANTE_OCCUPATION_SYSTEM =
  "https://mos.esante.gouv.fr/NOS/TRE_R37-TypeProfessionFonction/";
export const ESANTE_SPECIALTY_SYSTEM =
  "https://mos.esante.gouv.fr/NOS/TRE_R38-SpecialiteOrdinale/";
export const MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM =
  "http://medeo.io/fhir/ValueSet/procedure-code";
export const MEDEO_APPOINTMENT_IDENTIFIER_SYSTEM =
  "http://medeo.io/fhir/appointment/type";
export const MEDEO_APPOINTMENT_TYPE_TELECONSULTATION = "remote-consultation";
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_SYSTEM =
  "https://medeo.io/fhir/Identifier/general-practitioner";
export const MEDEO_RPPS_IDENTIFIER_SYSTEM =
  "http://medeo.io/fhir/Identifier/numero-rpps";
export const MEDEO_ITEM_VALUE_SET_SYSTEM =
  "http://medeo.io/fhir/ValueSet/item-code";

// MEDEO codes
export const MEDEO_MEDICAL_HISTORY_CODE = "medical-history";
export const MEDEO_SURGICAL_HISTORY_CODE = "surgical-history";
export const MEDEO_OUTSIDE_COLLABORATOR_CARETEAM_CODE =
  "outside-collaborator-careteam";
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_DU_TERRITOIRE =
  "medecin-du-territoire";
export const MEDEO_GENERAL_PRACTITIONER_IDENTIFIER_MEDECIN_TRAITANT_CODE =
  "medecin-traitant";
export const MEDEO_ENCOUNTER_TYPE_ADD_PATIENT = "add_patient";
export const MEDEO_KIOSK_MOTIVE_QUESTIONNAIRE = "motive-kiosk";
export const MEDEO_MOTIVE_QUESTIONNAIRE = "motives"; // used on medeo.care

export const MEDEO_PAYMENT_METHOD_QUESTIONNAIRE = "payment-method";
export const MEDEO_DOCUMENT_QUESTIONNAIRE = "document";
export const MEDEO_TOTAL_PRICE_QUESTIONNAIRE = "totalPrice";
export const MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE =
  "booking-appointment";
export const MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE = "teleconsultation";

// MEDEO Encounter codes
export const MEDEO_ENCOUNTER_ORIGIN_KIOSK = "kiosk";
export const MEDEO_ENCOUNTER_TYPE_BOOKING = "booking-appointment";
export const MEDEO_ENCOUNTER_TYPE_PRE_TELECONSULTATION = "pre_teleconsultation";

// item codes
export const MEDEO_MOTIVE_ITEM_CODE = "motive";

// SNOMED codes
export const SNOMED_SYSTEM_URL = "http://snomed.info/sct";
export const SNOMED_DAILY_SMOKER = "449868002";
export const SNOMED_FINING_TOBACCO = "365980008";

// FHIR codes
export const FHIR_OBSERVATION_CATEGORY_SYSTEM_URL =
  "http://hl7.org/fhir/observation-category";
export const FHIR_OBSERVATION_SOCIAL_HISTORY = "social-history";
export const FHIR_OBSERVATION_VITAL_SIGNS = "vital-signs";
export const FHIR_CONDITION_CATEGORY_SYSTEM_URL =
  "http://terminology.hl7.org/CodeSystem/condition-category";
export const FHIR_PROBLEM_LIST_CODE = "problem-list-item";

export const UNITS_OF_MEASURE_SYSTEM_URL = "http://unitsofmeasure.org";

// LOINC codes
export const LOINC_SYSTEM_URL = "http://loinc.org";
export const LOINC_BODY_HEIGHT_CODE = "8302-2";
export const LOINC_BODY_WEIGHT_CODE = "29463-7";

// SNOMED codes
export const BLOOD_PRESSURE_MONITOR_SNOMED = "135840009";
export const OTOSCOPE_SNOMED = "470067005";
export const OXIMETER_SNOMED = "462877003";
export const STETHOSCOPE_SNOMED = "468627005";
export const THERMOMETER_SNOMED = "448735006";
