import React from "react";
import BasicButton from "../../Shared/components/BasicButton";
import Hero from "../../Shared/components/Hero";

const BinaryTobacco = ({ onClick }) => {
  return (
      <div className="flex flex-1 flex-col justify-between">
          <Hero>Consommez-vous du tabac ?</Hero>
          <div className="flex justify-center">
        <BasicButton onClick={() => onClick(true)}>oui</BasicButton>
        <BasicButton className="ml-6" onClick={() => onClick(false)}>non</BasicButton>
      </div>
          <div/>
      </div>
  );
};

export default BinaryTobacco;
