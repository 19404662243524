import logo from "../../Images/logo.png";
import moment from "moment";
import React from "react";

function Footer() {
  return (
    <footer className="custombp:hidden pointer-events-none w-full flex justify-between px-4 items-center overflow-hidden">
      <div className="flex-1 flex items-start flex-col">
        <div className="text-sm text-gray-600">
          Medeo n'est pas un service d'urgence.
        </div>
        <div className="text-sm text-gray-600">
          En cas d'urgence composez le 15 ou le 112
        </div>
      </div>
      <div className="flex-1 flex justify-center">
        <img className="h-20 object-fill" src={logo} alt="logo" />
      </div>
      <div className="flex-1 flex items-end flex-col">
        <div className="text-sm text-gray-600">{moment().format("HH:mm")}</div>
        <div className="text-sm text-gray-600">{moment().format("L")}</div>
      </div>
    </footer>
  );
}

export default Footer;
