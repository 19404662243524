import React, { useState } from "react";
import Hero from "../../components/Hero";
import Form from "../../components/Form";
import InputWithSubmit from "../../components/InputWithSubmit";

const QuestionnaireString = ({ item, onChange }) => {
  const [state, setState] = useState({ linkId: item.linkId });

  const handleChange = e => {
    setState({ ...state, answer: [{ valueString: e.target.value }] });
  };

  const handleSubmit = () => {
    onChange(state);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Hero className="mt-8">{item.text}</Hero>
      <div className="mt-12">
        <InputWithSubmit type={"text"} required onChange={handleChange} />
      </div>
    </Form>
  );
};

export default QuestionnaireString;
