import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Router, useNavigate, Redirect } from "@reach/router";
import Header from "../../Shared/components/Header";
import Start from "../components/Start";
import PaymentForm from "../components/PaymentForm";
import { v4 as uuid } from "uuid";
import useSave from "../../Shared/hooks/useSave";
import {
  computeInitialState,
  initPaymentMethodResponse,
  initQuestionnaireResponseResource,
  initRequestGroupResource
} from "../utils";
import StripeProvider from "../../Stripe/containers/StripeProvider";
import useSearch from "../../Shared/hooks/useSearch";
import Questionnaire from "../../Shared/Questionnaire/containers/Questionnaire";
import {
  MEDEO_KIOSK_MOTIVE_QUESTIONNAIRE,
  MEDEO_MOTIVE_ITEM_CODE,
  MEDEO_MOTIVE_QUESTIONNAIRE,
  MEDEO_PAYMENT_METHOD_QUESTIONNAIRE
} from "../../Shared/codes";
import { getIdByReference } from "../../Shared/utils";
import { getAnswerForCode } from "../../Shared/Questionnaire/utils";

const ExamenClinique = ({
  patient,
  procedureRequest,
  onFinish,
  practitioner,
  currentOrganization
}) => {
  const navigate = useNavigate();
  const steps = useMemo(
    () => [{ url: "start" }, { url: "payment" }, { url: "symptoms" }],
    []
  );
  const [indexOfPage, setIndexOfPage] = useState(0);
  const [headerText] = useState("Examen clinique");
  const [encounterFullUrl] = useState(`urn:uuid:${uuid()}`);
  const [procedureRequestFullUrl] = useState(`urn:uuid:${uuid()}`);
  // Store the encounter id once the process is
  // completed and the entries were saved, and thus keep track of when
  // we can finish the clinical exam part.
  const [preconsultationId, setPreconsultationId] = useState(null);

  const [entries, setEntries] = useState(
    computeInitialState({
      encounterFullUrl,
      patient,
      procedureRequestFullUrl,
      procedureRequest
    })
  );

  const onBackHeader = () => {
    if (indexOfPage >= 1) {
      setIndexOfPage(indexOfPage - 1);
    } else {
      navigate(`../../${patient.id}`);
    }
  };

  const [save] = useSave();
  const bundle = useMemo(
    () => ({
      resourceType: "Bundle",
      type: "transaction",
      entry: Object.values(entries)
    }),
    [entries]
  );

  const goNext = () => {
    setIndexOfPage(indexOfPage => indexOfPage + 1);
  };

  const handleSymptomsSubmit = useCallback(
    questionnaireResponse => {
      setEntries(entries => ({
        ...entries,
        symptomsQuestionnaireResponse: {
          ...entries.symptomsQuestionnaireResponse,
          resource: questionnaireResponse
        }
      }));
      goNext();
    },
    [setEntries]
  );

  const handlePaymentSubmit = useCallback(
    chargeId => {
      setEntries(entries => {
        const updatedPaymentNotice = { ...entries.paymentNotice };
        if (chargeId != null) {
          updatedPaymentNotice.resource.paymentStatus.coding.find(
            c => c.system === "http://medeo.io/fhir/PaymentNotice/charge-id"
          ).code = chargeId;
        } else {
          updatedPaymentNotice.resource.paymentStatus = {
            coding: [
              {
                system: "http://medeo.io/fhir/PaymentNotice/payment-status",
                code: "authorized"
              },
              {
                system: "http://medeo.io/fhir/PaymentNotice/payment-method",
                code: "no-payment"
              },
              {
                system: "http://medeo.io/fhir/PaymentNotice/payment-amount",
                code: 0
              }
            ]
          };
        }
        console.log("salut");
        return {
          ...entries,
          paymentNotice: updatedPaymentNotice
        };
      });
      goNext();
    },
    [setEntries]
  );

  useEffect(() => {
    if (indexOfPage < steps.length && indexOfPage >= 0) {
      navigate(`./${steps[indexOfPage].url}`);
    }
  }, [indexOfPage, steps, navigate]);

  useEffect(() => {
    const saveData = async () => {
      const response = await save(bundle);
      const encounterContextId = response?.entry?.find(
        r => r.resource.resourceType === "Encounter"
      )?.resource?.id;
      return encounterContextId;
    };

    if (indexOfPage === steps.length) {
      saveData().then(encounterId => setPreconsultationId(encounterId));
    }
  }, [indexOfPage, save, bundle, steps, setPreconsultationId]);

  // This useEffect is meant to be called at the end of the process,
  // Once the entries were saved and the tlc procedureRequest was created,
  // meaning we are ready to call the onFinish callback.
  // The useState below make sure this effect is run only once,
  // ...otherwise more than one chrome webpage will open.
  const [hasRun, setHasRun] = useState(false);
  useEffect(() => {
    if (preconsultationId != null && hasRun === false) {
      setHasRun(true);
      onFinish(preconsultationId);
    }
  }, [onFinish, preconsultationId, hasRun]);

  // ---- for Stripe ----
  const patientName = `${patient?.name?.[0]?.family} ${patient?.name?.[0]?.given?.[0]}`;
  const patientEmail = patient?.telecom?.find(t => t.system === "email")?.value;

  // ---- for symptoms questionnaire ----
  // todo : create and upload a questionnaire and add its id as reference to the questionnaireResponse...
  // ... use the reference as a criteria to fetch
  const { data: questionnaireResponseData } = useSearch(
    "QuestionnaireResponse",
    {
      context: procedureRequest.context.reference,
      _include: "QuestionnaireResponse:questionnaire"
    }
  );

  const motiveQuestionnaire = questionnaireResponseData?.Questionnaire?.find(
    questionnaire =>
      questionnaire.identifier.find(
        identifier =>
          // The motive questionnaire can either be the one of kiosk or of medeo.care,
          // so that a booking performed on medeo.care can be ended here.
          // The codes used in both questionnaires are identical.
          identifier.value === MEDEO_KIOSK_MOTIVE_QUESTIONNAIRE ||
          identifier.value === MEDEO_MOTIVE_QUESTIONNAIRE
      )
  );

  const motiveResponse = questionnaireResponseData?.QuestionnaireResponse?.find(
    response =>
      getIdByReference(response.questionnaire.reference) ===
      motiveQuestionnaire?.id
  );

  const motiveCode = getAnswerForCode({
    questionnaire: motiveQuestionnaire,
    questionnaireResponse: motiveResponse,
    code: MEDEO_MOTIVE_ITEM_CODE
  })?.[0]?.valueCoding?.code;

  // when all the questionnaire will be written and uploaded...
  // ... we should fetch them instead of "fever-form-kiosk"
  const { data: questionnaireData } = useSearch("Questionnaire", {
    identifier: {
      $or: [`${motiveCode}-kiosk`, MEDEO_PAYMENT_METHOD_QUESTIONNAIRE]
    }
  });
  const symptomsQuestionnaire = questionnaireData?.Questionnaire?.find(
    questionnaire =>
      questionnaire.identifier?.[0]?.value === `${motiveCode}-kiosk`
  );

  const paymentMethodQuestionnaire = questionnaireData?.Questionnaire?.find(
    questionnaire =>
      questionnaire.identifier?.[0]?.value ===
      MEDEO_PAYMENT_METHOD_QUESTIONNAIRE
  );

  // We set the QuestionnaireResponse and RequestGroup here once we fetched the
  // information to initiate them correctly, that is the questionnaire they refer to
  useEffect(() => {
    if (symptomsQuestionnaire != null) {
      setEntries(entries => {
        const questionnaireResponse = initQuestionnaireResponseResource({
          encounterRef: encounterFullUrl,
          patientId: patient.id,
          questionnaireId: symptomsQuestionnaire.id
        });

        const requestGroup = initRequestGroupResource({
          encounterRef: encounterFullUrl,
          patientId: patient.id,
          questionnaireCode: `${motiveCode}-kiosk`
        });

        const paymentMethodResponse = initPaymentMethodResponse({
          encounterRef: encounterFullUrl,
          patientId: patient.id,
          questionnaireId: paymentMethodQuestionnaire.id
        });

        return {
          ...entries,
          symptomsQuestionnaireResponse: {
            ...entries.symptomsQuestionnaireResponse,
            resource: questionnaireResponse
          },
          paymentMethodResponse: {
            ...entries.paymentMethodResponse,
            resource: paymentMethodResponse
          },
          requestGroup: {
            ...entries.requestGroup,
            resource: requestGroup
          }
        };
      });
    }
  }, [
    symptomsQuestionnaire,
    motiveCode,
    patient.id,
    encounterFullUrl,
    paymentMethodQuestionnaire
  ]);

  const performerDisplay = procedureRequest.performer?.display;
  const performerId = getIdByReference(procedureRequest.performer?.reference);
  const metadata = {
    patient: `${patient?.name?.[0]?.given?.[0]} ${patient?.name?.[0]?.family}`,
    from: `(id : ${currentOrganization?.id}) ${currentOrganization?.name}, ${currentOrganization?.address?.[0]?.line?.[0]}, ${currentOrganization?.address?.[0]?.postalCode}, ${currentOrganization?.address?.[0]?.city}`,
    to: `(id: ${performerId}) ${performerDisplay}`,
    fee_total: "€1,00"
  };

  return (
    <StripeProvider>
      <div className="flex h-full flex-col">
        <Header
          cancelText="Vous êtes sur le point d'abandonner votre examen clinique. Les informations fournies ne seront pas enregistrées."
          headerText={headerText}
          onBack={onBackHeader}
          onCancel={() => navigate(`../../${patient.id}`)}
          widthOfProgressBar={(indexOfPage / steps.length) * 100}
          isPrevious={indexOfPage > 1}
        />
        <Router className="router m-10 flex-1 flex justify-center">
          <Start path="start" onSubmit={goNext} />
          <PaymentForm
            path="payment"
            practitioner={practitioner}
            onSubmit={handlePaymentSubmit}
            patientId={patient.id}
            initialValue={{ name: patientName, email: patientEmail }}
            metadata={metadata}
          />
          <Questionnaire
            path="symptoms"
            onSubmit={handleSymptomsSubmit}
            questionnaire={symptomsQuestionnaire}
            questionnaireResponse={
              entries.symptomsQuestionnaireResponse.resource
            }
          />
          <Redirect from="/" to="start" noThrow />
        </Router>
      </div>
    </StripeProvider>
  );
};

export default ExamenClinique;
