const AWS_ES_ENDPOINT =
  "https://search-medeo-elasticsearch-whxkhi7oxdmphqgi26dhwq72bi.eu-west-1.es.amazonaws.com/";

/**
 * Retrieves the hits from the AWS Elastic Search Practitioner Index matching the query passed
 * as a param.
 *
 * the hit response from the AWS Elastic search medication index looks like this:
 * {
 *   CIS: 12345678,
 *   name: "Medicament Trop Bien"
 *   formDesc: "pillule"
 * }
 *
 *
 * @param query
 * @returns {Promise<*>}
 */
export const queryPractitionerFromES = async query => {
  // use the url object to construct and encoded properly the query
  const url = new URL(
    "practitioner/_msearch?",
    AWS_ES_ENDPOINT + "practitioner"
  );

  // fetch is surrounded with a try/catch block to prevent any failure from breaking the app
  // if we fail we do it silently for now.
  try {
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/x-ndjson"
      },
      body:
        '{"preference":"SearchPra"}\n' +
        '{"query":{"bool":{"must":[{"bool":{"must":{"bool":{"must":[{"multi_match":{"query":"' +
        query +
        '","type":"most_fields","fields":["lastName^3","firstName^3","*"]}},{"match":{"name_trade.keyword":{"query":"Medecin"}}}]}}}}]}},"size":10,"_source":{"includes":["*"],"excludes":[]}}\n'
    });

    const json = await response.json();

    return json.responses[0].hits.hits.map(i => i._source);
  } catch {
    console.warn("something went wrong with practitioner ES");
    // return an empty list of hits to prevent any whitescreen
    return [];
  }
};
