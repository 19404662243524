import React from "react";
import { Router } from "@reach/router";
import ManualLogin from "./ManualLogin";
import CardAuthentication from "./CardAuthentication";

const Auth = () => {
  return (
    <Router className="router flex-1 flex">
      <CardAuthentication path="/" />
      <ManualLogin path="/manual/*" />
    </Router>
  );
};

export default Auth;
