import React, { useState } from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import moment from "moment";
import Hero from "../../Shared/components/Hero";
import Form from "../../Shared/components/Form";
import Input from "../../Shared/components/Input";

const Birthdate = ({ onSubmit, onChange, value }) => {
  const [state, setState] = useState(
    value !== ""
      ? {
          day: moment(value, "YYYY-MM-DD").format("DD"),
          month: moment(value, "YYYY-MM-DD").format("M"),
          year: moment(value, "YYYY-MM-DD").year()
        }
      : {}
  );

  const monthsArray = Array.from({ length: 12 }, (_, i) => {
    return new Date(0, i).toLocaleString("fr-FR", { month: "long" });
  });

  const maxYear = new Date().getFullYear();
  const minYear = maxYear - 120;

  const handleSubmit = e => {
    e.preventDefault();
    const string = `${state.year}-${state.month}-${state.day}`;
    const formattedDate = moment(string, "YYYY-MM-DD").format("YYYY-MM-DD");
    onChange(formattedDate);
    onSubmit(e);
  };
  return (
    <Form id="birthdate" onSubmit={handleSubmit}>
      <Hero>Quelle est votre date de naissance ?</Hero>
      <div className="flex justify-center">
        <div className="flex flex-row justify-center max-w-lg mt-20">
          <div className="ml-2">
            <Input
              type="number"
              id="day"
              label="Jour"
              min="1"
              max="31"
              value={state.day ?? ""}
              onChange={e => setState({ ...state, day: e.target.value })}
              required
              autoFocus
            />
          </div>
          <div className="mt-10 ml-2">
            <label
              htmlFor="month"
              className="block text-xs font-medium text-gray-700 mb-5"
            >
              Mois
            </label>
            <select
              id="month"
              name="month"
              value={state.month ?? "default"}
              onChange={e => setState({ ...state, month: e.target.value })}
              className="h-10 block w-full py-2 px-3 border border-gray-500 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option disabled value={"default"}>
                ...
              </option>
              {monthsArray.map((month, i) => (
                <option key={month} value={i + 1}>
                  {month}
                </option>
              ))}
            </select>
          </div>
          <div className="ml-2">
            <Input
              value={state.year ?? ""}
              id="years"
              label="Année"
              max={maxYear}
              min={minYear}
              onChange={e => setState({ ...state, year: e.target.value })}
              required
              type="number"
            />
          </div>
          <SubmitButton
            className="ml-6 mt-20 relative -top-0.5"
            disabled={
              !moment(
                `${state.year}-${state.month}-${state.day}`,
                "YYYY-MM-DD"
              ).isValid()
            }
          >
            Suivant
          </SubmitButton>
        </div>
      </div>
      <div className="h-16" />
    </Form>
  );
};

export default Birthdate;
