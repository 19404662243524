/*global $_ipm*/

const StripeInput = ({ className, ...rest }) => {
  const showKeyboard = type => {
    if (typeof $_ipm !== "undefined") $_ipm.taboskbd.showLayout(type);
  };

  return (
    <input
      {...rest}
      onClick={() => showKeyboard("number")}
      className={`${className} appearance-none rounded-md relative h-10 mt-1 w-full px-3 py-2 border border-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
    />
  );
};

export default StripeInput;
