import { Auth } from "aws-amplify";
import buildFhir from "lifen-fhir.js/src/adapters/native";

export const getFhirURLFromEnv = () => {
  if (process.env.REACT_APP_FHIR_BASE_URL == null) {
    const defaultValue = "http://localhost:8080/fhir";
    const message = `REACT_APP_FHIR_BASE_URL is not set.
      Please make sure to add a .env file to the project root directory.
      Use REACT_APP_FHIR_BASE_URL env variable to change the FHIR Base URL.
      Using default value (${defaultValue})`;
    console.warn(message);
    return new URL(defaultValue);
  }
  return new URL(process.env.REACT_APP_FHIR_BASE_URL);
};

/**
 * create a FhirClient dynamically, it calls Amplify Auth library
 * to get the latest accessToken.
 *
 * This method should be called every time an api call is made to the
 * database.
 *
 * Auth library makes sure to refresh the token if required.
 * @see https://github.com/aws-amplify/amplify-js/wiki/FAQ
 * @returns {Promise<fhirClient.FhirClient>}
 */
export const createFhirClient = async () => {
  // accessToken should not be fetched from the redux state,
  // as it is not updated once expired.
  // this mechanism is provided by Amplify and the Auth library
  const session = await Auth.currentSession();
  const token = session.accessToken.getJwtToken();

  // return prod or dev base url
  const fhirBaseUrl = getFhirURLFromEnv();
  return buildFhir({
    baseUrl: fhirBaseUrl.href,
    credentials: "same-origin",
    auth: {
      bearer: token
    }
  });
};

/**
 * extract FHIR resources out of a Bundle and transforms them in a map
 * the map keys are the FHIR resources names (Patient, Encounter, ...)
 * and the values are arrays of this type of resources
 * @param entries
 * @return {*}
 */
export const groupResources = (entries, previousState = {}) => {
  const resources =
    entries != null && entries.length > 0 ? entries.map(e => e.resource) : [];
  //at this step we have resources = [{ id: '12', resourceType: 'Patient' },  { id: '13', resourceType: 'Encounter' }]

  return resources.reduce((groupedByType, r) => {
    const currentType = r.resourceType;
    const previousResourceList = groupedByType[r.resourceType] || [];
    return {
      // spread the groupedByType object so other types get passed along
      ...groupedByType,
      // overwrite the currentType field with a new list where we append the current resource
      [currentType]: [...previousResourceList, r]
    };
  }, previousState);
};
