import React from "react";
import { faTimes} from "@fortawesome/free-solid-svg-icons";


import Heading from "../../Shared/components/Heading";
import HeaderButtonLink from "../../Shared/components/HeaderButtonLink";

function Header({ headerText }) {
  return (
      <div className="p-6 bg-white flex flex-row justify-between items-center shadow-sm border-b-2">
        <div className="w-10 h-10"/>
        <Heading>{headerText}</Heading>
        <HeaderButtonLink to="/" icon={faTimes}/>
      </div>
  );
}

export default Header;
