/* global $_ipm */
import React, { useEffect, useState } from "react";
import useLazySearch from "../../Shared/hooks/useLazySearch";
import useSearch from "../../Shared/hooks/useSearch";
import { getIdByReference } from "../../Shared/utils";
import {
  MEDEO_DOCUMENT_QUESTIONNAIRE,
  MEDEO_TOTAL_PRICE_QUESTIONNAIRE
} from "../../Shared/codes";

const Visio = ({ onBack, onExit, patientId, encounterId }) => {
  useEffect(() => {
    if (typeof $_ipm !== "undefined") {
      // If the next page is not contained in the list, the keyboard is hidden
      $_ipm.taboskbd.hide();
    }
  }, []);
  const [search] = useLazySearch();

  const { data } = useSearch("Questionnaire", {
    identifier: { $or: ["document", "totalPrice"] }
  });

  const documentQuestionnaire = data?.Questionnaire?.find(
    questionnaire =>
      questionnaire.identifier?.[0]?.value === MEDEO_DOCUMENT_QUESTIONNAIRE
  );

  const paymentQuestionnaire = data?.Questionnaire?.find(
    questionnaire =>
      questionnaire.identifier?.[0]?.value === MEDEO_TOTAL_PRICE_QUESTIONNAIRE
  );

  // Entries that we are expecting to be computed on the doctor side
  const [state, setState] = useState({
    documentResponse: null,
    documentAmount: null,
    payedAmount: null,
    paymentResponse: null,
    tlcEncounter: null
  });

  useEffect(() => {
    // Fetch teleconsultation Encounter and associated QuestionnaireResponses
    // every second
    const interval = setInterval(() => {
      search("Encounter", {
        subject: patientId,
        type: "teleconsultation_consultation",
        "part-of": encounterId,
        status: "finished",
        _revinclude: "QuestionnaireResponse:context",
        _sort: "-_id"
      }).then(data => {
        if (data == null) return;
        if (data.Encounter != null && state.tlcEncounter == null) {
          setState(s => ({ ...s, tlcEncounter: data.Encounter?.[0] }));
        }

        if (
          data.QuestionnaireResponse != null &&
          state.documentResponse == null
        ) {
          const documentResponse = data.QuestionnaireResponse.find(response => {
            return (
              getIdByReference(response.questionnaire.reference) ===
              documentQuestionnaire?.id
            );
          });
          setState(s => ({
            ...s,
            documentResponse: documentResponse,
            // Get amount of documents added bu the doctor
            documentAmount: documentResponse?.item?.[0]?.answer.length
          }));
        }

        if (
          data.QuestionnaireResponse != null &&
          state.paymentResponse == null
        ) {
          const paymentResponse = data.QuestionnaireResponse.find(response => {
            return (
              getIdByReference(response.questionnaire.reference) ===
              paymentQuestionnaire?.id
            );
          });
          setState(s => ({
            ...s,
            paymentResponse: paymentResponse,
            // Amount the patient had to pay
            payedAmount:
              paymentResponse?.item?.[0]?.answer?.[0].valueQuantity.value
          }));
        }
      });
    }, 3000);

    // Clear the interval once all resources were fetched and stored in the state
    if (
      state.tlcEncounter != null &&
      state.documentResponse != null &&
      state.paymentResponse
    )
      clearInterval(interval);
    // clear the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, [
    patientId,
    search,
    encounterId,
    documentQuestionnaire?.id,
    paymentQuestionnaire?.id,
    state.documentResponse,
    state.paymentResponse,
    state.tlcEncounter
  ]);

  return (
    <div className="h-screen w-full bg-gray-900 z-10 absolute top-0 left-0 p-24 flex flex-col justify-between text-gray-100">
      <div className="text-2xl font-bold">
        {state.tlcEncounter == null
          ? "Téléconsultation en cours"
          : "Téléconsultation terminée"}
      </div>
      {state.payedAmount != null && (
        <div>Vous avez été débité de {state.payedAmount}€</div>
      )}
      {state.documentAmount == null ? (
        <div>
          Les documents rédigés par le docteur seront automatiquement
          sauvegardés sur votre dossier patient. Pour les imprimer, faites-en la
          demande au comptoir.
        </div>
      ) : (
        <div>
          Le docteur a transmis {state.documentAmount ?? "vos"} document(s). Ils
          seront automatiquement sauvegardés sur votre dossier patient. Votre
          pharmacien peut imprimer les documents dont vous avez besoin.
        </div>
      )}
      <div className="text-center">
        <div className="flex flex-row justify-center">
          <button
            onClick={onBack}
            className="text-blue-400 bg-gray-900 p-2 border-2 text-sm uppercase text-center font-bold rounded-md border-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mr-20"
          >
            Retourner à mon dossier
          </button>
          <button
            onClick={onExit}
            className="text-blue-400 bg-gray-900 p-2 border-2 text-sm uppercase text-center font-bold rounded-md border-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Se déconnecter et quitter
          </button>
        </div>
        <div className="text-2xl font-bold mt-4">
          Merci d'avoir utiliser la plateforme Medeo !
        </div>
      </div>
    </div>
  );
};

export default Visio;
