import React, { createContext, useReducer } from "react";

export const StripeContext = createContext({});

const initialState = {
  customerId: null,
  source: null,
  hasSource: false,
  customerName: null,
  customerEmail: null,
  isComplete: false,
  successMessage: "",
  errorMessage: "",
  stripeCharges: null,
  isLoading: false
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "loading":
      return { ...state, isLoading: payload, errorMessage: "" };
    case "customer-name":
      return { ...state, customerName: payload };
    case "customer-email":
      return { ...state, customerEmail: payload };
    case "entering-card":
      return {
        ...state,
        isComplete: false,
        errorMessage: "",
        successMessage: ""
      };
    case "card-complete":
      return { ...state, isComplete: true };
    case "setup-complete":
      return {
        ...state,
        customerId: payload.id,
        source: payload.source,
        stripeCharges: payload.stripeCharges,
        hasSource: true,
        isComplete: true,
        isLoading: false
      };
    case "modify-card":
      return {
        ...state,
        source: payload.source,
        isComplete: payload.isComplete,
        hasSource: payload.hasSource,
        isCharging: payload.isCharging
      };
    case "error":
      return {
        ...state,
        errorMessage: payload,
        isLoading: false
      };
    default:
      return state;
  }
};

const StripeProvider = ({ children }) => {
  const [stripeData, dispatch] = useReducer(reducer, initialState);
  return (
    <StripeContext.Provider value={[stripeData, dispatch]}>
      {children}
    </StripeContext.Provider>
  );
};

export default StripeProvider;
