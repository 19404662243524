import { useCallback, useEffect, useState } from "react";
import {
  extractDataFromCardReader,
  extractDataFromIpmCardReader
} from "../../utils/cardReader";
/* global $_ipm */

/**
 * Handle vitale card insertion for both IPM (using the integrated card reader)
 * and other uses (using cardpeek)
 *
 * @param {Function} onCardInsertion: callback for when a card is inserted
 */
const useCardReader = onCardInsertion => {
  const [cardStatus, setCardStatus] = useState(null);
  const [ws, setWs] = useState(null);

  if (typeof $_ipm !== "undefined") {
    window.ipmOnTagReadingTagRead = evt => {
      try {
        let event = $_ipm.jsonParse(evt);
        if (event.src_name === "SesamVitaleReader") {
          const cardData = extractDataFromIpmCardReader(event.data);
          onCardInsertion(cardData);
        }
      } catch (e) {
        setCardStatus("notValid");
      }
    };
  }

  useEffect(() => {
    // If we are on ipm, then we register the callback defined above for
    // when a card is being inserted
    if (typeof $_ipm !== "undefined") {
      $_ipm.call("registerCallback", "ipmOnTagReadingTagRead");
      return () => {
        window.ipmOnTagReadingTagRead = null;
      };
    }
    // Otherwise, we open the socket with Kligo to be able to receive data from
    // cardpeek
    else {
      setWs(new WebSocket("ws://localhost:63336/v3/Vitale"));
    }
  }, []);

  // This useEffect will be triggered when the socket becomes not null,
  // thus when we are not on IPM and using cardpeek.
  useEffect(() => {
    if (ws != null) {
      ws.onmessage = function data(event) {
        // In the case where the detected card was not valid, we ask for Kligo
        // to relaunch cardPeek again, so that we start listening on a
        // new card insertion.
        if (event.data === "cardNotValid") {
          setCardStatus("notValid");

          return;
        }
        if (event.data === "cardConnected") {
          setCardStatus("processing");
        }

        try {
          const data = JSON.parse(event.data);

          if (data.cardpeek != null) {
            const cardData = extractDataFromCardReader(data);
            // If one of the expected properties from the vitale card is null,
            // set the card status to not valid and ivite the user to try again
            if (
              Object.entries(cardData).find(([_, value]) => value == null) ||
              cardData == null
            )
              setCardStatus("notValid");
            else onCardInsertion(cardData);
          }
        } catch (e) {}

        return () => ws.close();
      };
    }
  }, [onCardInsertion, ws]);

  const retry = useCallback(() => {
    if (ws != null) ws.send("relaunchCardPeek");

    setCardStatus(null);
  }, [ws]);

  return { cardStatus, retry };
};

export default useCardReader;
