import { useNavigate, useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import { upperFirst } from "lodash";
import {
  MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE,
  MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM,
  MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE
} from "../../Shared/codes";
import PatientFileHeader from "./PatientFileHeader";
import image from "../../Images/Calendar_8@2x.png";
import BasicButton from "../../Shared/components/BasicButton";
import moment from "moment";
import Spinner from "../../Shared/components/Spinner";
import Help from "../../Shared/components/Help";
import { getIdByReference } from "../../Shared/utils";
import { useEffectOnce } from "react-use";
/* global $_ipm */

const PatientFile = ({
  onClick,
  procedureRequest,
  joinTLC,
  patient,
  appointment,
  practitioner
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (typeof $_ipm !== "undefined") {
      // If the next page is not contained in the list, the keyboard is hidden
      $_ipm.taboskbd.hide();
    }
  }, []);

  // the spinner should display at least 1 second
  const [canDisplay, setCanDisplay] = useState(false);
  useEffectOnce(() => {
    setTimeout(() => setCanDisplay(true), 1500);
  });

  const handleJoinTLC = () => {
    joinTLC();
    const encounterId = getIdByReference(procedureRequest?.context?.reference);
    navigate(`${location.pathname}/visio/${encounterId}`);
  };

  const handleStartPreTLC = () => {
    navigate(`./${patient?.id}/examen-clinique/start`);
  };
  const patientName = patient?.name?.find(n => n.family)?.family;
  const patientGender = patient?.gender;
  const patientTitle = patientGender === "male" ? "M" : "Mme";
  const welcomeText = `Bonjour ${patientTitle} ${upperFirst(patientName)}`;

  const appointmentInfo = {
    performerName: practitioner?.name?.find(n => n.family)?.family,
    motif: appointment?.description?.split(" - ")[0],
    appointmentDate: moment(appointment?.start).format("dddd DD MMM"),
    appointmentHour: moment(appointment?.start).format("HH:mm")
  };

  const hasPreTLCProcedure =
    procedureRequest?.code?.coding?.find(
      c => c.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
    ).code === MEDEO_PROCEDURE_REQUEST_BOOKING_APPOINTMENT_CODE;

  const hasOnGoingTLC =
    procedureRequest?.code?.coding?.find(
      c => c.system === MEDEO_PROCEDURE_REQUEST_CODE_SYSTEM
    ).code === MEDEO_PROCEDURE_REQUEST_TELECONSULTATION_CODE;

  return (
    <div>
      <PatientFileHeader headerText={"Dossier patient"} />
      <div>
        {patient != null && canDisplay ? (
          <>
            <div className="m-16 px-16">
              <div className="font-bold text-3xl">{welcomeText}</div>
              <Help className="mt-6">
                Pour accéder aux documents de votre dossier patient, veuillez
                demander à votre pharmacien.
              </Help>
            </div>
            {(hasOnGoingTLC || hasPreTLCProcedure) && (
              <div className="flex justify-center">
                <div
                  style={{ height: "8.5rem", width: "48.5rem" }}
                  className="flex justify-between mb-8 items-center bg-white shadow-2xl text-center p-8 text-gray-800 rounded font-bold cursor-pointer"
                  onClick={
                    hasPreTLCProcedure ? handleStartPreTLC : handleJoinTLC
                  }
                >
                  <div className="flex flex-wrap flex-col">
                    <div className="font-bold text-2xl text-left">
                      {`Rendez-vous - Dr. ${appointmentInfo.performerName}`}
                    </div>
                    <div className="font-normal text-left">
                      {`Téléconsultation - ${appointmentInfo.appointmentHour} - ${appointmentInfo.appointmentDate}`}
                    </div>
                    <div
                      className="font-normal text-xs text-left"
                      style={{
                        color: "#92969A"
                      }}
                    >
                      {`Motifs: ${appointmentInfo.motif}`}
                    </div>
                  </div>

                  <BasicButton hover={false}>
                    {hasPreTLCProcedure
                      ? "DÉMARRER LA CONSULTATION"
                      : "REJOINDRE LA TÉLÉCONSULTATION"}
                  </BasicButton>
                </div>
              </div>
            )}

            {!(hasOnGoingTLC || hasPreTLCProcedure) && (
              <div className="w-full flex justify-center">
                <button
                  onClick={onClick}
                  className="p-6 mx-2 w-64 h-64 text-2xl shadow-2xl text-center bg-white text-gray-800 rounded font-bold justify-center"
                >
                  <img className="h-24 mx-auto" src={image} alt="Calendar" />
                  Prendre un rendez-vous
                </button>
              </div>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default PatientFile;
