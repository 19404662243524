import React from "react";

function BasicButton({
  className = "",
  bgColor = "bg-blue-400",
  children,
  onClick,
  disabled = false,
  type = "button",
  hover = true
}) {
  const hoverColor = hover ? "hover:bg-blue-700" : "hover:bg-blue-400";
  const basicProperty = disabled
    ? "cursor-not-allowed bg-gray-400"
    : `${bgColor} cursor-pointer ${hoverColor}`;
  return (
    <button
      onClick={onClick}
      className={`${basicProperty} ${className} h-10 uppercase font-bold py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default BasicButton;
