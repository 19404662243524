import React from "react";
import InputWithSubmit from "../../Shared/components/InputWithSubmit";
import Hero from "../../Shared/components/Hero";
import Form from "../../Shared/components/Form";

const Phone = ({ value, onChange, onSubmit }) => {
  return (
    <Form id="phone" onSubmit={onSubmit}>
      <Hero>Quel est votre numéro de portable ?</Hero>
      {/* here we want to accept european phone number, so numbers will have between 8 and 12 char ==> https://fr.wikipedia.org/wiki/Plan_de_num%C3%A9rotation_en_Europe */}
      <InputWithSubmit
        id="phone-number"
        autoComplete="off"
        name="phone"
        type="tel"
        required
        value={value}
        onChange={onChange}
        disabled={value.length < 8 || value.length > 12}
      />
      <div className="h-16" />
    </Form>
  );
};

export default Phone;
