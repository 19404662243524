import React from "react";
import SubmitButton from "../../Shared/components/SubmitButton";
import Input from "../../Shared/components/Input";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

const Weight = ({ value, onChange, onSubmit }) => {
  return (
    <Form id="weight" onSubmit={onSubmit}>
      <div>
        <Hero>Quel est votre poids ?</Hero>
        <Help>
          Cette information permettra au médecin d'adapter le dosage du
          traitement si nécessaire.
        </Help>
      </div>
      <div className="flex flex-row items-center justify-center">
        <Input
          id="weight"
          name="weight"
          className="w-1/4"
          type="number"
          defaultValue={value}
          onChange={onChange}
        />
        <div className="ml-2 mt-10">kg</div>
        <div className="flex justify-center ml-6 mt-10">
          <SubmitButton>Suivant</SubmitButton>
        </div>
      </div>
      <div className="h-16" />
    </Form>
  );
};

export default Weight;
