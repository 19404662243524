import React from "react";
import BasicButton from "../../Shared/components/BasicButton";
import Hero from "../../Shared/components/Hero";
import Help from "../../Shared/components/Help";

const PatientFileInformations = ({ onNext }) => {
  return (<div className="flex flex-1 w-full flex-col items-center justify-between">
        <div className="w-full">
          <Hero>
            Afin de finaliser votre dossier patient, nous avons quelques
            questions médicales à vous poser.
          </Hero>

          <Help>
            Ces informations aideront les professionels de santé à mieux vous
            soigner.
          </Help>
        </div>
        <BasicButton onClick={onNext}>Continuer</BasicButton>
        <div/>
      </div>
  );
};

export default PatientFileInformations;
