import React from "react";
import moment from "moment";

/**
 * Display an header with the date and day of the week
 * depending on the column index, i.e. the number of day from today
 * @param {number} index - the column index
 * @return {*}
 * @constructor
 */
const ColumnHeader = ({ date }) => {
  const weekDay = moment(date).format("dddd");
  const day = moment(date).format("DD");
  return (
    <header className="w-full flex flex-col justify-center">
      <label className="text-center text-xs capitalize h-4 mb-2 font-bold">
        {weekDay}
      </label>
      <div className="text-center text-sm  mb-4  text-gray-500 w-full">
        {day}
      </div>
    </header>
  );
};

export default ColumnHeader;
