import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Heading from "./Heading";
import HeaderButton from "./HeaderButton";
import CancelModal from "./CancelModal";

function Header({
  cancelText,
  headerText,
  onBack,
  onCancel,
  widthOfProgressBar
}) {
  return (
    <div className="shadow-sm">
      <div className="p-6 bg-white flex flex-row justify-between items-center ">
        <HeaderButton onClick={onBack} icon={faChevronLeft} />
        <Heading>{headerText}</Heading>
        <CancelModal
          component={({ onClick }) => (
            <HeaderButton
              icon={faTimes}
              onClick={() => {
                onClick();
              }}
            />
          )}
          text={cancelText}
          onConfirm={onCancel}
        />
      </div>
      <div className="h-2 relative overflow-hidden">
        <div className="w-full h-full bg-gray-400 absolute" />
        <div
          id="bar"
          className="h-full bg-blue-400 relative w-0"
          style={{ width: `${widthOfProgressBar}%` }}
        />
      </div>
    </div>
  );
}

export default Header;
