/* global $_ipm */
import React, { useEffect, useState } from "react";
import QuestionnaireOption from "./QuestionnaireOption";
import Form from "../../components/Form";
import Hero from "../../components/Hero";
import { useEffectOnce } from "react-use";

const QuestionnaireBoolean = ({ item, onChange }) => {
  const [state, setState] = useState({ linkId: item.linkId });
  useEffectOnce(() => {
    if (typeof $_ipm !== "undefined") {
      // Hide keyboard by default on a screen of this type
      $_ipm.taboskbd.hide();
    }
  }, []);

  const handleChange = value => {
    setState({ ...state, answer: [{ valueBoolean: value }] });
  };

  useEffect(() => {
    if (state.answer != null) onChange(state);
  }, [state, onChange]);

  return (
    <Form>
      <Hero>{item.text}</Hero>
      <div className="flex flex-row justify-center flex-wrap">
        <QuestionnaireOption
          handleChange={handleChange}
          value={false}
          display="Non"
        />
        <QuestionnaireOption
          handleChange={handleChange}
          value={true}
          display="Oui"
        />
      </div>
      <div className="h-16" />
    </Form>
  );
};

export default QuestionnaireBoolean;
