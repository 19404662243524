import React from "react";
import BasicToggleButton from "../../Shared/components/BasicToggleButton";
import Hero from "../../Shared/components/Hero";

const Gender = ({ onClick, value }) => {
  return (
    <div className="flex flex-1 flex-col justify-between">
      <Hero>Quel est votre sexe ?</Hero>
      <div className="flex justify-center">
        <BasicToggleButton
          className="mr-6"
          onClick={() => onClick("male")}
          checked={value === "male"}
        >
          Homme
        </BasicToggleButton>
        <BasicToggleButton
          onClick={() => onClick("female")}
          checked={value === "female"}
        >
          Femme
        </BasicToggleButton>
      </div>
      <div className="h-16" />
    </div>
  );
};

export default Gender;
