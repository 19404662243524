import { Link } from "@reach/router";
import React from "react";
import ButtonLink from "../../Shared/components/ButtonLink";

const BookingEnd = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="max-h-full w-3/4 pb-10 bg-white shadow sm:rounded-lg space-y-8 p-4 overflow-auto center">
        <div className="flex justify-center text-4xl font-bold mb-10">
          Votre rendez-vous a bien été enregistré
        </div>
        <div className="flex justify-around">
          <Link
            to=".."
            className="uppercase flex justify-center py-2 px-4 border border-blue-400 text-sm font-bold rounded-md text-blue-400 bg-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Retourner au dossier patient
          </Link>
          <ButtonLink to="/auth">Se déconnecter de medeo</ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default BookingEnd;
