import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import BasicButton from "./BasicButton";

const CancelModal = ({ component: Component, onConfirm, text }) => {
  const [state, setState] = useState(false);

  return (
    <div>
      <Component
        onClick={() => {
          setState(true);
        }}
      />
      {state === true && (
        <div
          className="absolute w-full inset-0 flex flex-col justify-center items-center z-10"
          style={{ background: "rgba(0,0,0,0.50)" }}
        >
          <div
            className="bg-white flex flex-col p-6 rounded-lg"
            style={{ maxWidth: "40rem" }}
          >
            <div className="self-end">
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => {
                  setState(false);
                }}
              />
            </div>
            <div className="text-2xl font-bold mb-4">
              Voulez-vous vraiment annuler ?
            </div>
            <div className="mr-10">{text}</div>
            <div className="flex flex-row mt-10 justify-end">
              <BasicButton
                className="text-blue-400 border-blue-400 mr-2"
                bgColor="bg-white"
                onClick={() => {
                  setState(false);
                }}
              >
                Revenir à la démarche
              </BasicButton>
              <BasicButton
                className="self-end"
                onClick={() => {
                  onConfirm();
                  setState(false);
                }}
              >
                Annuler la démarche
              </BasicButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelModal;
