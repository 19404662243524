import React from "react";
import Form from "../../Shared/components/Form";
import Hero from "../../Shared/components/Hero";
import InputWithSubmit from "../../Shared/components/InputWithSubmit";

const Mail = ({ value, onChange, onSubmit }) => {
  // We put a type text here so that the ipm keyboard will enable the
  // user to type '+' in as well for instance, which are not contained
  // by default into the email keyboard
  // on chrome we use email so the on screen keyboard while show the correct layout
  // "Trident" is a userAgent on IE11
  const type =
    window.navigator.userAgent.indexOf("Trident") !== -1 ? "text" : "email";
  return (
    <Form id="mail" onSubmit={onSubmit}>
      <Hero>Quelle est votre adresse email ?</Hero>
      <InputWithSubmit
        id="email-address"
        name="mail"
        autoComplete="off"
        type={type}
        required
        value={value}
        onChange={onChange}
      />

      <div className="h-16" />
    </Form>
  );
};

export default Mail;
